import DiscountPrice from 'cores/components/DiscountPrice';
import { getEditItemsFromCart } from 'helper/cart';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Quantity } from '../../../cores/components/Quantity/index';
import { isAddedToFavorite } from '../../../helper/index';
import { useAppSelector } from '../../../hooks/redux/index';
import { RootState } from '../../../store/configureStore';
import { QuantitySmall } from 'cores/components/Quantity/indexSmall';
import { getSpotId } from '../../../helper/path';
import { MyImages } from 'cores/components';
import { useTranslation } from 'react-i18next';
import { SPOT_MENU_ONLY } from 'constants/spot';
import { getSpotById } from 'helper/spot';

type PropsType = {
  onChangeQuantity?: (param: number) => void;
  isWithinOrderTime?: boolean;
  isAvailable?: boolean;
};

const MenuInformation = (props: PropsType) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { detail } = useAppSelector((state) => state.organization);
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const { t } = useTranslation();
  const menu = useAppSelector((state: RootState) => state.menu.details);
  const favoriteMenu = useAppSelector((state: RootState) => state.favorites.menu);

  const [isMyFavorite, setIsMyFavorite] = useState(isAddedToFavorite(favoriteMenu, id ?? ''));
  const [quantity, setQuantity] = useState(getEditItemsFromCart()?.quantity ?? 1);
  const profileUrl = get(detail, ['image', 'imageUrl'], '');
  const imageUrl = get(menu, ['image', 'imageUrl'], '');
  const [scrolled, setScrolled] = useState(false);
  const isSafari = () => {
    const checkBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return checkBrowser ? 80 : 0;
  };
  React.useEffect(() => {
    setIsMyFavorite(isAddedToFavorite(favoriteMenu, id ?? ''));
    // window.addEventListener('scroll', handleScroll);

    // return () => window.removeEventListener('scroll', handleScroll);
  }, [isMyFavorite, menu, imageUrl]);

  function changeQuantity(quantity: number) {
    const { onChangeQuantity } = props;
    setQuantity(quantity);
    if (onChangeQuantity) {
      onChangeQuantity(quantity);
    }
  }
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const header = document.getElementById('sticky-header');
    if (header)
      if (scrollTop > header.offsetTop) {
        header.classList.add('fixed', 'fix-top');
        setScrolled(true);
      } else {
        header.classList.remove('fixed', 'fix-top');
        setScrolled(false);
      }
  };
  const back = () => {
    localStorage.setItem('isBack', 'true');
    navigate(-1);
  };
  return (
    <div>
      <div
        className="restaurant-wallpaper"
        style={{
          height: `${spot?.type == SPOT_MENU_ONLY ? 'calc(60vh)' : 'calc(40vh)'}`,
        }}
      >
        {/* <div className="overlay-image" style={{ borderRadius: 'unset' }}></div> */}
        {(imageUrl || profileUrl) && <img className="wallpaper" src={imageUrl || profileUrl} alt="" />}
        <div onClick={() => back()} className="profile-wrapper left back-size">
          <img src="/assets/icons/back.svg" alt="" />
        </div>
      </div>
      <div className="sticky-content restaurant-detail-content">
        {/* {scrolled ? (
          <>
            <div onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/back.svg" alt="" />
            </div>
            <div className="menu-rating-price mt-19">
              <div className="rating-description">
                <div className="text-title menu-name-one-line" style={{ fontSize: '15px' }}>
                  {menu?.name}
                </div>
              </div>
              {spotId && (
                <div
                  className="menu-details"
                  style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}
                >
                  <QuantitySmall startedWith={quantity} onChange={changeQuantity} />
                </div>
              )}
            </div>
          </>
        ) : (
          <> */}
        <div className="text-title">{menu?.name}</div>
        <div className="menu-rating-price mt-19">
          <div className="rating-description">
            {menu && menu.rating.value >= 5 && (
              <>
                <div className="rating">{menu?.rating?.value.toFixed(1)}</div>
                <img src="/assets/icons/star.svg" alt="" />
                <div className="people-ordered-text">
                  {menu?.rating?.total} {t('reviews')}
                </div>
              </>
            )}
          </div>
          <div className="menu-details" style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
            {/* {<DiscountPrice item={menu} />} */}
            {/* {menu && menu.basicPrice !== 0 && <DiscountPrice item={menu} />} */}
            {menu && menu.basicPrice !== 0 && menu && menu.rating.value >= 5 && <DiscountPrice item={menu} />}
          </div>
        </div>
        <p className="detail-text mt-19">{menu?.description}</p>
        {spotId && (
          <div className="add-and-rating mt-20">
            {menu?.isOutOfStock || !props.isWithinOrderTime || !props.isAvailable ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '10px',
                }}
              >
                <MyImages width="20px" src="/assets/icons/exclamation.svg" />
                <p style={{ color: '#64676C', fontWeight: '700', fontSize: '16px' }}>
                  {props.isWithinOrderTime ? 'Not Available' : 'You can only order this menu during order time.'}
                </p>
              </div>
            ) : (
              <>
                {spot && spot?.type != SPOT_MENU_ONLY && <Quantity startedWith={quantity} onChange={changeQuantity} />}
              </>
            )}
          </div>
        )}
        {/* </>
        )} */}
      </div>
    </div>
  );
};

export default MenuInformation;
