import { PayloadAction } from '@reduxjs/toolkit';

import { SET_CATEGORY_LIST } from '../../constants/category';
import { ICategory } from '../../interfaces/models/category';

type StateType = {
  list: ICategory[];
};

const initialState = {
  list: [],
};

export default function category(state: StateType = initialState, action: PayloadAction<ICategory[]>): StateType {
  switch (action.type) {
    case SET_CATEGORY_LIST:
      return {
        ...state,
        list: action.payload ?? [],
      };
    default:
      return state;
  }
}
