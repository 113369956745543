import { callingWaiter } from 'actions/outlets';
import { MyImages } from 'cores/components';
import { PaymentType, PaymentName } from 'enums/payment-type';
import { PaymentMethods, PaymentTypeUnion } from 'interfaces/models/outlets';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { getSpotId } from '../../../helper/path';
import { Icon } from '@iconify/react';
import { RootState } from 'store/configureStore';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../LoadingSpinner';

type TypProps = {
  url?: string;
};

const ProceedToPayment = ({ url }: TypProps) => {
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor = detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const redirectToPayment = () => {
    setPaymentLoading(true);
    if (url) window.location.href = url;
  };
  return (
    <div className="blur-background call-stuff-for-bill-wrapper opacity">
      <div className="overlay"></div>
      <div className="content">
        <div className="your-payment-method-txt">Your Payment Link is Ready</div>
        <button
          onClick={() => redirectToPayment()}
          className="flex-row center column-gap-10 btn light-primary mt-35"
          style={{
            padding: '19px 0',
            flexDirection: 'column',
            borderColor: brandColor,
          }}
        >
          <div className="flex-row column-gap-4">
            {paymentLoading ? (
              <LoadingSpinner brandColor={brandColor} />
            ) : (
              <>
                {' '}
                <Icon icon="ic:outline-credit-card" width={22} color={brandColor} />
                <span style={{ color: brandColor }}>Proceed to Payment</span>
              </>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default ProceedToPayment;
