import { useAppSelector } from 'hooks/redux';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { InputComponent } from 'cores/components';
import { IPickUpInfo } from 'interfaces/models/pickupInfo';
import { Oval } from 'react-loader-spinner';
import { IOutlets } from 'interfaces/models/outlets';
import { useTranslation } from 'react-i18next';

interface TypeProps {
  onClosed: () => void;
  pickupInfo: IPickUpInfo;
  setPickupInfo: (value: IPickUpInfo) => void;
  onSubmitPickup: () => void;
  price: number;
  list: IOutlets[];
}

const PickUpInformation = (props: TypeProps) => {
  const { onClosed, pickupInfo, setPickupInfo, price, onSubmitPickup, list } = props;
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const refBottomSheet = useRef<HTMLDivElement>(null);
  const hours = Array.from(Array(24).keys());
  const minutes = ['00', '15', '30', '45'];
  const currentTime = `${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(
    2,
    '0'
  )}`;

  const { detail } = useAppSelector((state) => state.organization);
  const brandColor = detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  let dayOfWeek = new Date().getDay();
  if (dayOfWeek === 0) {
    dayOfWeek = 6;
  }
  const matchingOrderHours = list[0].orderHours.find((x: { dayOfWeek: number }) => x.dayOfWeek === dayOfWeek - 1);
  const options = hours
    .flatMap((h) => minutes.map((m) => `${String(h).padStart(2, '0')}:${m}`))
    .filter((o) => currentTime.localeCompare(o) == -1);

  // Filter options based on order hours and break hours of the outlet and also remove the option
  const { orderStartTime, orderEndTime, breakStartTime, breakEndTime } = matchingOrderHours as {
    orderStartTime: { hours: number; minutes: number };
    orderEndTime: { hours: number; minutes: number };
    breakStartTime: { hours: number; minutes: number };
    breakEndTime: { hours: number; minutes: number };
  };

  const filteredOptions = options
    .filter((option) => {
      const [hours, minutes] = option.split(':');
      const optionTime = new Date();
      optionTime.setHours(Number(hours), Number(minutes), 0, 0);

      const currentTime = new Date();
      const next30Minutes = new Date(currentTime.getTime() + 30 * 60000); // Add 30 minutes to current time

      return (
        optionTime >=
          new Date(
            optionTime.getFullYear(),
            optionTime.getMonth(),
            optionTime.getDate(),
            orderStartTime.hours,
            orderStartTime.minutes
          ) &&
        optionTime <=
          new Date(
            optionTime.getFullYear(),
            optionTime.getMonth(),
            optionTime.getDate(),
            orderEndTime.hours,
            orderEndTime.minutes
          ) &&
        (optionTime <
          new Date(
            optionTime.getFullYear(),
            optionTime.getMonth(),
            optionTime.getDate(),
            breakStartTime.hours,
            breakStartTime.minutes
          ) ||
          optionTime >=
            new Date(
              optionTime.getFullYear(),
              optionTime.getMonth(),
              optionTime.getDate(),
              breakEndTime.hours,
              breakEndTime.minutes
            )) &&
        optionTime > next30Minutes
      );
    })
    .map((option) => {
      const [hours, minutes] = option.split(':');
      let formattedHours = Number(hours);
      let ampm = 'AM';

      if (formattedHours === 0) {
        formattedHours = 12;
      } else if (formattedHours === 12) {
        ampm = 'PM';
      } else if (formattedHours > 12) {
        formattedHours -= 12;
        ampm = 'PM';
      }

      return { [option]: `${formattedHours}:${minutes} ${ampm}` };
    });
  useEffect(() => {}, [pickupInfo]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (refBottomSheet.current && !refBottomSheet.current.contains(e.target)) {
      onClosed();
    }
  };

  function onSelectTime(e: any) {
    const date = new Date();
    if (e.target.value === 'ASAP') {
      const minute = date.getMinutes();
      const hour = date.getHours();
      date.setHours(hour, minute);
    } else {
      const [hour, minute] = e.target.value.split(':');
      date.setHours(hour, minute);
    }

    setPickupInfo({ ...pickupInfo, preffered_date: date });
  }
  useEffect(() => {
    const localPickupInfo = localStorage.getItem('pickupInfo');
    if (localPickupInfo) {
      setPickupInfo({
        ...pickupInfo,
        firstName: JSON.parse(localPickupInfo).firstName,
        mobileNumber: JSON.parse(localPickupInfo).mobileNumber,
        email: JSON.parse(localPickupInfo).email,
      });
    }
  }, []);
  function onClickSubmitPickupOrder() {
    localStorage.setItem('pickupInfo', JSON.stringify(pickupInfo));
    setLoading(true);
    if (pickupInfo.firstName && pickupInfo.mobileNumber.length >= 8) return onSubmitPickup();
    else console.log('validate false:::');
  }

  function validateEmail(email: string) {
    // var re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    var re = /^[\w%\+\-]+(\.[\w%\+\-]+)*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!email) return true;
    else return re.test(email);
  }

  function LoadingSpinner() {
    return (
      <Oval
        height={20}
        width={20}
        color="#FFFF"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#FFB26B"
        strokeWidth={5}
        strokeWidthSecondary={5}
      />
    );
  }

  return (
    <>
      <div className="bottom-sheet-wrapper">
        <div className="bottom-sheet-content padding" ref={refBottomSheet}>
          <div className="navigation-wrapper pickup-information">
            <div className="title mb-25">{t('Your_contact_information')}</div>
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="label">
                  {t('full_name')} <span className="error">*</span>
                </div>
              </div>
              <InputComponent
                name="fullname"
                value={pickupInfo.firstName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPickupInfo({ ...pickupInfo, firstName: e.target.value })
                }
                placeholder="John"
              />
            </div>
            {/* <div className="pickup-information-item row-item">
              <div className=" flex-row space-between">
                <div className="label">
                  Last Name <span className="error">*</span>
                </div>
              </div>

              <InputComponent
                value={pickupInfo.lastName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPickupInfo({ ...pickupInfo, lastName: e.target.value })
                }
                placeholder="Doe"
              />
            </div> */}
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="label">
                  {t('mobile_number')} <span className="error">*</span>{' '}
                  {pickupInfo.mobileNumber.length != 0 && pickupInfo.mobileNumber.length < 8 ? (
                    <span className="error">({t('enter_valid_number')})</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="flex-row space-between">
                <div className="phone-country-code">
                  <InputComponent
                    value=""
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPickupInfo({ ...pickupInfo, mobileNumber: e.target.value })
                    }
                    placeholder="(855)"
                    disabled={true}
                  />
                </div>

                <InputComponent
                  name="phonenumber"
                  value={pickupInfo.mobileNumber}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    e.target.value.length <= 9 ? setPickupInfo({ ...pickupInfo, mobileNumber: e.target.value }) : ''
                  }
                  placeholder="12 123 123"
                  type="number"
                />
              </div>
            </div>
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="label">{t('prefered_pick_up')}</div>
              </div>

              <select className="select" onChange={(e) => onSelectTime(e)}>
                <option value="" disabled>
                  {t('please_choose_time')}
                </option>
                <option value="ASAP">ASAP</option>
                {filteredOptions.map((option, idx) => {
                  const key = Object.keys(option)[0]; // Get the key of the option object
                  const value = option[key]; // Get the value of the option object

                  return (
                    <option key={idx} value={key}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="pickup-information-item row-item">
              <div className=" flex-row space-between">
                <div className="label">
                  {t('email_optional')}
                  {pickupInfo.email.length > 0 && !validateEmail(pickupInfo.email) ? (
                    <span className="error"> ({t('enter_valid_email')})</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <InputComponent
                value={pickupInfo.email}
                name="email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPickupInfo({ ...pickupInfo, email: e.target.value })}
                placeholder="customer@example.com"
              />
            </div>
            <div className="pickup-information-item row-item">
              <div className=" flex-row space-between">
                <div className="label">{t('notes')}</div>
              </div>

              <InputComponent
                value={pickupInfo.notes}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPickupInfo({ ...pickupInfo, notes: e.target.value })}
                placeholder="Notes"
              />
            </div>

            <div
              className="btn primary pd-12 flex-row space-between submit-order-pickup"
              onClick={() => onClickSubmitPickupOrder()}
              style={{ backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40`, borderColor: brandColor }}
            >
              <div className="flex-row center column-gap-10">
                <span>{t('submit_order')}</span>
              </div>
              <div className="flex-row column-gap-16">
                <div>$ {price.toFixed(2)}</div>

                {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                {isLoading && <LoadingSpinner />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PickUpInformation;
