import { getAllMenuByOutletId, getAllMenuFilteredByOutletId } from 'actions/menu';
import { getAllSpot } from 'actions/spot';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getOrganizationInformation } from '../../actions/organization';
import { GET_MENU_LIST_REQUEST } from '../../constants/menu';
import { Cart } from '../../cores/components';
import { getSelectorByKey } from '../../helper/selector';
import { useAppSelector } from '../../hooks/redux';
import { useAppDispatch } from '../../hooks/redux/index';
import { RootState } from '../../store/configureStore';
import FilterListing from './components/filter-listing';
import Header from './components/header';
import { isDisabledAddToCartButton } from 'helper/cart';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import Loading from 'components/partials/Loading';
import {
  SPOT_HOTEL_ROOM_SERVICE,
  SPOT_QRTABLE,
  SPOT_RESERVATION_PREORDER,
  SPOT_TABLE_RESERVATION,
} from 'constants/spot';
import TableReservation from './components/TableReservation';
import GroupedPromotions from './components/GroupedPromotion';
import ListingSections from './components/ListingSections';
import PreviouslyOrdered from './components/PreviouslyOrder';
import ReactGA from 'react-ga4';
import { getCategoryListByOutletId } from 'actions/category';
import WifiDetails from 'components/partials/WifiDetails';
import { stringify } from 'querystring';
import { TabFeedBack, TabSocialMedia } from './components/bottomTabs';
import { getAllPromotion } from 'actions/promotion';

const MenuListing = () => {
  const dispatch = useAppDispatch();
  const { orgId, outletId } = useParams();
  const { name, category } = useAppSelector((state: RootState) => state.filter);
  const { selected } = useAppSelector((state: RootState) => state.tabSelected);
  const { lang } = useAppSelector((state: RootState) => state.localization);
  const [filterName, setFilterName] = useState(name);
  const [hasFilterCategory, setFilterCategory] = useState<boolean>(false);
  const hasFiltered = _.isEmpty(filterName) || !_.isEmpty(category);
  const request = getSelectorByKey('request');
  const { t, i18n } = useTranslation();
  const { grouped } = useAppSelector((state: RootState) => state.menu);
  const [isLoading, setLoading] = useState<boolean>(false);
  const fromDetailPage = localStorage.getItem('isBack');
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const [categoryDisplayed, setCategoryDisplayed] = useState<string>('');
  const { list } = useAppSelector((state) => state.outlets);
  const outlet = Array.isArray(list) ? list.find((i) => i.id === outletId) : null;
  const storedWifi = localStorage.getItem('OpenWifi');
  const WifiDetailsModal = storedWifi !== null ? storedWifi : 'open';
  const [isWifiDetailsModal, setWifiDetailsModal] = useState<string>(WifiDetailsModal);
  const [isPreOrderInfoOpen, setOpenPreOrderMenu] = useState(false);
  const [selectedtab, setSelectedTab] = useState('');
  useEffect(() => {
    console.log('🚀 ~ MenuListing ~ isWifiDetailsModal:', isWifiDetailsModal);
  }, [isWifiDetailsModal]);

  localStorage.removeItem('KESS_PAYMENT_METHOD');
  useEffect(() => {
    if (fromDetailPage === null) {
      setLoading(true);
    }

    if (orgId && outletId) {
      dispatch(getOrganizationInformation(orgId));
      dispatch(getAllMenuFilteredByOutletId(outletId,spotId));
      dispatch(getAllSpot(outletId));
      dispatch(getAllPromotion(outletId));
      if (spot && outlet) {
        ReactGA.send({
          hitType: 'pageview',
          page: `${window.location.pathname + window.location.search}`,
          title: `${outlet.name} - ${spot.spotName}`,
        });
      }
    }
    i18n.changeLanguage(lang);
  }, [orgId, lang]);

  useEffect(() => {
    if (grouped.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [grouped]);

  useEffect(() => {
    const filteredList = list.filter((item) => item.id === outletId);

    const currentOrderHours = filteredList[0]?.orderHours;
    const isWithinHours = currentOrderHours ? isDisabledAddToCartButton(currentOrderHours) : true;

    if (
      currentOrderHours &&
      !isWithinHours &&
      spot &&
      spot.type != SPOT_TABLE_RESERVATION &&
      spot.type != SPOT_RESERVATION_PREORDER
    ) {
      toast.dismiss();
      toast.error(t('sorry_you_cannot_order_at_this_time'), {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [list]);
  const closedWifi = () => {
    localStorage.setItem('OpenWifi', 'closed');
    setWifiDetailsModal('closed');
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {spot &&
            (spot.type == SPOT_QRTABLE || spot.type == SPOT_HOTEL_ROOM_SERVICE) &&
            isWifiDetailsModal === 'open' &&
            outlet?.wiFis &&
            outlet?.wiFis.length > 0 && (
              <div className="pop-up">
                <WifiDetails outletDetails={outlet} closedWifi={() => closedWifi()} />
              </div>
            )}
          <div>
            {!hasFilterCategory && (
              <div>
                <Header
                  isPreOrderInfoOpen={isPreOrderInfoOpen}
                  changeSearchName={(name: string) => setFilterName(name)}
                  clickSliderHappened={() => setFilterCategory(true)}
                  setCategory={(param: string) => setFilterName(param)}
                  categoryDisplayed={categoryDisplayed}
                  setWifiDetailsModal={() => setWifiDetailsModal('open')}
                  setSelectedTab={(value: string) => setSelectedTab(value)}
                  selectedTab={selected}
                />

                {spot && spot.type != SPOT_TABLE_RESERVATION && spot.type != SPOT_RESERVATION_PREORDER ? (
                  <>
                    {spot && spot.type == SPOT_QRTABLE ? (
                      <>
                        {selected === 'order' && (
                          <>
                            {!_.isEmpty(category) ||
                              (!_.isEmpty(filterName) && (
                                <div className="filter-listing-wrapper">
                                  <div className="filter-listing">{<FilterListing />}</div>
                                </div>
                              ))}
                            {hasFiltered && !request[GET_MENU_LIST_REQUEST] && (
                              <div
                                style={{
                                  // marginTop: '50vh',
                                  marginTop:
                                    spot && spot.type == SPOT_QRTABLE
                                      ? selected === 'order'
                                        ? '10vh'
                                        : '0vh'
                                      : '50vh',
                                }}
                              >
                                <PreviouslyOrdered categoryDisplayed={setCategoryDisplayed} />
                                <GroupedPromotions categoryDisplayed={setCategoryDisplayed} />
                                <ListingSections categoryDisplayed={setCategoryDisplayed} />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {!_.isEmpty(category) ||
                          (!_.isEmpty(filterName) && (
                            <div className="filter-listing-wrapper">
                              <div className="filter-listing">{<FilterListing />}</div>
                            </div>
                          ))}
                        {hasFiltered && !request[GET_MENU_LIST_REQUEST] && (
                          <div
                            style={{
                              marginTop: '10vh',
                            }}
                          >
                            <PreviouslyOrdered categoryDisplayed={setCategoryDisplayed} />
                            <GroupedPromotions categoryDisplayed={setCategoryDisplayed} />
                            <ListingSections categoryDisplayed={setCategoryDisplayed} />
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {spot && (spot.type == SPOT_TABLE_RESERVATION || spot.type == SPOT_RESERVATION_PREORDER) && (
                       <div
                       style={{
                         marginTop: '10vh',
                       }}
                     >
                      <TableReservation
                        setOpenPreOrderMenu={(value: boolean) => setOpenPreOrderMenu(value)}
                        openPreOrderMenu={isPreOrderInfoOpen}
                        categoryDisplayed={setCategoryDisplayed}
                      />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <Cart />
          </div>
        </>
      )}
    </>
  );
};

export default MenuListing;
