import { setError } from 'actions/error';
import { toast } from 'react-toastify';

import { PayloadAction } from '@reduxjs/toolkit';

import { setRequestInProcess } from '../actions/request';
import { ActionOptions } from './types/index';

/**
 * @template T the type of the action's `response` tag.
 * @template P the type of the service's `param` tag.
 */
export const createListAction = <T, P = any, P2 = any>(options: ActionOptions<T, P>, values: P, bodyParam?: P2) => {
  const { serviceMethod, requestName, dispatch, getState, errorHandler } = options;

  let { successAction } = options;

  const getListSuccess = (response: T): PayloadAction<T> => {
    return {
      type: `${requestName}_SUCCESS`,
      payload: response,
    };
  };

  if (successAction == null) {
    successAction = getListSuccess;
  }

  const requestInProcess = getState().request[requestName];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestName));
  return serviceMethod(values, bodyParam)
    .then((response) => {
      if (successAction) {
        dispatch(successAction(response, dispatch));
      }
      dispatch(setRequestInProcess(false, requestName));

      return Promise.resolve(response);
    })
    .catch((error: any) => {
      dispatch(setRequestInProcess(false, requestName));
      dispatch(setError(error, requestName));
      const errorMessage = error?.message ?? 'Something went wrong';
      toast.dismiss();
      toast.error(errorMessage);
      if (errorHandler) {
        errorHandler(error);
      }
      return Promise.reject(error);
    });
};
