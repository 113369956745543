import { getSelectorByKey } from 'helper/selector';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export function useCalculateCharge(price: number) {
  const { list } = getSelectorByKey('outlets');
  const { outletId } = useParams();
  const outlet = list.find((i) => i.id === outletId);

  const originalPrice = price;
  const serviceChargePercentage = outlet?.serviceCharge ?? 0;
  const vatPercentage = outlet?.vat ?? 0;
  const areChargesIncluded = outlet?.areChargesIncluded ?? 0;
  const chargesIncluded = serviceChargePercentage !== 0 || vatPercentage !== 0;
  const [subTotal, setSubTotal] = useState<number>(0);
  const [serviceChargeTotal, setServiceChargeTotal] = useState<number>(0);
  const [vatTotal, setVatTotal] = useState<number>(0);
  const [totalSum, setTotalSum] = useState<number>(0);

  useEffect(() => {
    if (areChargesIncluded) {
      totalPriceCalculation();
    } else {
      subTotalCalculation();
    }
  }, [price]);

  useEffect(() => {
    if (areChargesIncluded) {
      subTotalCalculation();
    } else {
      totalPriceCalculation();
    }
  }, [serviceChargeTotal, vatTotal]);

  function subTotalCalculation() {
    let finalSubTotal;

    if (areChargesIncluded) {
      finalSubTotal = originalPrice - vatTotal - serviceChargeTotal;
    } else {
      finalSubTotal = originalPrice;
      serviceChargeCalculation(finalSubTotal);
    }

    setSubTotal(finalSubTotal);
  }

  function serviceChargeCalculation(sum: number) {
    let finalServiceCharge;

    if (areChargesIncluded) {
      finalServiceCharge = (sum / (1 + serviceChargePercentage / 100) - sum) * -1;
      subTotalCalculation();
    } else {
      finalServiceCharge = (sum * serviceChargePercentage) / 100;
      vatCalculation(sum + finalServiceCharge);
    }

    setServiceChargeTotal(finalServiceCharge);
  }

  function vatCalculation(sum: number) {
    let finalVAT;

    if (areChargesIncluded) {
      finalVAT = (sum / (1 + vatPercentage / 100) - sum) * -1;
      serviceChargeCalculation(sum - finalVAT);
    } else {
      finalVAT = (sum * vatPercentage) / 100;
      totalPriceCalculation();
    }

    setVatTotal(finalVAT);
  }

  function totalPriceCalculation() {
    let finalPrice;

    if (areChargesIncluded) {
      finalPrice = originalPrice;
      vatCalculation(finalPrice);
    } else {
      finalPrice = originalPrice + vatTotal + serviceChargeTotal;
    }

    setTotalSum(finalPrice);
  }

  return {
    serviceChargePercentage,
    vatPercentage,
    chargesIncluded,
    subTotal,
    serviceChargeTotal,
    vatTotal,
    totalSum,
  };
}

export function useCalculatChargeDelivery(price: number) {
  const { list } = getSelectorByKey('outlets');
  const { outletId } = useParams();
  const outlet = list.find((i) => i.id === outletId);

  const originalPrice = price;
  const vatPercentageDelivery = outlet?.vat ?? 0;
  const areChargesIncluded = outlet?.areChargesIncluded ?? 0;
  const chargesIncludedDelivery = vatPercentageDelivery !== 0;
  const [subTotalDelivery, setSubTotal] = useState<number>(0);
  const [vatTotalDelivery, setVatTotal] = useState<number>(0);
  const [totalSumDelivery, setTotalSum] = useState<number>(0);
  const deliveryCharges = localStorage.getItem('deliveryCharges');
  useEffect(() => {
    if (areChargesIncluded) {
      totalPriceCalculation();
    } else {
      subTotalCalculation();
    }
  }, [price, deliveryCharges]);

  useEffect(() => {
    if (areChargesIncluded) {
      subTotalCalculation();
    } else {
      totalPriceCalculation();
    }
  }, [vatTotalDelivery, deliveryCharges, price]);

  function subTotalCalculation() {
    let finalSubTotal;

    if (areChargesIncluded) {
      finalSubTotal = originalPrice - vatTotalDelivery;
    } else {
      finalSubTotal = originalPrice;
    }

    setSubTotal(finalSubTotal);
  }

  function vatCalculation(sum: number) {
    let finalVAT;

    if (areChargesIncluded) {
      finalVAT = (sum / (1 + vatPercentageDelivery / 100) - sum) * -1;
    } else {
      finalVAT = (sum * vatPercentageDelivery) / 100;
    }
    setVatTotal(finalVAT);
  }

  function totalPriceCalculation() {
    let finalPrice;
    const isFreeDelivery = originalPrice >= 30 ? 0 : Number(deliveryCharges);
    if (areChargesIncluded) {
      finalPrice = originalPrice;
      vatCalculation(finalPrice);
    } else {
      finalPrice = originalPrice + vatTotalDelivery;
      vatCalculation(originalPrice);
    }

    setTotalSum(finalPrice + isFreeDelivery);
  }

  return {
    vatPercentageDelivery,
    chargesIncludedDelivery,
    subTotalDelivery,
    vatTotalDelivery,
    totalSumDelivery,
    deliveryCharges,
  };
}
export function useCalculatePickup(price: number) {
  const { list } = getSelectorByKey('outlets');
  const { outletId } = useParams();
  const outlet = list.find((i) => i.id === outletId);

  const originalPrice = price;
  const vatPercentagePickup = outlet?.vat ?? 0;
  const areChargesIncluded = outlet?.areChargesIncluded ?? false;

  const [subTotalPickup, setSubTotal] = useState<number>(0);
  const [vatTotalPickup, setVatTotal] = useState<number>(0);
  const [totalSumPickup, setTotalSum] = useState<number>(0);

  useEffect(() => {
    if (areChargesIncluded) {
      calculateTotalWithCharges();
    } else {
      calculateTotalWithoutCharges();
    }
  }, [price, vatPercentagePickup, areChargesIncluded]);

  function calculateTotalWithCharges() {
    const subTotal = originalPrice / (1 + vatPercentagePickup / 100);
    const vatTotal = originalPrice - subTotal;
    const totalSum = originalPrice;

    setSubTotal(subTotal);
    setVatTotal(vatTotal);
    setTotalSum(totalSum);
  }

  function calculateTotalWithoutCharges() {
    const vatAmount = (originalPrice * vatPercentagePickup) / 100;
    const subTotal = originalPrice;
    const totalSum = originalPrice + vatAmount;

    setSubTotal(subTotal);
    setVatTotal(vatAmount);
    setTotalSum(totalSum);
  }

  return {
    vatPercentagePickup,
    chargesIncludedPickup: areChargesIncluded,
    subTotalPickup,
    vatTotalPickup,
    totalSumPickup,
  };
}
export function useCalculatChargeDeliveryHistory(price: number) {
  const { list } = getSelectorByKey('outlets');
  const { outletId } = useParams();
  const outlet = list.find((i) => i.id === outletId);

  const originalPrice = price;
  const vatPercentageDelivery = outlet?.vat ?? 0;
  const areChargesIncluded = outlet?.areChargesIncluded ?? 0;
  const chargesIncludedDelivery = vatPercentageDelivery !== 0;
  const [subTotalDelivery, setSubTotal] = useState<number>(0);
  const [vatTotalDelivery, setVatTotal] = useState<number>(0);
  const [totalSumDelivery, setTotalSum] = useState<number>(0);
  const deliveryCharges = localStorage.getItem('deliveryCharges');
  useEffect(() => {
    if (areChargesIncluded) {
      totalPriceCalculation();
    } else {
      subTotalCalculation();
    }
  }, [price]);

  useEffect(() => {
    if (areChargesIncluded) {
      subTotalCalculation();
    } else {
      totalPriceCalculation();
    }
  }, [vatTotalDelivery, price]);

  function subTotalCalculation() {
    let finalSubTotal;

    if (areChargesIncluded) {
      finalSubTotal = originalPrice - vatTotalDelivery;
    } else {
      finalSubTotal = originalPrice;
    }

    setSubTotal(finalSubTotal);
  }

  function vatCalculation(sum: number) {
    let finalVAT;

    if (areChargesIncluded) {
      finalVAT = (sum / (1 + vatPercentageDelivery / 100) - sum) * -1;
    } else {
      finalVAT = (sum * vatPercentageDelivery) / 100;
    }
    setVatTotal(finalVAT);
  }

  function totalPriceCalculation() {
    let finalPrice;
    if (areChargesIncluded) {
      finalPrice = originalPrice;
      vatCalculation(finalPrice);
    } else {
      finalPrice = originalPrice + vatTotalDelivery;
      vatCalculation(originalPrice);
    }

    setTotalSum(finalPrice);
  }

  return {
    vatPercentageDelivery,
    chargesIncludedDelivery,
    subTotalDelivery,
    vatTotalDelivery,
    totalSumDelivery,
  };
}
