import { every, filter, find, findIndex, flatMap, has, isEmpty } from 'lodash';
import React, { createRef, useRef, useState } from 'react';

import { findCategoryConfigByName, getEditItemsFromCart } from '../../../helper/cart';
import { getSelectorByKey } from '../../../helper/selector';
import { AddOn, GroupedAddOn, MenuOption, MenuOptionItem } from '../../../interfaces/models/menu';
import Attributes from '../../../pages/MenuDetail/components/attributes';
import OptionAttributes from '../../../pages/MenuDetail/components/option-attributes';
import { t } from 'i18next';

type PropsTypes = {
  isSubmitting: boolean;
  onChange?: (param: AddOn[], isValid: boolean) => void;
  onValid: (isValid: boolean) => void;
};

const Options = (props: PropsTypes) => {
  const { onChange, onValid, isSubmitting } = props;
  const options = getSelectorByKey('menu').options;
  const [selectedOptions, setOption] = React.useState<MenuOptionItem[][] | [][]>([[]]);
  const refs = useRef(new Array());
  // refs.current = details?.groupedAddOns.map((element, i) => refs.current[i] ?? createRef()) || [];
  refs.current = options?.map((element, i) => refs.current[i] ?? createRef()) || [];

  const editItem = getEditItemsFromCart();
  const [selectedValue, setSelectedValue] = useState('');
  const [isMaxSelection, setIsMaxSelection] = useState(false);
  const [optionsMaxed, setOptionsMaxed] = useState<string[]>([]);
  let disabledOptions: string[] = [];

  React.useEffect(() => {
    const valid = every(options?.map(validateOptions));
    onValid(valid);
    if (selectedOptions.length && onChange) {
      const formattedOptions = selectedOptions.map((optionGroup) =>
        optionGroup.map((option) => ({
          name: option.option,
          description: '',
          price: option.price,
          category: option.category,
          sequence: option.sequence,
          menuSequentialId: option.option, // You can set this to a proper value if needed
          isOutOfStock: option.isOutOfStock,
          stockExpectedDateTime: option.stockExpectedDateTime,
        }))
      );
      const combinedFormattedOptions = formattedOptions.reduce((accumulator, optionGroup) => {
        return accumulator.concat(optionGroup);
      }, []);

      onChange(combinedFormattedOptions, valid);
    }
  }, [selectedOptions, options]);

  React.useEffect(() => {
    if (isSubmitting) {
      const firstInvalid = options?.map(validateOptions).findIndex((e) => !e);
      if (firstInvalid != null && firstInvalid != -1) {
        refs.current[firstInvalid].current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isSubmitting]);

  function findDefaultCheck(name: string): boolean {
    const hasAdded = !isEmpty(find(editItem?.addOns, { name }));
    if (hasAdded) {
      return true;
    }

    return false;
  }

  function validateOptions(option: MenuOption, index: number) {
    const currentOptions = selectedOptions[index] ?? [];

    if (Number(option.minimum) === 0) return true;
    if (currentOptions.length < Number(option.minimum)) return false;
    if (currentOptions.length > Number(option.maximum)) return false;

    return true;
  }

  function addedOption(item: MenuOptionItem, index: number, type: string, maximum?: string) {
    while (selectedOptions.length <= index) {
      selectedOptions.push([]);
    }

    const currentIndex = findIndex(selectedOptions[index], { index: item.index });
    let currentItem = selectedOptions[index];
    if (type === 'RADIO') {
      selectedOptions[index] = [item];
      setOption([...selectedOptions]);
    }

    if (type === 'BUBBLE' && currentIndex === -1) {
      if (selectedOptions[index].length >= Number(maximum)) {
        selectedOptions[index].pop();
        setOption([...selectedOptions]);

        const addOns = [...currentItem, item];
        selectedOptions[index] = addOns;
        setOption([...selectedOptions]);
      } else {
        const addOns = [...currentItem, item];
        selectedOptions[index] = addOns;
        setOption([...selectedOptions]);
      }

      // if (selectedOptions[index].length >= Number(maximum)) {
      //   // setIsMaxSelection(true);
      //   // disabledOptions.push(selectedOptions[index][0].category);
      //   // const getCurrentOption = selectedOptions[index][0].category;
      //   // setOptionsMaxed([...optionsMaxed, getCurrentOption]);
      //   // const removedlastOption = selectedOptions[index].pop();
      //   // console.log('🚀 ~ file: index.tsx:108 ~ addedOption ~ removedlastOption:', selectedOptions);
      // } else {
      //   // const removedlastOption = selectedOptions[index].pop();
      //   // console.log('🚀 ~ file: index.tsx:108 ~ addedOption ~ removedlastOption:', removedlastOption);
      //   // setOption([...removedlastOption]);
      // }
    }
    if (type === 'CHECKBOX' && currentIndex === -1) {
      const addOns = [...currentItem, item];
      selectedOptions[index] = addOns;
      setOption([...selectedOptions]);
    }

    if (type === 'DROPDOWN') {
      selectedOptions[index] = [item];
      setOption([...selectedOptions]);
    }
  }

  function onRemoveOption(item: MenuOptionItem, index: number, maximum?: string) {
    if (Number(maximum) > 1 && selectedOptions[index].length <= Number(maximum)) {
      setIsMaxSelection(false);
      if (selectedOptions[index].length >= 1) {
        const categoryToRemove = selectedOptions[index][0].category;
        const updatedDisabledOptions = disabledOptions.filter((category) => category === categoryToRemove);
        const updatedOptionsMaxed = optionsMaxed.filter((category) => category !== categoryToRemove);
        setOptionsMaxed(updatedOptionsMaxed);
        disabledOptions = updatedDisabledOptions;
      }
    }
    if (Number(maximum) == 1) {
      const categoryToRemove = selectedOptions[index][0].category;
      const updatedDisabledOptions = disabledOptions.filter((category) => category === categoryToRemove);
      const updatedOptionsMaxed = optionsMaxed.filter((category) => category !== categoryToRemove);
      setOptionsMaxed(updatedOptionsMaxed);
      disabledOptions = updatedDisabledOptions;
    }
    const removeItem = filter(selectedOptions[index], (addOn: MenuOptionItem) => addOn.option !== item.option);
    selectedOptions[index] = removeItem;
    setOption([...selectedOptions]);
  }
  function ChangeOption(e: any, index: number, type: string, option: MenuOptionItem[]) {
    addedOption(option[e.target.value], index, type);
  }
  console.log('🚀 ~ Options ~ options:', options);

  return (
    <>
      {options &&
        options.length > 0 &&
        options.map((option: MenuOption, index: number) => {
          const isAllOptionOutofStock = option.itemOptions.every((option) => option.isOutOfStock);
          console.log('🚀 ~ Options ~ isAllOptionOutofStock:', isAllOptionOutofStock);
          const error = isSubmitting && !validateOptions(option, index) ? 'error' : '';
          if (option.maximum > 0 && !isAllOptionOutofStock) {
            return (
              <div
                ref={refs.current[index]}
                className={`attribute-wrapper row-item mt-15 ${error}`}
                key={'category-' + index}
              >
                <div className="flex-row space-between">
                  <div className="add-on-text">{option.name}</div>
                  {Number(option?.minimum) === Number(option.maximum) && (
                    <>
                      <div className="option-text">
                        {t('choose')}:{' '}
                        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{option?.minimum}</span>
                      </div>
                    </>
                  )}

                  {Number(option?.minimum) !== Number(option.maximum) && (
                    <>
                      {Number(option?.minimum) === 0 && Number(option.maximum) > 10 && (
                        <div className="option-text">Optional / ជម្រើស</div>
                      )}
                      {Number(option?.minimum) === 0 && Number(option.maximum) < 10 && (
                        <div className="option-text">
                          {t('maximum')}:{' '}
                          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                            {Number(option.maximum)}
                          </span>
                        </div>
                      )}
                      {Number(option?.minimum) !== 0 && Number(option.maximum) > 10 && (
                        <div className="option-text">
                          {t('minimum')}:{' '}
                          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{option?.minimum}</span>
                        </div>
                      )}
                      {Number(option?.minimum) !== 0 && Number(option.maximum) <= 10 && (
                        <div className="option-text">
                          {t('between')}:{' '}
                          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                            {option?.minimum}-{Number(option.maximum)}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div
                  // make add ons into grids
                  className={`${option.visualizationType == 'BUBBLE' && 'options-bubble-grid'}`}
                >
                  {option.visualizationType == 'DROPDOWN' ? (
                    <select
                      id="menu-add-on"
                      className="addOn-drop-down"
                      onChange={(e) => ChangeOption(e, index, option.visualizationType, option.itemOptions)}
                      defaultValue={'none'}
                    >
                      <option hidden>{t('select_an_option')}</option>
                      {option.itemOptions.map((optionItem: MenuOptionItem, idx) => {
                        if (!optionItem.isOutOfStock)
                          return (
                            <>
                              {/* <option value={idx}>{option.name}</option> */}
                              <OptionAttributes
                                sequence={optionItem.sequence}
                                selectedOptions={selectedOptions}
                                groupId={index}
                                hasSoldOut={optionItem.isOutOfStock}
                                _id={idx.toString()}
                                item={optionItem}
                                type={option.visualizationType}
                                defaultChecked={findDefaultCheck(optionItem.option)}
                                name={'add-on-' + idx}
                                maximum={option.maximum}
                                // onClicked={() => addedOption(optionItem, index, option.visualizationType)}
                                onRemoved={() => onRemoveOption(optionItem, index)}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                              />
                            </>
                          );
                      })}
                    </select>
                  ) : (
                    option.itemOptions.map((optionItem: MenuOptionItem, idx) => {
                      if (!optionItem.isOutOfStock)
                        return (
                          <div className="" style={{}} key={'addOns' + idx}>
                            <OptionAttributes
                              sequence={optionItem.sequence}
                              selectedOptions={selectedOptions}
                              groupId={index}
                              hasSoldOut={optionItem.isOutOfStock}
                              _id={idx.toString()}
                              item={optionItem}
                              type={option.visualizationType}
                              defaultChecked={findDefaultCheck(optionItem.option)}
                              name={'add-on-' + idx}
                              maximum={option.maximum}
                              isMaxSelection={isMaxSelection}
                              disabledOptions={optionsMaxed}
                              onClicked={() => addedOption(optionItem, index, option.visualizationType, option.maximum)}
                              onRemoved={() => onRemoveOption(optionItem, index, option.maximum)}
                              selectedValue={selectedValue}
                              setSelectedValue={setSelectedValue}
                            />
                          </div>
                        );
                    })
                  )}
                </div>
              </div>
            );
          } else if (option.minimum === 0 && !isAllOptionOutofStock) {
            return (
              <div
                ref={refs.current[index]}
                className={`attribute-wrapper row-item mt-15 ${error}`}
                key={'category-' + index}
              >
                <div className="flex-row space-between">
                  <div className="add-on-text">{option.name}</div>
                  {Number(option?.minimum) === Number(option.maximum) && (
                    <>
                      <div className="option-text">
                        {t('choose')}:{' '}
                        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{option?.minimum}</span>
                      </div>
                    </>
                  )}

                  {Number(option?.minimum) !== Number(option.maximum) && (
                    <>
                      {Number(option?.minimum) === 0 && Number(option.maximum) > 10 && (
                        <div className="option-text">Optional / ជម្រើស</div>
                      )}
                      {Number(option?.minimum) === 0 && Number(option.maximum) < 10 && (
                        <div className="option-text">
                          {t('maximum')}:{' '}
                          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                            {Number(option.maximum)}
                          </span>
                        </div>
                      )}
                      {Number(option?.minimum) !== 0 && Number(option.maximum) > 10 && (
                        <div className="option-text">
                          {t('minimum')}:{' '}
                          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{option?.minimum}</span>
                        </div>
                      )}
                      {Number(option?.minimum) !== 0 && Number(option.maximum) <= 10 && (
                        <div className="option-text">
                          {t('between')}:{' '}
                          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                            {option?.minimum}-{Number(option.maximum)}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div
                  // make add ons into grids
                  className={`${option.visualizationType == 'BUBBLE' && 'options-bubble-grid'}`}
                >
                  {option.visualizationType == 'DROPDOWN' ? (
                    <select
                      id="menu-add-on"
                      className="addOn-drop-down"
                      onChange={(e) => ChangeOption(e, index, option.visualizationType, option.itemOptions)}
                      defaultValue={'none'}
                    >
                      <option hidden>{t('select_an_option')}</option>
                      {option.itemOptions.map((optionItem: MenuOptionItem, idx) => {
                        if (!optionItem.isOutOfStock)
                          return (
                            <>
                              {/* <option value={idx}>{option.name}</option> */}
                              <OptionAttributes
                                sequence={optionItem.sequence}
                                selectedOptions={selectedOptions}
                                groupId={index}
                                hasSoldOut={optionItem.isOutOfStock}
                                _id={idx.toString()}
                                item={optionItem}
                                type={option.visualizationType}
                                defaultChecked={findDefaultCheck(optionItem.option)}
                                name={'add-on-' + idx}
                                maximum={option.maximum}
                                // onClicked={() => addedOption(optionItem, index, option.visualizationType)}
                                onRemoved={() => onRemoveOption(optionItem, index)}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                              />
                            </>
                          );
                      })}
                    </select>
                  ) : (
                    option.itemOptions.map((optionItem: MenuOptionItem, idx) => {
                      if (!optionItem.isOutOfStock)
                        return (
                          <div className="" style={{}} key={'addOns' + idx}>
                            <OptionAttributes
                              sequence={optionItem.sequence}
                              selectedOptions={selectedOptions}
                              groupId={index}
                              hasSoldOut={optionItem.isOutOfStock}
                              _id={idx.toString()}
                              item={optionItem}
                              type={option.visualizationType}
                              defaultChecked={findDefaultCheck(optionItem.option)}
                              name={'add-on-' + idx}
                              maximum={option.maximum}
                              isMaxSelection={isMaxSelection}
                              disabledOptions={optionsMaxed}
                              onClicked={() => addedOption(optionItem, index, option.visualizationType, option.maximum)}
                              onRemoved={() => onRemoveOption(optionItem, index, option.maximum)}
                              selectedValue={selectedValue}
                              setSelectedValue={setSelectedValue}
                            />
                          </div>
                        );
                    })
                  )}
                </div>
              </div>
            );
          } else if (option.minimum > 0 && isAllOptionOutofStock) {
            return (
              <div
                ref={refs.current[index]}
                className={`attribute-wrapper row-item mt-15 ${error}`}
                key={'category-' + index}
              >
                <div className="flex-row space-between">
                  <div className="add-on-text">{option.name}</div>
                  <div className="option-text">Out Of Stock</div>
                </div>
                <div
                  // make add ons into grids
                  className={`${option.visualizationType == 'BUBBLE' && 'options-bubble-grid'}`}
                >
                  {option.visualizationType == 'DROPDOWN' ? (
                    <select
                      id="menu-add-on"
                      className="addOn-drop-down"
                      onChange={(e) => ChangeOption(e, index, option.visualizationType, option.itemOptions)}
                      defaultValue={'none'}
                    >
                      <option hidden>{t('select_an_option')}</option>
                      {option.itemOptions.map((optionItem: MenuOptionItem, idx) => {
                        // if (!optionItem.isOutOfStock)
                        return (
                          <>
                            {/* <option value={idx}>{option.name}</option> */}
                            <OptionAttributes
                              sequence={optionItem.sequence}
                              selectedOptions={selectedOptions}
                              groupId={index}
                              hasSoldOut={true}
                              _id={idx.toString()}
                              item={optionItem}
                              type={option.visualizationType}
                              defaultChecked={findDefaultCheck(optionItem.option)}
                              name={'add-on-' + idx}
                              maximum={option.maximum}
                              // onClicked={() => addedOption(optionItem, index, option.visualizationType)}
                              onRemoved={() => onRemoveOption(optionItem, index)}
                              selectedValue={selectedValue}
                              setSelectedValue={setSelectedValue}
                            />
                          </>
                        );
                      })}
                    </select>
                  ) : (
                    option.itemOptions.map((optionItem: MenuOptionItem, idx) => {
                      // if (!optionItem.isOutOfStock)
                      return (
                        <div className="" style={{}} key={'addOns' + idx}>
                          <OptionAttributes
                            sequence={optionItem.sequence}
                            selectedOptions={selectedOptions}
                            groupId={index}
                            hasSoldOut={true}
                            _id={idx.toString()}
                            item={optionItem}
                            type={option.visualizationType}
                            defaultChecked={findDefaultCheck(optionItem.option)}
                            name={'add-on-' + idx}
                            maximum={option.maximum}
                            isMaxSelection={isMaxSelection}
                            disabledOptions={optionsMaxed}
                            onClicked={() => addedOption(optionItem, index, option.visualizationType, option.maximum)}
                            onRemoved={() => onRemoveOption(optionItem, index, option.maximum)}
                            selectedValue={selectedValue}
                            setSelectedValue={setSelectedValue}
                          />
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            );
          }
        })}
    </>
  );
};

export default Options;
