import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { generatePathHelpForHomePage } from '../../../helper/path';
import { useAppSelector } from 'hooks/redux';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
interface TypeProps {
  onClosed: () => void;
}

const Confirmation = (props: TypeProps) => {
  const navigate = useNavigate();
  const route = useParams();
  const { search } = useLocation();
  const [countdown, setCountdown] = useState(5);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor = detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { t } = useTranslation();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000); // decrease the value of countdown by 1 every second

    if (countdown === 0) {
      clearInterval(intervalId); // stop the interval when countdown reaches 0
      props.onClosed();
      clickedSubmit();
    }

    return () => {
      document.body.style.overflow = 'unset';
      clearInterval(intervalId);
    }; // cleanup function to clear the interval when component unmounts
  }, [countdown]);

  function clickedSubmit() {
    const { outletId, orgId } = route;
    if (outletId && orgId) {
      navigate(generatePathHelpForHomePage('/rating-menu', { outletId, orgId }, search));
    }
  }
  function clickedSkip() {
    const { outletId, orgId } = route;
    props.onClosed();
    if (outletId && orgId) {
      navigate(generatePathHelpForHomePage('/thank-you', { outletId, orgId }, search));
    }
  }
  return (
    <>
      <div className="bottom-sheet-wrapper">
        <div className="bottom-sheet-content content" style={{ overflow: 'unset' }}>
          <img src="/assets/icons/thumb-up.svg" alt="" className="position-absolute" />
          <div className="confirmation-title mt-86">{t('our_staff_will_attend')}</div>
          <p className="confirmation-paragraph mt-28">{t('we_value_your_feedback')}</p>
          <button
            className="btn primary mt-49"
            onClick={clickedSubmit}
            style={{ borderColor: brandColor, backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40` }}
          >
            {t('rate_experience')} {countdown}
          </button>

          <div className="flex-row center mt-48" onClick={clickedSkip} style={{ color: brandColor }}>
            {/* <img src="/assets/icons/back-primary.svg" alt="" /> */}
            <Icon icon="ep:arrow-left-bold" width={20} />
            <div className="back-review-text ml-14" style={{ color: brandColor }}>
              {t('skip_feedback')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
