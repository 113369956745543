import { getRatesService, postShippingService } from 'services/kess';
import { CategoryModel } from '../../interfaces/models/category';
import { getCategoryService } from '../../services/category';
import { GetRatesParam, GetRatesResponse } from 'interfaces/models/getRates';
import { PostShipParam, PostShipResponse } from 'interfaces/models/postShip';

export function getRates(param: GetRatesParam): Promise<GetRatesResponse> {
  localStorage.setItem('getRatesParam', JSON.stringify(param));
  return getRatesService(param);
}
export function postShip(param: PostShipParam): Promise<PostShipResponse> {
  return postShippingService(param);
}
