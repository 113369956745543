import { callingWaiter } from 'actions/outlets';
import { MyImages } from 'cores/components';
import { PaymentType, PaymentName } from 'enums/payment-type';
import { PaymentMethods, PaymentTypeUnion } from 'interfaces/models/outlets';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { getSpotId } from '../../../helper/path';
import { Icon } from '@iconify/react';
import { RootState } from 'store/configureStore';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../LoadingSpinner';
import {
  SPOT_CINEMA_COUNTER,
  SPOT_DELIVERY,
  SPOT_DINE_IN,
  SPOT_QRTABLE,
  SPOT_RESERVATION_PREORDER,
  SPOT_TABLE_RESERVATION,
} from 'constants/spot';
import { getSpotById } from 'helper/spot';
import { FooterLogo } from '../FooterLogo';

type TypProps = {
  cancelCalled: () => void;
  clickedSubmit: (param: PaymentTypeUnion, method: string, notes?: string) => void;
  totalSum?: number;
  cinemaInfo?: any;
  // setPaymentMethod?: (param: string) => void;
};

const SelectPaymentMethod = ({ cancelCalled, clickedSubmit, totalSum, cinemaInfo }: TypProps) => {
  const dispatch = useDispatch();
  const { outletId } = useParams();
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const paymentMethods = useAppSelector((state: RootState) => state.outlets.list);
  const [openNote, setOpenNote] = useState(false);
  const [note, setNote] = useState('');
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const [paymentLoading, setPaymentLoading] = useState(false);

  const { t } = useTranslation();
  function clickPaymentMethod(type: PaymentTypeUnion, method: string, cashNote?: string) {
    localStorage.setItem('paymentMethod', type);
    if (type === 'CASHWITHNOTE') {
      if (openNote) {
        clickedSubmit(type, method, cashNote);
      }
    } else {
      clickedSubmit(type, method);
    }
    switch (type) {
      case 'CASHWITHNOTE':
        if (openNote) {
          callForWaiter(type, cashNote);
          cancelCalled();
          setOpenNote(false);
        } else {
          setOpenNote(true);
        }

        break;
      case 'PAYONLINE':
        callForWaiter(type);
        break;
      default:
        callForWaiter(type);
        cancelCalled();
        break;
    }
  }
  const callForWaiter = (type: PaymentTypeUnion, cashNote?: string) => {
    const param = {
      type: 'Call for Bill',
      message: type === 'CASHWITHNOTE' && cashNote ? `${cashNote}` : '',
      paymentType: PaymentType[type],
    };
    if (outletId && spotId && spot.type != SPOT_CINEMA_COUNTER) {
      dispatch(callingWaiter(spotId, outletId, param));
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  function paymentMethodButton(type: string) {
    let name = '';
    let methodName: PaymentTypeUnion = 'CASH';
    let img;
    let paymentMethodOnline = '';
    let fee = 0;

    const handleClick = (type: PaymentTypeUnion, onlineMethod: string) => {
      clickPaymentMethod(type, onlineMethod);
    };
    switch (type) {
      case PaymentName.CASHWITHNOTE:
        name = t('cash_w_note');
        methodName = 'CASHWITHNOTE';
        img = <Icon icon="fluent:money-hand-24-regular" width={40} color={brandColor} />;
        paymentMethodOnline = '';
        break;
      case PaymentName.VISA_MASTER:
        paymentMethodOnline = 'VISA_MASTER';
        name = 'VISA/MASTER CARD';
        methodName = 'PAYONLINE';
        img = <Icon icon="majesticons:creditcard" width={40} color={brandColor} />;
        fee = calculateThreePercent(totalSum ? totalSum : 0);
        break;
      case PaymentName.ABA:
        paymentMethodOnline = 'ABAAKHPP';
        name = 'ABA Payway';
        methodName = 'PAYONLINE';
        img = (
          <img
            src="/assets/icons/aba-square.png"
            style={{
              width: '40px',
              borderRadius: '0.5rem',
            }}
          />
        );
        break;
      case PaymentName.SBPLKHPP:
        paymentMethodOnline = 'SBPLKHPP';
        name = 'Sathapana iBanking';
        methodName = 'PAYONLINE';
        img = (
          <img
            src="/assets/icons/sathapana-square.jpg"
            style={{
              width: '40px',
              borderRadius: '0.5rem',
            }}
          />
        );
        break;
      case PaymentName.ACLBKHPP:
        paymentMethodOnline = 'ACLBKHPP';
        name = 'Acleda Bank';
        methodName = 'PAYONLINE';
        img = (
          <img
            src="/assets/icons/acleda-square.png"
            style={{
              width: '40px',
              borderRadius: '0.5rem',
            }}
          />
        );
        break;
      case PaymentName.CC:
        name = t('credit_card');
        methodName = 'CC';
        img = <Icon icon="majesticons:creditcard" width={40} color={brandColor} />;
        paymentMethodOnline = '';
        break;
      case PaymentName.QR:
        name = t('scan_khqr');
        methodName = 'QR';
        img = (
          <img
            src="/assets/icons/khqr-red.png"
            style={{
              width: '40px',
              borderRadius: '0.2rem',
            }}
          />
        );
        paymentMethodOnline = '';
        break;
      case PaymentName.BILL:
        name = t('bill_to_room');
        methodName = 'BILL';
        img = <Icon icon="uil:bill" width={40} color={brandColor} />;
        paymentMethodOnline = '';
        break;

      default:
        paymentMethodOnline = '';
        name = t('cash');
        methodName = 'CASH';
        img = <Icon icon="fluent:money-hand-24-regular" width={40} color={brandColor} />;
        break;
    }
    return (
      <button
        onClick={() => handleClick(methodName, paymentMethodOnline)}
        className=" column-gap-10 btn light-primary mt-35"
        style={{ padding: '10px 10px', borderColor: brandColor }}
      >
        <div
          key={name}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {img}
          <span style={{ color: '#474747', textAlign: 'left', fontSize: '18px' }}>{name}</span>
          {fee > 0 && (
            <span
              style={{
                color: brandColor,
                fontSize: '14px',
                fontWeight: '450',
                width: '23%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <span style={{ fontWeight: '550' }}>Fee:&nbsp;</span>${Number(fee).toFixed(2)}
            </span>
          )}
        </div>
      </button>
    );
  }
  const calculateThreePercent = (total: number): number => {
    const threePercent = (3 / 100) * total;
    return threePercent;
  };
  return (
    <div className="blur-background call-stuff-for-bill-wrapper opacity">
      <div className="overlay" onClick={cancelCalled}></div>
      {openNote ? (
        <div className="content">
          <div className="your-payment-method-txt">{t('specify_payment_note')}</div>
          <div
            style={{
              gap: '5px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <div style={{ gap: '5px', display: 'flex', flexDirection: 'column', width: '50%' }}>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 10$'), setNote('Payment Note - 10$');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>10$</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 20$'), setNote('Payment Note - 20$');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>20$</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 50$'), setNote('Payment Note - 50$');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>50$</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 100$'), setNote('Payment Note - 100$');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>100$</span>
              </button>
            </div>
            <div
              style={{
                gap: '5px',
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
              }}
            >
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 10,000៛'), setNote('Payment Note - 10,000៛');
                }}
                className=" btn light-primary mt-35"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>10,000៛</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 20,000៛'), setNote('Payment Note - 20,000៛');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>20,000៛</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 50,000៛'), setNote('Payment Note - 50,000៛');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>50,000៛</span>
              </button>
              <button
                onClick={() => {
                  clickPaymentMethod('CASHWITHNOTE', '', 'Payment Note - 100,000៛'), setNote('Payment Note - 100,000៛');
                }}
                className=" btn light-primary "
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  borderColor: brandColor,
                  color: brandColor,
                }}
              >
                <Icon icon="material-symbols:payments-outline" width={28} />
                <span style={{ width: '80%' }}>100,000៛</span>
              </button>
            </div>
          </div>
          <div style={{ marginTop: '30px', marginBottom: '-3vh' }}>
            <FooterLogo />
          </div>
        </div>
      ) : (
        <div
          className="content"
          style={{
            maxHeight: '70vh',
            overflowY: 'scroll',
          }}
        >
          <div className="your-payment-method-txt">{t('your_payment_method')}</div>
          {paymentMethods[0]?.paymentMethods && paymentMethods[0]?.paymentMethods.length > 0 ? (
            paymentMethods[0].paymentMethods.map((item: PaymentMethods, index: number) => {
              if (item.name !== 'Bill to room' && spot.type != SPOT_DINE_IN)
                return <div key={index}>{paymentMethodButton(item.name)}</div>;
            })
          ) : (
            <>
              {spot.type != SPOT_RESERVATION_PREORDER && (
                <button
                  onClick={() => clickPaymentMethod('CASH', '')}
                  className="flex-row center column-gap-10 btn light-primary mt-35"
                  style={{ padding: '19px 0' }}
                >
                  <MyImages src="/assets/icons/wallet.svg" />
                  <span>Cash</span>
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectPaymentMethod;
