export const GET_MENU_LIST_REQUEST = '@@menu/GET_MENU_LIST_REQUEST';
export const GET_MENU_LIST_REQUEST_SUCCESS = '@@menu/GET_MENU_LIST_REQUEST_SUCCESS';

export const GET_MENU_DETAILS_REQUEST = '@@menu/GET_MENU_DETAILS_REQUEST';
export const GET_MENU_OPTIONS = '@@menu/GET_MENU_OPTIONS';
export const GET_MENU_DETAILS_REQUEST_SUCCESS = '@@menu/GET_MENU_DETAILS_REQUEST_SUCCESS';
export const GET_MENU_OPTIONS_REQUEST_SUCCESS = '@@menu/GET_MENU_OPTIONS_REQUEST_SUCCESS';

export const GROUP_MENU_LIST_REQUEST_SUCCESS = '@@menu/GROUP_MENU_LIST_REQUEST_SUCCESS';

export const CREATE_MENU_FEED_BACK = '@@menu/CREATE_MENU_FEED_BACK';
export const CREATE_MENU_FEED_BACK_SUCCESS = '@@menu/CREATE_MENU_FEED_BACK_SUCCESS';
