import { TileLayer, MapContainer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import L from 'leaflet';
import { IDeliveryInfo } from 'interfaces/models/deliveryInfo';
import { get } from 'lodash';
import { calculateTravelTime } from 'helper/map';
import { useAppSelector } from 'hooks/redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store/configureStore';
import { useTranslation } from 'react-i18next';

interface TypeProps {
  coordinates: google.maps.LatLngLiteral | null;
  setDeliveryInfo: (value: IDeliveryInfo) => void;
  deliveryInfo: IDeliveryInfo;
  setCoordinates: (value: google.maps.LatLngLiteral | null) => void;
  setError: (value: boolean) => void;
  price: number;
}

export default function OpenStreetMap(props: TypeProps) {
  const { coordinates, setCoordinates, setDeliveryInfo, deliveryInfo, setError, price } = props;

  const { list } = useAppSelector((state: RootState) => state.outlets);
  const { outletId } = useParams();
  const filteredList = list.filter((item: any) => item.id === outletId);
  const maximumDistanceInKilometers = list[0].minDistanceInKMToPlaceOrder;
  const { t, i18n } = useTranslation();

  const mapRef = useRef<any>();
  const [currentLocation, setCurrentLocation] = useState({
    latitude: 0,
    longitude: 0,
    display_name: '',
  });

  const [positionRetrieved, setPositionRetrieved] = useState(false);

  useEffect(() => {
    if (deliveryInfo !== null) {
      if (coordinates !== null && coordinates.lat !== 0 && coordinates.lng !== 0) {
        setCurrentLocation({
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          display_name: `Your Location.`,
        });
        getDetails(coordinates.lat, coordinates.lng);
      } else {
        navigator.geolocation.getCurrentPosition(getCurrentCityName);
      }
    } else {
      navigator.geolocation.getCurrentPosition(getCurrentCityName);
    }
  }, [coordinates]);

  function getCurrentCityName(position: any) {
    const url =
      'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' +
      position.coords.latitude +
      '&lon=' +
      position.coords.longitude;
    fetch(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => {
        setCurrentLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          display_name: `Your Location.`,
        });
        getDetails(position.coords.latitude, position.coords.longitude);
      })
      .then(() => {
        setPositionRetrieved(true);
      });
  }

  const customIcon = L.icon({
    iconUrl: '/assets/icons/pin.svg',
    iconSize: [20, 20],
    iconAnchor: [10, 20],
    popupAnchor: [0, -30],
  });

  function LocationMarker() {
    const markerRef = useRef<any>(null);

    const eventHandlers = useMemo(
      () => ({
        dragend(e: any) {
          const marker = markerRef.current;
          if (marker != null) {
            const newPosition = marker.getLatLng();
            getDetails(newPosition.lat, newPosition.lng);
          }
        },
      }),
      []
    );

    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        icon={customIcon}
        position={[currentLocation.latitude, currentLocation.longitude]}
        ref={markerRef}
      >
        <Popup>{currentLocation.display_name}</Popup>
      </Marker>
    );
  }
  function MapClickHandler() {
    const map = useMapEvents({
      click(e) {
        getDetails(e.latlng.lat, e.latlng.lng);
        map.flyTo(e.latlng, map.getZoom());
      },
    });

    return null;
  }
  function getDetails(lat: any, lng: any) {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;

    fetch(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => {
        setError(false);
        setCurrentLocation({
          latitude: lat,
          longitude: lng,
          display_name: `${data.display_name}.`,
        });
        calculateTravelTime(
          deliveryInfo,
          setDeliveryInfo,
          filteredList,
          { lat: lat, lng: lng },
          maximumDistanceInKilometers,
          data.display_name,
          price
        );
        if (mapRef.current) {
          mapRef.current.flyTo([lat, lng], mapRef.current.getZoom());
        }
      })
      .then(() => {
        setPositionRetrieved(true);
      })
      .catch((error) => {
        setError(true);

        // Handle the error here
        console.error('Error in OpenMapStreet API', error);
        // Handle any necessary actions for the error
      });
  }

  return (
    <>
      {positionRetrieved && (
        <div className="scale-in-center" style={{ width: '85vw', height: '25vh' }}>
          <MapContainer
            ref={mapRef}
            center={[currentLocation.latitude, currentLocation.longitude]}
            zoom={15}
            scrollWheelZoom={false}
          >
            {i18n.language === 'en' ? (
              <TileLayer url="https://maptiles.p.rapidapi.com/en/map/v1/{z}/{x}/{y}.png?rapidapi-key=6f276e5947mshd9505e6b8136a2ep10761bjsn535b4aa69be4" />
            ) : (
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            )}

            <LocationMarker />
            <MapClickHandler />
          </MapContainer>
        </div>
      )}
    </>
  );
}
