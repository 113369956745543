import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { FooterLogo } from '../FooterLogo';

const Loading = () => {
  const icons = [
    'fluent-emoji:hot-dog',
    'fluent-emoji:cooked-rice',
    'fluent-emoji:hot-beverage',
    'fluent-emoji:cookie',
    'fluent-emoji:pot-of-food',
    'fluent-emoji:hamburger',
    'fluent-emoji:wine-glass',
    'fluent-emoji:doughnut',
    'fluent-emoji:spaghetti',
    'fluent-emoji:teacup-without-handle',
    'fluent-emoji:cupcake',
    'fluent-emoji:bubble-tea',
    'fluent-emoji:beer-mug',
    'fluent-emoji:pizza',
    'fluent-emoji:baguette-bread',
  ];

  const initialIconIndex = Math.floor(Math.random() * icons.length);

  const [currentIconIndex, setCurrentIconIndex] = useState(initialIconIndex);
  const [isBlurred, setIsBlurred] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBlurred(true);
      setTimeout(() => {
        setCurrentIconIndex((prevIndex) => (prevIndex + 1) % icons.length);
        setIsBlurred(false);
      }, 500);
    }, 1500);
    localStorage.setItem('isBack', 'true');
    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentIcon = icons[currentIconIndex];

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px',
        backgroundColor: '#f5f5f5',
      }}
    >
      <div
        style={{
          color: '#64676C',
          fontSize: '25px',
          marginBottom: '20px',
        }}
      >
        Hi! Welcome
      </div>
      <div
        id="icons-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          width: '40px',
          height: '40px',
        }}
      >
        <Icon
          icon={currentIcon}
          width={35}
          style={{
            transition: 'opacity 0.5s',
            opacity: isBlurred ? 0 : 1,
          }}
        />
      </div>

      <div
        style={{
          color: '#64676C',
        }}
      >
        Please Wait...
      </div>
      <div
        style={{
          color: '#64676C',
          marginLeft: '40px',
          marginRight: '40px',
          fontSize: '16px',
          textAlign: 'center',
        }}
      >
        We are preparing your digital menu and you will be able to order shortly...
      </div>
      <div
        style={{
          marginTop: '50px',
        }}
      >
        <FooterLogo />
      </div>
    </div>
  );
};

export default Loading;
