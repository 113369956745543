import axios from 'axios';
import { RequestPayment } from 'interfaces/models/requestPayment';
import { toast } from 'react-toastify';

export async function paymentRequestService(params: RequestPayment): Promise<any> {
  // const url = 'https://corsproxy.io/?' + encodeURIComponent('https://ntn.davoit.com/request-payment');
  const url = 'https://test.e-bot.io/transaction/quote';
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer 7f9ab479-aab2-ab14-c6ef5ff04e10`,
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      toast.error('Error in Payment Request Service');
      throw new Error('Network response was not ok');
    }

    const responseData = await response.text();
    return responseData;
  } catch (error) {
    console.error('Error in paymentRequestService:', error);
    toast.error('Error in Payment Request Service');
    throw error;
  }
}
