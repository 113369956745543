/**
 * @constant PATH - The `GET` method requests a representation of the specified resource. Requests using `GET` should only retrieve data.
 */
export const GET = 'GET';

/**
 * @constant PATH - The `POST` method submits an entity to the specified resource, often causing a change in state or side effects on the server.
 */
export const POST = 'POST';

/**
 * @constant PATH - The `PUT` method replaces all current representations of the target resource with the request payload.
 */
export const PUT = 'PUT';

/**
 * @constant PATH - The `DELETE` method deletes the specified resource.
 */
export const DELETE = 'DELETE';

/**
 * @constant PATH - The `PATCH` method applies partial modifications to a resource.
 */
export const PATCH = 'PATCH';

/**
 * @constant PATH - The `OPTIONS` method describes the communication options for the target resource.
 */
export const OPTION = 'OPTION';
