import './App.css';
import './styles/_index.scss';

import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// Routes
import Router from './routes';
import CacheBuster from 'react-cache-buster';
import Loading from 'components/partials/Loading';

function App() {
  const isProduction = (window as any).NTN_ENV === 'production';
  const version = (window as any).VERSION_CACHE;
  console.log('🚀 ~ file: App.tsx:14 ~ App ~ version:', version, (window as any).NTN_ENV);
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      reloadOnDowngrade={false}
      isVerboseMode={true}
      loadingComponent={<Loading />}
    >
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </CacheBuster>
  );
}

export default App;
