import {
  CART_REQUEST_ADDED,
  CART_REQUEST_EMPTY,
  CART_UPDATE_DELIVERY_DISTANCE,
  CART_UPDATE_DELIVERY_FEE,
  CART_UPDATE_ITEM,
  CART_UPDATE_QUANTITY,
} from 'constants/carts';
import { concat, sumBy } from 'lodash';

import { PayloadAction } from '@reduxjs/toolkit';

import { Customer, Item } from '../../interfaces/models/carts';
import { calculateDiscountedPrice } from 'cores/components';

const initState: StateType = {
  status: 0,
  items: [],
  price: 0,
  deliveryFee: 0,
  deliveryDistance: 0,
};

type StateType = {
  customer?: Customer;
  status: number;
  items: Item[];
  price: number;
  deliveryFee?: number;
  deliveryDistance?: number;
};

type StateAddType = {
  customer?: Customer;
  status: number;
  items: Item;
  price: number;
};

type StateEditType = StateAddType & {
  index: number;
};

type StateUpdate = {
  menuId: string;
  quantity: number;
  index: number;
};

type StateUpdateDeliveryFee = {
  deliveryFee: number;
};

type StateUpdateDeliveryDistance = {
  deliveryDistance: number;
};

type PayloadType =
  | StateType
  | StateAddType
  | StateEditType
  | StateUpdate
  | StateUpdateDeliveryFee
  | StateUpdateDeliveryDistance;

export default function carts(state: StateType = initState, action: PayloadAction<PayloadType>): StateType {
  switch (action.type) {
    case CART_REQUEST_ADDED:
      state.price = state.price + (action.payload as StateType).price;
      state.items = concat(state.items, [(action.payload as StateAddType).items]);
      break;

    case CART_REQUEST_EMPTY:
      state.price = 0;
      state.items = [];
      break;

    case CART_UPDATE_QUANTITY:
      state = updateQuantity(state, action.payload as StateUpdate);
      break;

    case CART_UPDATE_ITEM:
      state = updateItem(state, action.payload as StateEditType);
      break;

    case CART_UPDATE_DELIVERY_FEE:
      state.deliveryFee = (action.payload as StateUpdateDeliveryFee).deliveryFee;
      break;

    case CART_UPDATE_DELIVERY_DISTANCE:
      state.deliveryDistance = (action.payload as StateUpdateDeliveryDistance).deliveryDistance;
      break;
    default:
      break;
  }

  return { ...state };
}

function updateItem(state: StateType, payload: StateEditType): StateType {
  const items = state.items[payload.index];

  if (items) {
    state.items[payload.index] = payload.items;
    if (payload.items.quantity === 0) {
      state.items = state.items.filter((_, index: number) => index !== payload.index);
    }

    state.price = sumBy(state.items, (item: Item) => {
      return item.discountedPrice && item.discountedPrice !== 0
        ? (Number(item.discountedPrice) + Number(sumBy(item.addOns, 'price'))) * item.quantity
        : (Number(item.price) + Number(sumBy(item.addOns, 'price'))) * item.quantity;
    });
  }

  return { ...state };
}

function updateQuantity(state: StateType, payload: StateUpdate): StateType {
  if (payload.quantity !== 0) {
    state.items[payload.index].quantity = payload.quantity;
  }

  if (payload.quantity === 0 && payload) {
    state.items = state.items.filter((_, index: number) => index !== payload.index);
  }
  state.price = sumBy(state.items, (item: Item) => {
    return item.discountedPrice && item.discountedPrice !== 0
      ? (Number(item.discountedPrice) + Number(sumBy(item.addOns, 'price'))) * item.quantity
      : (Number(item.price) + Number(sumBy(item.addOns, 'price'))) * item.quantity;
  });
  return { ...state };
}
