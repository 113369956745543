import { createListAction } from 'generics/action-creator';
import { Action, Dispatch } from 'redux';

import { CREATE_ORDER_REQUEST } from '../../constants/order';
import { CreateOrderResponse, Order } from '../../interfaces/models/order';
import outlets from '../../reducers/outlets/index';
import { createOrderByMenuBySpotId } from '../../services/order';
import { RootState } from '../../store/configureStore';

interface OrderParam {
  spotId: string;
  outletId: string;
  body: Order;
}

export const createOrder =
  ({ spotId, body, outletId }: OrderParam): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const options = {
      serviceMethod: createOrderByMenuBySpotId,
      dispatch,
      getState,
      requestName: CREATE_ORDER_REQUEST,
    };

    return createListAction<CreateOrderResponse, any>(options, { spotId, outletId, body });
  };
