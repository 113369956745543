import { calculateDiscountedPrice, Quantity } from 'cores/components';
import { Item } from 'interfaces/models/carts';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { CART_UPDATE_QUANTITY } from '../../../constants/carts';
import { getSpotId } from '../../../helper/path';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/index';
import { AddOn } from '../../../interfaces/models/menu';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { QuantitySmall } from 'cores/components/Quantity/indexSmall';
type PropTypes = {
  item: Item;
  index: number;
  quantityChange?: (param: number) => void;
};

const OrderItem = (props: PropTypes) => {
  const { name, quantity, specialRequest, menuId, price, addOns, promotion } = props.item;
  const dispatch = useAppDispatch();
  const spotId = getSpotId();
  const navigate = useNavigate();
  const { orgId, outletId } = useParams();
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor = detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const [sQuantity, setSQuantity] = useState(quantity ?? 0);
  const { t } = useTranslation();
  useEffect(() => {}, [sQuantity]);

  const clickQuantityChange = (quantity: number) => {
    const { quantityChange } = props;
    const payload = { menuId, quantity, index: props.index };
    setSQuantity(quantity);
    dispatch({ type: CART_UPDATE_QUANTITY, payload });
    if (quantityChange) quantityChange(quantity);
  };

  function generatePathHelper() {
    if (orgId && outletId) {
      const pathname = generatePath('/org/:orgId/outlet/:outletId/menu/:id', { orgId, outletId, id: menuId });
      const search = `?spotId=${spotId}&orderItem=${props.index}`;
      navigate({ pathname, search });
    }
  }

  return (
    <div className="menu-wrapper">
      <div className="flex-row space-between column-gap-20">
        <div className="bottom-sheet-menu-title">{name}</div>
        <div className="bottom-sheet-menu-title" style={{ flexShrink: 0 }}>
          $ {calculateDiscountedPrice(promotion?.value, promotion?.discountType, price).toFixed(2)}
        </div>
      </div>
      {addOns &&
        addOns?.length > 0 &&
        addOns?.map((addOn: AddOn, index) => (
          <div className="flex-row attributes space-between column-gap-4 mt-11" key={index}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src="/assets/icons/plus-circle-grey.svg" alt="" />
              <span>{addOn?.name}</span>
            </div>
            <span>$ {addOn?.price.toFixed(2)}</span>
          </div>
        ))}
      <div className="attributes mt-11">{specialRequest}</div>
      <div className="flex-row space-between mt-19 mb-19">
        <div onClick={generatePathHelper} className="flex-row column-gap-10">
          {/* <img src="/assets/icons/edit-pencil.svg" alt="" /> */}
          <Icon icon="charm:pencil" color={brandColor} width={20} />
          <div className="edit-text" style={{ color: brandColor }}>
            {t('edit')}
          </div>
        </div>
        <QuantitySmall startedWith={quantity} size="small" onChange={clickQuantityChange} />
      </div>
    </div>
  );
};

export default OrderItem;
