import React, { useEffect, useState } from 'react';
import 'styles/components/_location.scss';

interface TypeProps {
  setPopUp: (value: boolean) => void;
  messageID: number;
  distance: number;
  // requestGeolocation: () => void;
}

const Location = (props: TypeProps) => {
  return (
    <div className="location-background">
      <div className="location-container">
        <div className="body">
          {props.messageID === 1 ? (
            <p>
              Location access is required to use table ordering.<br></br>Please allow location access and refresh the
              page
            </p>
          ) : props.messageID === 2 ? (
            <p>
              Out of range!<br></br>
              You are {props.distance > 1000 ? Number(props.distance / 1000).toFixed(2) : props.distance}{' '}
              {props.distance > 1000 ? 'kilometers' : 'meters'} away from the store.
            </p>
          ) : null}
        </div>
        <div className="footer">
          {/* <button
            onClick={() => {
              props.requestGeolocation();
            }}
          >
            OK
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Location;
