import { POST_KESS } from 'generics/base-service';
import { GetRatesParam, GetRatesResponse } from 'interfaces/models/getRates';
import { PostShipParam, PostShipResponse } from 'interfaces/models/postShip';

export function getRatesService(params: GetRatesParam): Promise<any> {
  const url = 'https://devdelivery.kesspay.io/api/v1/merchant/shipment';
  return POST_KESS<GetRatesResponse>(url, params);
}

export function postShippingService(params: PostShipParam): Promise<any> {
  const url = 'https://devdelivery.kesspay.io/api/v1/merchant/shipment';
  return POST_KESS<PostShipResponse>(url, params);
}