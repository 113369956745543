import { createListAction } from 'generics/action-creator';
import { Action, Dispatch } from 'redux';
import { RootState } from 'store/configureStore';

import { CREATE_CALLING_WAITRESS_REQUEST, CREATE_OUTLET_FEED_BACK, GET_OUTLET_REQUEST } from '../../constants/outlets';
import {
  CallingWaiterBodyParam,
  CallingWaiterParam,
  CallingWaiterResponse,
  IOutlets,
  OutletFeedBackParam,
} from '../../interfaces/models/outlets';
import { createCallingWaiter, fetchOutletByOrganizationId, outletsFeedBack } from '../../services/outlets';

export const getOutlets =
  (orgId: string): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const options = {
      serviceMethod: fetchOutletByOrganizationId,
      dispatch,
      getState,
      requestName: GET_OUTLET_REQUEST,
    };

    return createListAction<IOutlets[], string>(options, orgId);
  };

export const callingWaiter =
  (spotId: string, outletId: string, bodyParam: CallingWaiterBodyParam): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    if (spotId && outletId) {
      const param = {
        spotId,
        outletId,
      };

      const options = {
        serviceMethod: createCallingWaiter,
        dispatch,
        getState,
        requestName: CREATE_CALLING_WAITRESS_REQUEST,
      };
      return createListAction<CallingWaiterResponse, CallingWaiterParam, CallingWaiterBodyParam>(
        options,
        param,
        bodyParam
      );
    }
  };

export const submitFeedBackForOutlet =
  (params: OutletFeedBackParam): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const options = {
      serviceMethod: outletsFeedBack,
      dispatch,
      getState,
      requestName: CREATE_OUTLET_FEED_BACK,
    };

    return createListAction<any, OutletFeedBackParam>(options, params);
  };
// export const getOutletsByOutletId =
// (outletId: string): any =>
// (dispatch: Dispatch<Action>, getState: () => RootState) => {
//   const options = {
//     serviceMethod: fetchOutletByOutletId,
//     dispatch,
//     getState,
//     requestName: GET_OUTLET_REQUEST,
//   };

//   return createListAction<IOutlets[], string>(options, outletId);
// };
