import { FILTER_CHANGE_ON_CATEGORY, FILTER_SEARCH_BY_NAME_CHANGE } from 'constants/Filter';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Navigation from '../../../components/partials/Navigation/index';
import { InputComponent } from '../../../cores/components';
import { MyImages } from '../../../cores/components/MyImage/index';
import { useAppSelector } from '../../../hooks/redux/index';
import { RootState } from '../../../store/configureStore';
import { getSpotId } from 'helper/path';
import ImageSlider from 'cores/components/ImageSlider';
import { useTranslation } from 'react-i18next';
import FilterCategory from './filter-category';
import { Icon } from '@iconify/react';
import { getSpotById } from 'helper/spot';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  SPOT_DELIVERY,
  SPOT_PICKUP_TYPE,
  SPOT_QRTABLE,
  SPOT_RESERVATION_PREORDER,
  SPOT_TABLE_RESERVATION,
} from 'constants/spot';
import ReactGA from 'react-ga4';
import NavigationButtons from './navigationButtons';
import CallForWaiter from 'components/partials/CallForWaiter';
import AskForBilledPayment from 'components/partials/AskForBillPayment';
import LanguageSetting from 'components/partials/LanguageSetting';
import { POST_TAB_REQUEST } from 'constants/tabs';

export interface IProps {
  isPreOrderInfoOpen?: boolean;
  changeSearchName?: (query: string) => void;
  clickSliderHappened?: () => void;
  setWifiDetailsModal?: () => void;
  setCategory?: (param: string) => void;
  setSelectedTab: (param: string) => void;
  categoryDisplayed: string;
  selectedTab: string;
}

const Header = (props: IProps) => {
  const dispatch = useDispatch();
  const { outletId } = useParams();
  const { detail } = useAppSelector((state) => state.organization);
  const { deliveryFee, deliveryDistance } = useAppSelector((state) => state.carts);
  const { list } = useAppSelector((state) => state.outlets);
  const { selected } = useAppSelector((state: RootState) => state.tabSelected);
  const [selectedTab, setSelectedTab] = useState('');
  useEffect(() => {
    console.log('🚀 ~ Header ~ selected:', selected, selectedTab);
    setSelectedTab(selected);
  }, [selected]);

  // const outlet = list.find((i) => i.id === outletId);
  const outlet = Array.isArray(list) ? list.find((i) => i.id === outletId) : null;
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';

  const { name } = useAppSelector((state: RootState) => state.filter);
  const [category, setCategoryFilter] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filterName, setFilterName] = useState(name || '');
  const [openNavigation, setOpenNavigation] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const [callForWaiter, setCallForWaiter] = useState(false);
  const [openBillPayment, setOpenBillPayment] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);

  const images = outlet?.headerImages || [];
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      checkScrolled();
    };
  }, [filterName, selected]);

  function checkScrolled() {
    const header = document.getElementById('sticky-header');
    if (header) {
      if (window.scrollY > header.offsetHeight + 80) {
        header.classList.add('fixed', 'fix-top');
        header.style.minHeight = '19vh';
        setScrolled(true);
      } else {
        header.classList.remove('fixed', 'fix-top');
        if (spot && spot.type == SPOT_QRTABLE) {
          if (selected === 'order') {
            header.style.minHeight = '24vh';
          } else {
            if (outlet?.wiFis && outlet.wiFis !== null && outlet?.wiFis.length > 0) {
              header.style.minHeight = '65vh';
            } else {
              header.style.minHeight = '50vh';
            }
          }
        } else {
          header.style.minHeight = '24vh';
        }

        setScrolled(false);
      }
    }
  }

  function outletDetails() {
    const { outletId } = useParams();

    let item1 = Array.isArray(list) ? list.find((i) => i.id === outletId) : null;
    return item1;
  }
  const searchMenuByNameChange = (query: string) => {
    if (query) {
      ReactGA.event({
        category: `Searched for ${query}`,
        action: `Searched for ${query}`,
        transport: 'xhr', // optional, beacon/xhr/image
      });
    }
    const { changeSearchName, setCategory } = props;
    setCategoryFilter('');
    setFilterName(query);
    if (changeSearchName) {
      changeSearchName(query);
    }
    dispatch({ type: FILTER_CHANGE_ON_CATEGORY, payload: [] });
    dispatch({ type: FILTER_SEARCH_BY_NAME_CHANGE, payload: query });
  };

  useEffect(() => {
    if (category) {
      const { setCategory } = props;
      if (setCategory) {
        setCategory(category);
        setSelectedCategory(category);
      }

      dispatch({ type: FILTER_CHANGE_ON_CATEGORY, payload: [category] });
    } else {
      const { setCategory } = props;
      if (setCategory) {
        setCategory('');
      }
      dispatch({ type: FILTER_CHANGE_ON_CATEGORY, payload: [] });
    }
  }, [category]);

  useEffect(() => {
    if (!showSearch) {
      searchMenuByNameChange('');
    }
  }, [showSearch]);

  useEffect(() => {
    setSelectedCategory(props.categoryDisplayed);
  }, [props.categoryDisplayed]);

  return (
    <>
      <div id="restaurant-profile-information" className="restaurant-profile-header-container">
        {callForWaiter && (
          <CallForWaiter onClosed={() => setCallForWaiter(false)} outletId={outletId ?? ''} spotId={spot.id} />
        )}
        {openBillPayment && <AskForBilledPayment onClose={() => setOpenBillPayment(false)} />}

        {images.length > 0 ? (
          <>
            <ImageSlider images={images} brandColor={brandColor} />
          </>
        ) : (
          <div className="restaurant-wallpaper">
            <MyImages className="wallpaper" src={detail?.bannerImage?.imageUrl} />
          </div>
        )}
        <div
          id="sticky-header"
          className="sticky-content restaurant-information padding "
          style={{
            minHeight:
              spot && spot.type == SPOT_QRTABLE && selectedTab === null
                ? outlet?.wiFis && outlet.wiFis !== null && outlet?.wiFis.length > 0
                  ? '65vh'
                  : // : '48vh'
                    '50vh'
                : '24vh',
          }}
        >
          {detail && (
            <div className="logo-wrapper">
              <MyImages
                className="profile-image-logo sticky-content fix-top-21"
                id="logo"
                loading="lazy"
                src={detail?.image?.imageUrl}
              />
            </div>
          )}
          <div className="restaurant-title title-sticky mt-4">
            <span className="sticky-content fix-top-4">{outletDetails()?.name}</span>
          </div>
          {spotId && spot && spot.type != SPOT_QRTABLE ? (
            <div id="filter-box" className="hamburger-wrapper">
              <img
                className="menu-filter-box sticky-content fix-top-21"
                src="/assets/icons/menu-hamburger.svg"
                alt=""
                onClick={() => setOpenNavigation(true)}
              />
            </div>
          ) : (
            <>
              {props.selectedTab === 'order' && (
                <div
                  id="filter-box"
                  className="hamburger-wrapper"
                  style={{
                    top: '-120%',
                  }}
                >
                  <div
                    className="menu-filter-box sticky-content fix-top-21"
                    style={{
                      padding: '8px 8px',
                    }}
                  >
                    <Icon
                      icon="icon-park-solid:back"
                      width={24}
                      onClick={() => {
                        dispatch({ type: POST_TAB_REQUEST, payload: null });
                        // window.location.reload();
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {!scrolled && (
            <div className="menu-filter-wrapper search absolute-bottom">
              {(spot && spot.type == SPOT_QRTABLE) || showSearch ? (
                <>
                  {spot && spot.type == SPOT_QRTABLE && !props.selectedTab ? (
                    <>
                      <NavigationButtons
                        setSelectedTab={(value: string) => props.setSelectedTab(value)}
                        brandColor={brandColor}
                        outlet={outlet}
                        spot={spot}
                        setWifiDetailsModal={props.setWifiDetailsModal}
                        setCallForWaiter={() => setCallForWaiter(true)}
                        setOpenBillPayment={() => setOpenBillPayment(true)}
                        setOpenLanguage={() => setOpenLanguage(true)}
                        selectedTab={selectedTab}
                      />
                    </>
                  ) : (
                    <>
                      {' '}
                      <InputComponent
                        value={filterName}
                        placeholder={t('search_name_category')}
                        onChange={(e) => searchMenuByNameChange(e.target.value)}
                      />{' '}
                    </>
                  )}
                </>
              ) : (
                <div className="slide-in-right outlet-details-header ">
                  {spot && (spot.type == SPOT_TABLE_RESERVATION || spot.type == SPOT_RESERVATION_PREORDER) && (
                    <>
                      <div className="outlet-details-list">
                        <Icon icon="material-symbols:call" width={18} color={brandColor} />
                        <p className="details-text">
                          +855 {outlet && outlet.contact.phoneNumber} | {outlet && outlet.contact.email}
                        </p>
                      </div>
                      <div className="outlet-details-list">
                        <Icon icon="uiw:map" width={18} color={brandColor} />
                        <p className="details-text">
                          {outlet && outlet.location.address}, {outlet && outlet.location.city}
                        </p>
                      </div>
                    </>
                  )}
                  {spot && spot.type == SPOT_PICKUP_TYPE && (
                    <>
                      <div className="outlet-details-list">
                        <Icon icon="material-symbols:call" width={18} color={brandColor} />
                        <p className="details-text">
                          +855 {outlet && outlet.contact.phoneNumber} | {outlet && outlet.contact.email}
                        </p>
                      </div>
                      <div className="outlet-details-list">
                        <Icon icon="uiw:map" width={18} color={brandColor} />
                        <p className="details-text">
                          {outlet && outlet.location.address}, {outlet && outlet.location.city}
                        </p>
                      </div>
                    </>
                  )}
                  {spot && spot.type == SPOT_DELIVERY && (
                    <>
                      <div className="outlet-details-list">
                        <Icon icon="uiw:map" width={18} color={brandColor} />
                        <p className="details-text">
                          {outlet && outlet.location.address}, {outlet && outlet.location.city}
                        </p>
                      </div>
                      {deliveryFee !== 0 && (
                        <div className="outlet-details-list">
                          <Icon icon="ic:round-delivery-dining" width={18} color={brandColor} />
                          <p className="details-text">
                            ${deliveryFee} Delivery Fee | {Number(deliveryDistance).toFixed(2)} km away
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {/* <img className="menu-filter-box" onClick={clickSliderHappened} src="/assets/icons/sliders.svg" alt="" /> */}
              {spot &&
                spot.type != SPOT_QRTABLE &&
                spot.type != SPOT_TABLE_RESERVATION &&
                spot.type != SPOT_RESERVATION_PREORDER && (
                  <div className="menu-filter-box" onClick={() => setShowSearch(!showSearch)}>
                    {showSearch ? <Icon icon="maki:cross" width={18} /> : <Icon icon="octicon:search-16" width={18} />}
                  </div>
                )}
            </div>
          )}
          {spot && spot.type != SPOT_TABLE_RESERVATION && (
            <>
              {spot && spot.type == SPOT_RESERVATION_PREORDER && props.isPreOrderInfoOpen && (
                <div className="filter-container">
                  <FilterCategory
                    setCategory={setCategoryFilter}
                    selectedCategory={selectedCategory}
                    selectedTab={props.selectedTab}
                    spot={spot}
                  />
                </div>
              )}
              {spot && spot.type != SPOT_RESERVATION_PREORDER && (
                <div className="filter-container">
                  <FilterCategory
                    setCategory={setCategoryFilter}
                    selectedCategory={selectedCategory}
                    selectedTab={props.selectedTab}
                    spot={spot}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {openNavigation && <Navigation onClosed={() => setOpenNavigation(false)} />}
        {openLanguage && <LanguageSetting onClose={() => setOpenLanguage(false)} />}
      </div>
    </>
  );
};

export default Header;
