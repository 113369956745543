import { createListAction } from 'generics/action-creator';
import { Action, Dispatch } from 'redux';
import { RootState } from '../../store/configureStore';
import { GET_PROMOTION_REQUEST } from 'constants/promotion';
import { fetchingAllPromotionByOutletId } from 'services/promotion';
import { IPromotion } from 'interfaces/models/promotion';

export const getAllPromotion =
  (outletId: string): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const options = {
      serviceMethod: fetchingAllPromotionByOutletId,
      dispatch,
      getState,
      requestName: GET_PROMOTION_REQUEST,
    };

    return createListAction<IPromotion>(options, outletId);
  };
