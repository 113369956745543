import queryString from 'query-string';
import { generatePath, useLocation } from 'react-router-dom';

type PathType = {
  orgId: string;
  outletId: string;
};

export function generatePathHelpForHomePage(nextPath: string, path: PathType, search: string) {
  const pathname = generatePath('/org/:orgId/outlet/:outletId' + nextPath, path);
  return { pathname, search };
}

export function getSpotId(): string {
  const search = useLocation().search;
  const stringified = queryString.parse(search);
  const spotId = stringified.spotId as string;
  return spotId ?? '';
}
