import { BottomSheet } from 'components/partials/BottomSheet';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../../hooks/redux';
import { RootState } from '../../../store/configureStore';
import { useParams } from 'react-router-dom';
import { IOutlets } from 'interfaces/models/outlets';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import DeliveryInformation from 'components/partials/DeliveryInformation';
import { IDeliveryInfo } from 'interfaces/models/deliveryInfo';
import { getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import ReactGA from 'react-ga4';

const CartComponent = () => {
  const [openBottomSheet, setOpenBottomSheet] = useState<boolean>(false);
  const { items, price } = useAppSelector((state: RootState) => state.carts);
  const { detail } = useAppSelector((state) => state.organization);
  const { t } = useTranslation();
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';

  const { list } = useAppSelector((state) => state.outlets);
  const { outletId } = useParams();
  let item1 = Array.isArray(list) ? list.find((i) => i.id === outletId) : null;
  const [currentOutlet, setCurrentOutlet] = useState<IOutlets>();

  useEffect(() => {
    if (item1) setCurrentOutlet(item1);
  }, [item1]);

  // const { items, price } = useAppSelector((state: RootState) => state.carts);
  // const { list } = getSelectorByKey('outlets');
  const spotIdd = getSpotId();
  const spot = getSpotById(spotIdd);
  const [openDeliveryInformation, setOpenDeliveryInformation] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState<IDeliveryInfo>({
    address: '',
    country: '',
    city: '',
    longitude: 0,
    latititude: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    countryCode: '',
    email: '',
    notes: '',
    expectedDeliveryTime: '',
    deliveryCharge: 0,
  });
  const onSubmitDelivery = () => {
    setOpenDeliveryInformation(false);
    setOpenBottomSheet(true);
  };
  useEffect(() => {
    const localInfoDelivery = localStorage.getItem('deliveryInfo');
    const deliveryCharge = sessionStorage.getItem('deliveryCharges');
    if (localInfoDelivery && JSON.parse(localInfoDelivery).deliveryCharge !== 0) {
      setDeliveryInfo({
        ...deliveryInfo,
        firstName: JSON.parse(localInfoDelivery).firstName,
        email: JSON.parse(localInfoDelivery).email,
        phoneNumber: JSON.parse(localInfoDelivery).phoneNumber,
        address: '',
      });
      setOpenDeliveryInformation(false);
    }
  }, []);

  const handleDeliveryInformation = () => {
    ReactGA.event({
      category: `Proceed to Review Order`,
      action: `Proceed to Review Order`,
      transport: 'xhr', // optional, beacon/xhr/image
    });
    if (spotIdd && spot && spot.type === 3) {
      setOpenDeliveryInformation(true);
    } else {
      setOpenBottomSheet(true);
    }
  };

  return (
    <>
      {spotIdd && spot && spot.type === 3 && openDeliveryInformation && (
        <DeliveryInformation
          isSubmit={false}
          onSubmitDelivery={onSubmitDelivery}
          onClosed={() => setOpenDeliveryInformation(false)}
          deliveryInfo={deliveryInfo}
          setDeliveryInfo={setDeliveryInfo}
          price={price}
          list={list}
        />
      )}
      <BottomSheet isOpen={openBottomSheet} onClose={() => setOpenBottomSheet(false)} />
      {items.length > 0 && !openBottomSheet && (
        <div className="footer-wrapper" onClick={() => handleDeliveryInformation()}>
          <div>
            <div className="footer-price">
              $ {price.toFixed(2)}
              {!currentOutlet?.areChargesIncluded &&
                (currentOutlet?.vat !== 0 || currentOutlet?.serviceCharge !== 0) &&
                '++'}
            </div>
            <div className="footer-description">
              {currentOutlet?.areChargesIncluded || (currentOutlet?.vat === 0 && currentOutlet?.serviceCharge === 0)
                ? t('no_additional_charges')
                : currentOutlet?.vat !== undefined || currentOutlet?.serviceCharge !== undefined
                ? `Subject to ${currentOutlet?.vat && currentOutlet?.vat > 0 && `${currentOutlet?.vat}% VAT`} & ${
                    currentOutlet?.serviceCharge &&
                    currentOutlet?.serviceCharge > 0 &&
                    `${currentOutlet?.serviceCharge}% Service Charge`
                  } `
                : ''}
            </div>
          </div>
          <div
            className="basket-wrapper"
            style={{ backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40` }}
          >
            <div>{t('order_now')}</div>
            {/*             
            <div className="basket-box">
              <img src="/assets/icons/shopping-basket.svg" alt="" style={{ color: 'black' }} /> 
              <Icon icon="ph:basket-bold" color={brandColor} width={25} />
            </div> 
            */}
          </div>
        </div>
      )}
    </>
  );
};

export const Cart = CartComponent;
