import { PayloadAction } from '@reduxjs/toolkit';

import {
    FAVORITES_MENU_REQUEST_ADD, FAVORITES_MENU_REQUEST_REMOVE
} from '../../constants/favorites';

type StateType = {
  menu: string[];
};

const initialState = {
  menu: [],
};

export default function favorites(state: StateType = initialState, action: PayloadAction<string>): StateType {
  switch (action.type) {
    case FAVORITES_MENU_REQUEST_ADD:
      state.menu = state.menu.concat(action.payload);
      return {
        ...state,
      };

    case FAVORITES_MENU_REQUEST_REMOVE:
      state.menu = state.menu.filter((id: string) => id !== action.payload);
      return state;

    default:
      return state;
  }
}
