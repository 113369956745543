import { POST } from "generics/base-service";
import { CreateOrderResponse, OrderItem } from "interfaces/models/order";

type CreateParam = {
  spotId: string;
  outletId: string;
  body: OrderItem;
};

export function createOrderByMenuBySpotId(params: CreateParam): Promise<CreateOrderResponse> {
  const { spotId, outletId, body } = params;
  const url = `/api/v1/Order/${outletId}/${spotId}`;

  return POST<CreateOrderResponse>(url, body);
}
