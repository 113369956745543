import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import ChargesSection from '../ChargesSection';
import { AddOn } from 'interfaces/models/menu';
import { CART_REQUEST_ADDED, CART_REQUEST_EMPTY } from 'constants/carts';
import { Carts, Item } from 'interfaces/models/carts';
import { OrderStatus } from 'enums/status';
import { calculateDiscountedPrice } from 'cores/components';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { generatePathHelpForHomePage, getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import { RootState } from 'store/configureStore';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { storePreviousOrder } from 'helper/order-summary';
import { createOrder } from 'actions/order';
import { useCalculateCharge } from 'hooks/useCalculateCharges';
import { LoadingSpinner } from '../LoadingSpinner';
import { Oval } from 'react-loader-spinner';
import { t } from 'i18next';

type QuickOrderProps = {
  brandColor: string;
  outlet: any;
  onClose: () => void;
  orderItems: any;
  orderAgainItemImage: string;
  orderAgainItemPromo: any;
  totalPrice: number;
};
export const OrderAgainConfirmation = ({
  brandColor,
  onClose,
  orderItems,
  outlet,
  orderAgainItemImage,
  orderAgainItemPromo,
  totalPrice,
}: QuickOrderProps) => {
  const refBottomSheet = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { outletId } = useParams();

  const spotId = getSpotId();

  const { subTotal, totalSum } = useCalculateCharge(totalPrice);

  const [loading, setLoading] = useState(false);
  const { items } = useAppSelector((state: RootState) => state.carts);
  const [quickOrderItems, setQuickOrderItem] = useState<Item[]>([]);

  const OrderAgainSubmit = () => {
    let type = CART_REQUEST_ADDED;
    const payload: Carts = {
      price: orderItems.price,
      status: OrderStatus.Open,
      items: {
        name: orderItems.name,
        menuId: orderItems.menuId,
        description: orderItems.description,
        specialRequest: orderItems.specialRequest,
        quantity: orderItems.quantity,
        addOns: orderItems.addOns,
        imageUrl: orderAgainItemImage,
        price: orderItems.price,
        promotion: orderAgainItemPromo,
        discountedPrice: orderAgainItemPromo
          ? calculateDiscountedPrice(orderAgainItemPromo.value, orderAgainItemPromo.discountType, orderItems.price)
          : 0,
      },
    };
    if (orderItems.quantity > 0) {
      dispatch({ type, payload });
      setLoading(true);
    }
  };
  useEffect(() => {
    if (items.length > 0) {
      setQuickOrderItem(items);
    }
  }, [items]);

  useEffect(() => {
    if (orderAgainItemImage.length > 0) {
      onClickSubmitOrder();
    }
  }, [quickOrderItems]);

  const onClickSubmitOrder = () => {
    localStorage.setItem('menuListingScrollPosition', '0');
    if (spotId && outlet && outletId) {
      if (quickOrderItems && quickOrderItems.length) {
        storePreviousOrder(items);
        const param = {
          spotId: spotId,
          outletId,
          body: {
            items: quickOrderItems,
            status: 0,
            price: outlet?.areChargesIncluded ? totalSum : subTotal,
            discountedprice: orderAgainItemPromo
              ? calculateDiscountedPrice(orderAgainItemPromo.value, orderAgainItemPromo.discountType, totalPrice)
              : 0,
          },
        };
        Promise.resolve(dispatch(createOrder(param)))
          .then(() => {
            dispatch({ type: CART_REQUEST_EMPTY });

            window.location.reload();
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const successAction = () => {
    // window.location.reload();
    // if (outletId && orgId) {
    //   const path = generatePathHelpForHomePage('/order-summary', { outletId, orgId }, search);
    //   navigate(path.pathname + path.search);
    // }
    setLoading(false);
  };
  return (
    <div className="bottom-sheet-wrapper" ref={refBottomSheet}>
      <div className="bottom-sheet-content padding">
        <div className="navigation-wrapper pickup-information">
          <div
            className={`title quick-order-header-title`}
            style={{
              color: brandColor,
            }}
          >
            <p>{t('please_confirm_your_order')}</p>
          </div>
          <div className="quick-order-header-confirmation">
            {t('are_you_sure_order_again')}{' '}
            <span
              className="menu-name"
              style={{
                color: brandColor,
              }}
            >
              {orderItems.name}.
            </span>
          </div>
          <div className="quick-order-divider"></div>
          <div className="flex-row space-between total-amount-text">
            <div>{t('order_details')}</div>
          </div>
          <div className="flex-col quick-order-pt-15">
            <div className="flex-row space-between quick-order-addons" style={{ gap: '2px' }}>
              <div className="col" style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '2px',
                  }}
                >
                  <span>
                    {' '}
                    <span className="quick-order-quantity">{orderItems.quantity}X</span> {orderItems.name}
                  </span>
                  <div
                    className="quick-order-price"
                    style={{
                      width: '50px',
                      textAlign: 'right',
                    }}
                  >
                    $
                    {orderItems.discountedPrice && orderItems.discountedPrice < orderItems.price
                      ? orderItems.discountedPrice.toFixed(2)
                      : orderItems.price.toFixed(2)}
                  </div>
                </div>
                {orderItems &&
                  orderItems.addOns?.map((addon: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '2px',
                      }}
                    >
                      <span>- {addon.name}</span>
                      <span
                        style={{
                          width: '50px',
                          textAlign: 'right',
                        }}
                      >
                        ${addon.price.toFixed(2)}
                      </span>
                    </div>
                  ))}
                <div
                  style={{
                    fontSize: '13px',
                    color: '#7b7b7b',
                    fontStyle: 'italic',
                    marginTop: '5px',
                  }}
                >
                  {orderItems.specialRequest}
                </div>
              </div>
            </div>
          </div>
          <ChargesSection totalPrice={totalPrice} />
          <div className="quick-order-button-container">
            <div
              className="btn primary pd-12 flex-row space-between submit-order-pickup quick-order-button-close"
              onClick={() => onClose()}
            >
              <div className="flex-row center column-gap-10">
                <span>{t('cancel')}</span>
              </div>
              <div className="flex-row column-gap-16">
                <Icon icon="maki:cross" />
              </div>
            </div>
            <div
              className="btn primary pd-12 flex-row space-between submit-order-pickup"
              onClick={() => OrderAgainSubmit()}
              style={{
                backgroundColor: brandColor,
                boxShadow: `0px 4px 4px ${brandColor}40`,
                borderColor: brandColor,
              }}
            >
              <div className="flex-row center ">
                <span>{t('order_now')}</span>
              </div>
              {!loading ? (
                <div className="flex-row column-gap-16">
                  <img src="/assets/icons/next-white.svg" alt="" />
                </div>
              ) : (
                <Oval
                  height={15}
                  width={15}
                  color="#FFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor={brandColor ? brandColor : '#FFB26B'}
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
