import { callingWaiter } from 'actions/outlets';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TypeProps {
  onClosed: () => void;
  outletId: string;
  spotId: string;
}

const CallForWaiter = (props: TypeProps) => {
  const dispatch = useAppDispatch();
  const [countdown, setCountdown] = useState(999999);
  const [inputValue, setInputValue] = useState('');
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { t } = useTranslation();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000); // decrease the value of countdown by 1 every second

    if (countdown === 0) {
      clearInterval(intervalId); // stop the interval when countdown reaches 0
      props.onClosed();
    }

    return () => {
      document.body.style.overflow = 'unset';
      clearInterval(intervalId);
    }; // cleanup function to clear the interval when component unmounts
  }, [countdown]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }; // get the input from the input feild

  const handleCallButtonClicked = () => {
    dispatch(
      callingWaiter(props.spotId, props.outletId, {
        type: 'Question',
        message: `${inputValue === '' ? 'Assistance Required.' : inputValue} `,
        paymentType: '',
      })
    );
    props.onClosed();
  };

  return (
    <div className="bottom-sheet-wrapper">
      <div className="bottom-sheet-content content" style={{ overflow: 'unset' }}>
        <p className="confirmation-paragraph mt-28">{t('how_can_we_help_you')}</p>
        <input className="call-for-waiter-input" placeholder={t('a_note_to_staff')} onChange={handleInputChange} />
        <div className="menu-filter-wrapper">
          <button
            className="btn primary"
            onClick={props.onClosed}
            style={{ backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40`, borderColor: brandColor }}
          >
            {t('cancel')}
          </button>
          <button
            className="btn primary"
            onClick={handleCallButtonClicked}
            style={{ backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40`, borderColor: brandColor }}
          >
            {t('call')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CallForWaiter;
