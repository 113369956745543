import { PayloadAction } from '@reduxjs/toolkit';
import { POST_TAB_REQUEST } from 'constants/tabs';

type StateType = {
  selected?: any | null;
};

const initialState = {
  selected: null,
};
type PayloadType = string | null;

export default function tabSelected(state: StateType = initialState, action: PayloadAction<PayloadType>): StateType {
  switch (action.type) {
    case POST_TAB_REQUEST:
      return {
        ...state,
        selected: action.payload as string,
      };
    default:
      return state;
  }
}
