import { HTMLInputTypeAttribute, useEffect, useState } from 'react';

import { AddOn, MenuOptionItem } from '../../../interfaces/models/menu';
import { useAppSelector } from 'hooks/redux';
import { Icon } from '@iconify/react';

type OptionAttributesProps = {
  name: string;
  defaultChecked?: boolean;
  item: MenuOptionItem;
  type: HTMLInputTypeAttribute;
  hasSoldOut?: boolean;
  _id: string;
  groupId: number;
  selectedValue?: string;
  //
  onClicked?: (param: MenuOptionItem) => void;
  onRemoved?: (param: MenuOptionItem) => void;
  setSelectedValue: (value: string) => void;
  maximum?: string;
  isMaxSelection?: boolean;
  disabledOptions?: any[];
  selectedOptions: any[];
  sequence: number;
};

const OptionAttributes = (props: OptionAttributesProps) => {
  const {
    defaultChecked = false,
    type = 'CHECKBOX',
    _id,
    onClicked,
    item,
    setSelectedValue,
    selectedValue,
    isMaxSelection,
    disabledOptions,
    groupId,
    selectedOptions,
    sequence,
  } = props;

  const { option, price, category } = item;
  const [checked, setChecked] = useState(defaultChecked);
  useEffect(() => {
    if (defaultChecked && onClicked) {
      onClicked(item);
    }
  }, [defaultChecked]);
  useEffect(() => {
    if (selectedOptions[groupId] && type === 'BUBBLE') {
      setChecked(
        selectedOptions[groupId].some(
          (selectedOption: any) => selectedOption.option === option && selectedOption.sequence === sequence
        )
      );
    }
  }, [selectedOptions]);

  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#dd5f36';
  const clickedHappened = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (type === 'BUBBLE') {
      BubbleSelection();
    } else {
      if (type === 'RADIO') {
        setSelectedValue(nameInput + _id);
      }

      const inputChecked = !checked;
      const value: MenuOptionItem = { index: _id, ...item };

      if (onClicked && inputChecked) {
        onClicked(value);
      }

      if (!inputChecked && props.onRemoved) {
        props.onRemoved(value);
      }

      setChecked(inputChecked);
    }
  };
  const BubbleSelection = () => {
    const inputChecked = !checked;

    const value: MenuOptionItem = { index: _id, ...item };
    if (!inputChecked && props.onRemoved) {
      props.onRemoved(value);
      if (onClicked && inputChecked) {
        onClicked(value);
      }
      setChecked(inputChecked);
    }
    const checkDisabledOption = disabledOptions?.includes(value.category);
    if (!checkDisabledOption) {
      if (onClicked && inputChecked) {
        onClicked(value);
      }
      setChecked(inputChecked);
    }
  };
  const nameInput =
    type === 'RADIO' ? `RADIO-${category}` : type === 'BUBBLE' ? `RADIO-${category}-${_id}` : `${name}+-${_id}`;
  // console.log(
  //   '🚀 ~ file: option-attributes.tsx:107 ~ OptionAttributes ~ nameInput:',
  //   type === 'BUBBLE' ? nameInput + _id : ''
  // );

  return (
    <>
      {type === 'BUBBLE' && (
        <>
          {props.hasSoldOut ? (
            <>
              <div
                id={nameInput + _id}
                className={`flex-row radioItem`}
                style={{
                  padding: '10px',
                  width: 'fit-content',
                  height: '40px',
                  background: `none`,
                  border: `2px solid`,
                  borderColor: `#cccccc`,
                  transition: 'all 0.3s ease',
                }}
              >
                <Icon icon="line-md:confirm-circle-to-circle-transition" color={'#cccccc'} width={20} />

                <p
                  style={{
                    marginLeft: '5px',
                    color: `#cccccc`,
                    whiteSpace: 'nowrap',
                    textDecoration: 'line-through',
                  }}
                  className="labels"
                >
                  <span style={{ whiteSpace: 'nowrap' }}>{option}</span> {price > 0 && `+$${price.toFixed(2)}`}
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                id={nameInput + _id}
                className={`flex-row radioItem`}
                style={{
                  padding: '10px',
                  width: 'fit-content',
                  height: '40px',
                  background: `${checked === true ? brandColor : 'none'}`,
                  border: `2px solid`,
                  borderColor: `${brandColor}`,
                  transition: 'all 0.3s ease',
                }}
                onClick={clickedHappened}
              >
                {checked ? (
                  <Icon icon="line-md:circle-to-confirm-circle-transition" color={'#fff'} width={20} />
                ) : (
                  <Icon icon="line-md:confirm-circle-to-circle-transition" color={brandColor} width={20} />
                )}
                <p
                  style={{
                    marginLeft: '5px',
                    color: `${checked === true ? '#fff' : brandColor}`,
                    whiteSpace: 'nowrap',
                  }}
                  className="labels"
                >
                  <span style={{ whiteSpace: 'nowrap' }}>{option}</span> {price > 0 && `+$${price.toFixed(2)}`}
                </p>
              </div>{' '}
            </>
          )}
        </>
      )}
      {type === 'CHECKBOX' && (
        <div className="flex-row space-between mt-15">
          <div className="flex-row column-gap-10 ">
            <input
              id={nameInput + _id}
              className="check-box"
              name={nameInput}
              type={type}
              defaultChecked={defaultChecked}
              value={`${checked}`}
              disabled={props.hasSoldOut ?? false}
              onClick={clickedHappened}
              style={{ accentColor: brandColor }}
            />
            <label
              htmlFor={nameInput + _id}
              className="labels"
              style={{
                textDecoration: `${props.hasSoldOut ? 'line-through' : 'none'}`,
              }}
            >
              {option}
            </label>
          </div>
          {price > 0 && <div>+${price ? price.toFixed(2) : 0}</div>}
        </div>
      )}
      {type === 'RADIO' && (
        <div className="flex-row space-between mt-15">
          <div className="flex-row column-gap-10 ">
            <input
              id={nameInput + _id}
              className="check-box"
              name={nameInput}
              type="radio"
              defaultChecked={defaultChecked}
              value={`${checked}`}
              disabled={props.hasSoldOut ?? false}
              onClick={clickedHappened}
              style={{ accentColor: brandColor }}
            />
            <label
              htmlFor={nameInput + _id}
              className="labels"
              style={{
                textDecoration: `${props.hasSoldOut ? 'line-through' : 'none'}`,
              }}
            >
              {option}
            </label>
          </div>
          {price > 0 && <div>+${price ? price.toFixed(2) : 0}</div>}
        </div>
      )}
      {type === 'DROPDOWN' && (
        <option value={_id} disabled={props.hasSoldOut ?? false} selected={defaultChecked}>
          <span className="drop-down-text">{option}</span>
          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
          {price > 0 && <span> (+${price ? price.toFixed(2) : 0})</span>}
        </option>
      )}
    </>
  );
};

export default OptionAttributes;
