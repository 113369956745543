import { MyImages } from 'cores/components';
import DiscountPrice from 'cores/components/DiscountPrice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { find, get, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { OutletMenuItem } from '../../../interfaces/models/menu';
import { RootState } from '../../../store/configureStore';
import { QuantitySmall } from 'cores/components/Quantity/indexSmall';
import { getEditItemsFromCart } from 'helper/cart';
import { CART_UPDATE_QUANTITY } from 'constants/carts';
import { t } from 'i18next';

type TypeProps = {
  item: OutletMenuItem;
  index?: number;
};

const ThumbnailLandscape = (props: TypeProps) => {
  const { index } = props;
  const { items, price } = useAppSelector((state: RootState) => state.carts);
  const { detail } = useAppSelector((state) => state.organization);
  const [sQuantity, setSQuantity] = useState(0);
  const { search } = useLocation();
  const { id, image, rating, name, description, isOutOfStock, isAvailable } = props.item;
  const dispatch = useAppDispatch();
  const profileUrl = get(detail, ['image', 'imageUrl'], '');
  const imageUrl = get(image, ['imageUrl'], '');
  let targetIndex = items.findIndex((item) => item.menuId === id);
  useEffect(() => {
    findIfMenuIsCart();
  }, [sQuantity, price, items]);
  useEffect(() => {}, [sQuantity]);

  function findIfMenuIsCart(): void {
    const item = find(items, { menuId: id });
    if (item && !isEmpty(item)) {
      setSQuantity(item.quantity);
    } else {
      setSQuantity(0);
    }
  }
  function quantityChanged(quantity: number) {
    const payload = { menuId: id, quantity, index: targetIndex };
    setSQuantity(quantity);
    dispatch({ type: CART_UPDATE_QUANTITY, payload });
  }
  return (
    <Link to={`menu/${id}${search}`} className="text-link" style={{ padding: '0px 5px' }}>
      <div className="thumbnail-landscape-wrapper " style={{ position: 'relative' }}>
        {(isOutOfStock || !isAvailable) && <div className={'not-available-overlay'}></div>}
        <div className="image-container">
          {rating.value >= 5 && <div className="overlay-image"></div>}
          <MyImages className="thumbnail-image" src={imageUrl || profileUrl} />
          {rating.value >= 5 && (
            <div className="thumbnail-rating">
              <span>{rating.value.toFixed(1)}</span>
              <img src="/assets/icons/star.svg" alt="" />
            </div>
          )}
        </div>
        <div className="thumbnail-right">
          <div>
            <p className="title paragraph-truncate">{name}</p>
            <p className="description paragraph-truncate">{description}</p>
          </div>
          {/* <div className="flex-row flex-start thumbnail-pricing">
            <DiscountPrice item={props.item} />
          </div> */}
          <div className="flex-row flex-start thumbnail-pricing" style={{ justifyContent: 'space-between' }}>
            {props.item.basicPrice !== 0.0 && (
              <DiscountPrice item={props.item} isOutofStock={isOutOfStock || !isAvailable} quantity={sQuantity} />
            )}
            {/* FOR UPDATING THE CART VIA MENU LIST */}
            {sQuantity > 0 && <QuantitySmall startedWith={sQuantity} size="small" onChange={quantityChanged} />}
            {(isOutOfStock || !isAvailable) && <p className="not-available">{t('not_available')}</p>}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ThumbnailLandscape;
