import React, { useEffect, useState } from 'react';

interface TypeProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  value?: any;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLImageElement>) => void;
}

export const MyImages: React.FC<TypeProps> = ({ src = '', ...props }: TypeProps) => {
  const defaultImage = '/assets/icons/no-images.svg';
  // const defaultImage = detail?.image.imageUrl;

  const [srcImage, setSrcImages] = useState<string>(src ?? defaultImage);
  useEffect(() => {
    setSrcImages(src ?? defaultImage);
  }, [src]);
  const onErrorLoadingImages = (e: any) => {
    setSrcImages('/assets/icons/no-images.svg');
    // setSrcImages(detail?.image.imageUrl);
  };

  return (
    <>
      <img loading="lazy" src={srcImage} alt={srcImage} {...props} onError={(e) => onErrorLoadingImages(e)} />
    </>
  );
};
