import { useAppSelector } from 'hooks/redux';
import { OutletMenuItem } from 'interfaces/models/menu';
import { useEffect, useState } from 'react';

import { RootState } from '../../../store/configureStore';
import ThumbnailLandscape from './thumbnail-landscape';
import { useTranslation } from 'react-i18next';
import { getSpotId } from 'helper/path';

const FilterListing = () => {
  const { name, category } = useAppSelector((state: RootState) => state.filter);
  const { list } = useAppSelector((state: RootState) => state.menu);
  const [menu, setMenu] = useState<OutletMenuItem[]>([]);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { t } = useTranslation();
  const spotId = getSpotId();
  useEffect(() => {
    filterMenu();
  }, [name?.length, category.length, menu.length]);

  const filterMenu = () => {
    let menu = list;

    if (category.length) {
      menu = list.filter((item: OutletMenuItem) => {
        if (category.includes('Others')) {
          return !item.itemCategory || !item.itemCategory.name || category.includes(item.itemCategory.name);
        }

        return item.itemCategory && item.itemCategory.name && category.includes(item.itemCategory.name);
      });
    }
    if (name) {
      menu = list.filter((item: OutletMenuItem) => {
        const includedName = item.name.toLowerCase().includes(name.toLocaleLowerCase());
        const includedCategory =
          item.itemCategory && item.itemCategory.name.toLowerCase().includes(name.toLocaleLowerCase());
        const includedDescription = item.description.toLowerCase().includes(name.toLocaleLowerCase());
        return includedName || includedCategory || includedDescription;
      });
    }

    setMenu(menu);
  };
  return (
    <div className="filter-title mt-48 text-ellipsis">
      {/* {category?.length > 0 && (
        <>
          Filtered by “
          <span className="keyword" style={{ color: brandColor }}>
            {category.map((item, index) => (
              <>
                {category.length > 1 && index === category.length - 1 && <> and </>}
                {item}
                {category.length > 2 && index !== category.length - 1 && <>, </>}
              </>
            ))}
          </span>
          ” <br />
        </>
      )} */}
      {name && (
        <>
          {t('search_results_for')} “<span className="keyword">{name}</span>”{' '}
        </>
      )}
      <div className="filter-listing mt-14 ">
        {menu.length > 0 &&
          menu.map((item: OutletMenuItem) => {
            const checkSpot = item.spots && item.spots.find((spot) => spot.id === spotId);
            if (checkSpot)
              return (
                <div key={item.id}>
                  {/* {Math.max(menu?.IrRating?.total)} */}
                  <ThumbnailLandscape item={item} />
                </div>
              );
          })}
      </div>
    </div>
  );
};

export default FilterListing;
