import { useTranslation } from 'react-i18next';

export const FooterLogo = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        fontWeight: '200',
        color: '#64676C',
        alignItems: 'center',
      }}
    >
      {t('powered_by')}
      <img src="/assets/images/NtnLogo.png" alt="logo" style={{ width: '35px', marginLeft: '5px' }} />
    </div>
  );
};
