import { useAppSelector } from '../../../hooks/redux/index';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
interface TypeProps {
  onFillDeliveryInfo: () => void;
}
const DeliveryDetails = (props: TypeProps) => {
  const { onFillDeliveryInfo } = props;

  const { detail } = useAppSelector((state) => state.organization);
  const brandColor = detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const localStorageDeliveryInfo = localStorage.getItem('deliveryInfo');
  const { t } = useTranslation();

  return (
    <>
      {localStorageDeliveryInfo && (
        <div className="menu-wrapper">
          <div className="flex-row space-between column-gap-20">
            <div className="bottom-sheet-menu-title">Delivery Details</div>
          </div>
          <div
            className="attributes mt-11"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {JSON.parse(localStorageDeliveryInfo).address}
          </div>
          <div className="attributes mt-11">
            {JSON.parse(localStorageDeliveryInfo).firstName} +855{JSON.parse(localStorageDeliveryInfo).phoneNumber}
          </div>
          <div className="flex-row space-between mt-19 mb-19">
            <div onClick={onFillDeliveryInfo} className="flex-row column-gap-10">
              {/* <img src="/assets/icons/edit-pencil.svg" alt="" /> */}
              <Icon icon="charm:pencil" color={brandColor} width={20} />
              <div className="edit-text" style={{ color: brandColor }}>
                {t('edit')}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeliveryDetails;
