import { ISpot } from 'interfaces/models/spot';

import { PayloadAction } from '@reduxjs/toolkit';

import { GET_SPOT_REQUEST_SUCCESS } from '../../constants/spot';
import { GET_PROMOTION_REQUEST_SUCCESS } from 'constants/promotion';
import { IPromotion } from 'interfaces/models/promotion';

type StateType = {
  list: IPromotion;
};

const initialState = {
  list: {} as IPromotion,
};

export default function promotion(state: StateType = initialState, action: PayloadAction<IPromotion>): StateType {
  switch (action.type) {
    case GET_PROMOTION_REQUEST_SUCCESS:
      return {
        ...state,
        list: action.payload ?? {},
      };
    default:
      return state;
  }
}
