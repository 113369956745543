import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { InputComponent, Quantity } from 'cores/components';
import { IPickUpInfo } from 'interfaces/models/pickupInfo';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { getSelectorByKey } from 'helper/selector';
import { ORDER_TYPES } from 'constants/order';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { generatePathHelpForHomePage, getSpotId } from 'helper/path';
import { createOrder } from 'actions/order';
import _, { set } from 'lodash';
import { Icon } from '@iconify/react';
import { getConvertedDate, getCurrentDate, getMaxFutureDate } from 'helper/date';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { generateReservationId } from 'hooks/useRandomId';

interface TypeProps {
  onClosed: () => void;
  type?: string;
  index: number | null;
  isOnConfirmedPage: boolean;
}

const TableReservationInformation = (props: TypeProps) => {
  const { onClosed, type, index, isOnConfirmedPage } = props;
  const { list } = getSelectorByKey('outlets');
  const { outletId, orgId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const spotId = getSpotId();
  const reservationListString = localStorage.getItem('RESERVATION_INFO_LOCAL_GROUPED');
  const reservationList = reservationListString ? JSON.parse(reservationListString) : [];
  const [reservationInfo, setReservationInfo] = useState<IPickUpInfo>({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    notes: '',
    preffered_date: new Date(),
  });

  const [dateError, setDateError] = useState('');
  const [isDateError, setIsDateError] = useState(false);

  const [ReserveDate, setReserveDate] = useState(getCurrentDate());
  const [GuestNumber, SetGuestNumber] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const refBottomSheet = useRef<HTMLDivElement>(null);
  const hours = Array.from(Array(24).keys());
  const minutes = ['00', '15', '30', '45'];

  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  let dayOfWeek = new Date().getDay();
  if (dayOfWeek === 0) {
    dayOfWeek = 6;
  }
  const matchingOrderHours = list[0].orderHours.find((x: { dayOfWeek: number }) => x.dayOfWeek === dayOfWeek - 1);
  const options = hours.flatMap((h) => minutes.map((m) => `${String(h).padStart(2, '0')}:${m}`));
  const { orderStartTime, orderEndTime, breakStartTime, breakEndTime } = matchingOrderHours as {
    orderStartTime: { hours: number; minutes: number };
    orderEndTime: { hours: number; minutes: number };
    breakStartTime: { hours: number; minutes: number };
    breakEndTime: { hours: number; minutes: number };
  };

  const filteredOptions = options
    .filter((option) => {
      const [hours, minutes] = option.split(':');
      const optionTime = new Date();
      optionTime.setHours(Number(hours), Number(minutes), 0, 0);

      const currentTime = new Date();
      const next1Hour = new Date(currentTime.getTime() + 60 * 60000); // Add 60 minutes to current time
      const isDateToday = ReserveDate === moment(optionTime).format('YYYY-MM-DD');
      if (isDateToday) {
        return next1Hour <= optionTime;
      } else {
        return (
          optionTime >=
            new Date(
              optionTime.getFullYear(),
              optionTime.getMonth(),
              optionTime.getDate(),
              orderStartTime.hours,
              orderStartTime.minutes
            ) &&
          optionTime <=
            new Date(
              optionTime.getFullYear(),
              optionTime.getMonth(),
              optionTime.getDate(),
              orderEndTime.hours,
              orderEndTime.minutes
            ) &&
          (optionTime <
            new Date(
              optionTime.getFullYear(),
              optionTime.getMonth(),
              optionTime.getDate(),
              breakStartTime.hours,
              breakStartTime.minutes
            ) ||
            optionTime >=
              new Date(
                optionTime.getFullYear(),
                optionTime.getMonth(),
                optionTime.getDate(),
                breakEndTime.hours,
                breakEndTime.minutes
              ))
        );
      }
    })
    .map((option) => {
      const [hours, minutes] = option.split(':');
      let formattedHours = Number(hours);
      let ampm = 'AM';

      if (formattedHours === 0) {
        formattedHours = 12;
      } else if (formattedHours === 12) {
        ampm = 'PM';
      } else if (formattedHours > 12) {
        formattedHours -= 12;
        ampm = 'PM';
      }

      return { [option]: `${formattedHours}:${minutes} ${ampm}` };
    });

  const [ReserveTime, setReserveTime] = useState(filteredOptions.length > 0 && Object.keys(filteredOptions[0])[0]);

  const handleClickOutside = (e: any) => {
    if (refBottomSheet.current && !refBottomSheet.current.contains(e.target)) {
      localStorage.removeItem('isFromConfirmedPage');
      onClosed();
    }
  };

  function onSelectTime(e: any) {
    const date = new Date();
    if (e.target.value === 'ASAP') {
      const minute = date.getMinutes();
      const hour = date.getHours();
      date.setHours(hour, minute);
    } else {
      const [hour, minute] = e.target.value.split(':');
      date.setHours(hour, minute);
    }
    setReserveTime(e.target.value);
  }
  function onClickSubmitTableReservation() {
    validateEmail(reservationInfo.email) ? console.log('true:::') : console.log(':::false');
    localStorage.setItem('reservationInfo', JSON.stringify(reservationInfo));
    setLoading(true);
    if (reservationInfo.firstName && reservationInfo.mobileNumber.length >= 8 && !isDateError)
      return onSubmitTableReservation();
    else setLoading(false);
  }

  function validateEmail(email: string) {
    var re = /^[\w%\+\-]+(\.[\w%\+\-]+)*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!email) return true;
    else return re.test(email);
  }
  const onSubmitTableReservation = () => {
    if (!isLoading && spotId && outletId) {
      setLoading(true);
      let note = '';

      const newReservation = {
        CustomerName: reservationInfo.firstName,
        CustomerPhone: reservationInfo.mobileNumber,
        CustomerEmail: reservationInfo.email,
        GuestNumber: GuestNumber,
        ReserveDate: ReserveDate,
        ReserveTime: ReserveTime,
        id: generateReservationId(),
        outletId: outletId,
      };
      const reservationCurrentObject = JSON.stringify({
        CustomerName: reservationInfo.firstName,
        CustomerPhone: reservationInfo.mobileNumber,
        CustomerEmail: reservationInfo.email,
        GuestNumber: GuestNumber,
        ReserveDate: ReserveDate,
        ReserveTime: ReserveTime,
        type: type,
        id: newReservation.id,
        outletId: outletId,
      });
      localStorage.setItem('RESERVATION_INFO_LOCAL', reservationCurrentObject);

      const existingReservationsString = localStorage.getItem('RESERVATION_INFO_LOCAL_GROUPED');
      const existingReservations = existingReservationsString ? JSON.parse(existingReservationsString) : [];
      if (index && index !== 0) {
        let LocalIndex = index - 1;
        if (type && type === 'edit') {
          ReactGA.event({
            category: `Updated Reservation Details`,
            action: `Updated Reservation Details`,
            label: `Updated Reservation Details`,
            nonInteraction: true,
            transport: 'xhr',
          });
          existingReservations[LocalIndex] = {
            ...existingReservations[LocalIndex],
            newReservation,
          };
          note = `TABLE RESERVATION UPDATED:
           Table Dining Date: ${
             (moment(ReserveDate).format('LL'), moment(`${ReserveTime}`, 'HH:mm').format('h:mm A'))
           }  - Number of People: ${GuestNumber}`;
        } else if (type && type === 'cancel') {
          ReactGA.event({
            category: `Cancelled Reservation Details`,
            action: `Cancelled Reservation Details`,
            label: `Cancelled Reservation Details`,
            nonInteraction: true,
            transport: 'xhr',
          });
          existingReservations.splice(LocalIndex, 1);
          note = `TABLE RESERVATION CANCELED:
          Table Dining Date: ${
            (moment(ReserveDate).format('LL'), moment(`${ReserveTime}`, 'HH:mm').format('h:mm A'))
          }  - Number of People: ${GuestNumber}`;
        }
      } else {
        ReactGA.event({
          category: `Created Reservation Details`,
          action: `Created Reservation Details`,
          label: `Created Reservation Details`,
          nonInteraction: true,
          transport: 'xhr',
        });
        existingReservations.push(newReservation);
        note = `
        Table Dining Date: ${
          (moment(ReserveDate).format('LL'), moment(`${ReserveTime}`, 'HH:mm').format('h:mm A'))
        } - Number of People: ${GuestNumber}`;
      }
      localStorage.setItem('RESERVATION_INFO_LOCAL_GROUPED', JSON.stringify(existingReservations));
      const param = {
        spotId: spotId,
        outletId,
        body: {
          items: [],
          status: 0,
          customer: {
            firstname: reservationInfo.firstName,
            lastname: '',
            phoneNumber: reservationInfo.mobileNumber,
          },
          orderType: ORDER_TYPES.RESERVATION,
          preferedDeliveryDateTime: new Date(getConvertedDate(`${ReserveDate} ${ReserveTime}`)).toISOString(),
          notes: note,
        },
      };
      localStorage.removeItem('isFromConfirmedPage');

      Promise.resolve(dispatch(createOrder(param)))
        .then(() => {
          if (outletId && orgId) {
            const path = generatePathHelpForHomePage('/table-reservation-confirmation', { outletId, orgId }, search);
            navigate(path.pathname + path.search);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const getDateTime = (event: any) => {
    const selectedDate = new Date((event.target as HTMLInputElement).value);
    const currentDate = new Date(); // Current date
    const maxDate = new Date();
    maxDate.setDate(currentDate.getDate() + 30); // Maximum date is 30 days from today
    if (
      moment(selectedDate).format('YYYY-MM-DD') >= moment(currentDate).format('YYYY-MM-DD') &&
      moment(selectedDate).format('YYYY-MM-DD') <= moment(maxDate).format('YYYY-MM-DD')
    ) {
      setDateError('');
      setIsDateError(false);
      setReserveDate(moment(selectedDate).format('YYYY-MM-DD'));
    } else if (moment(selectedDate).format('YYYY-MM-DD') < moment(currentDate).format('YYYY-MM-DD')) {
      setDateError('Please select a date in the future.');
      setIsDateError(true);
    } else {
      setDateError('Max Reservation Date is 30 days from today');
      setIsDateError(true);
    }
  };

  function LoadingSpinner() {
    return (
      <Oval
        height={20}
        width={20}
        color="#FFFF"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#FFB26B"
        strokeWidth={5}
        strokeWidthSecondary={5}
      />
    );
  }
  function quantityChanged(quantity: number) {
    SetGuestNumber(quantity);
  }
  useEffect(() => {
    if (reservationList.length > 0 && type && index && index !== 0) {
      let LocalIndex = index - 1;
      setReservationInfo({
        ...reservationInfo,
        firstName: reservationList[LocalIndex].CustomerName,
        mobileNumber: reservationList[LocalIndex].CustomerPhone,
        email: reservationList[LocalIndex].CustomerEmail,
      });
      setReserveDate(reservationList[LocalIndex].ReserveDate);
      SetGuestNumber(reservationList[LocalIndex].GuestNumber);
      setReserveTime(reservationList[LocalIndex].ReserveTime);
    } else if (reservationList.length > 0 && !type) {
      setReservationInfo({
        ...reservationInfo,
        firstName: reservationList[0].CustomerName,
        mobileNumber: reservationList[0].CustomerPhone,
        email: reservationList[0].CustomerEmail,
      });
      setReserveDate(reservationList[0].ReserveDate);
      SetGuestNumber(reservationList[0].GuestNumber);
    }
  }, [index, type]);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="bottom-sheet-wrapper">
        <div className="bottom-sheet-content padding" ref={refBottomSheet}>
          <div className="navigation-wrapper pickup-information">
            <div className={`title ${type !== 'cancel' && 'mb-25'}`}>
              {type === 'cancel' ? t('are_you_sure') : t('reservation_info')}
            </div>
            {type === 'cancel' && (
              <div
                className="mb-25"
                style={{
                  fontSize: '14px',
                }}
              >
                {t('by_clicking_yes_this_reservation_canceled')}
              </div>
            )}
            {type !== 'cancel' && (
              <>
                <div className="pickup-information-item row-item">
                  <div className="flex-row space-between">
                    <div className="label">
                      {t('full_name')} <span className="error">*</span>
                    </div>
                  </div>
                  <InputComponent
                    disabled={type === 'cancel'}
                    name="fullname"
                    value={reservationInfo.firstName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setReservationInfo({ ...reservationInfo, firstName: e.target.value })
                    }
                    placeholder="John"
                  />
                </div>
                <div className="pickup-information-item row-item">
                  <div className="flex-row space-between">
                    <div className="label">
                      {t('mobile_number')} <span className="error">*</span>{' '}
                      {reservationInfo.mobileNumber.length != 0 && reservationInfo.mobileNumber.length < 8 ? (
                        <span className="error">({t('enter_valid_number')})</span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  <div className="flex-row space-between">
                    <div className="phone-country-code">
                      <InputComponent
                        value=""
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setReservationInfo({ ...reservationInfo, mobileNumber: e.target.value })
                        }
                        placeholder="(855)"
                        disabled={true}
                      />
                    </div>

                    <InputComponent
                      disabled={type === 'cancel'}
                      name="phonenumber"
                      value={reservationInfo.mobileNumber}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        e.target.value.length <= 9
                          ? setReservationInfo({ ...reservationInfo, mobileNumber: e.target.value })
                          : ''
                      }
                      placeholder="12 123 123"
                      type="number"
                    />
                  </div>
                </div>
                <div className="pickup-information-item row-item">
                  <div className="flex-row space-between">
                    <div className="label">
                      {t('date_time')} <span className="error">*</span>{' '}
                      {isDateError && <span className="error">{dateError}</span>}
                    </div>
                  </div>

                  <div className="flex-row space-between" style={{ gap: '10px' }}>
                    <input
                      style={{
                        width: '50vw',
                        height: '55px',
                        borderRadius: '10px',
                        border: '1px solid #D3D4D5',
                        padding: '0px 10px',
                        backgroundColor: 'white',
                        color: 'black',
                        fontSize: '1rem',
                      }}
                      disabled={type === 'cancel'}
                      name="phonenumber"
                      value={ReserveDate}
                      onChange={(event) => getDateTime(event)}
                      type="date"
                      min={getCurrentDate()}
                      max={getMaxFutureDate()}
                    />
                    <select
                      disabled={type === 'cancel'}
                      defaultValue={`${ReserveTime}`}
                      className="select"
                      onChange={(e) => onSelectTime(e)}
                      style={{
                        width: '50vw',
                        height: '55px',
                        borderRadius: '10px',
                        border: '1px solid #D3D4D5',
                        padding: '0px 10px',
                        backgroundColor: 'white',
                        color: 'black',
                        fontSize: '1rem',
                      }}
                    >
                      <option value="" disabled>
                        {t('please_choose_time')}
                      </option>
                      {type && (
                        <option value={`${ReserveTime}`} disabled>
                          {moment(getConvertedDate(`${ReserveDate} ${ReserveTime}`)).format('LT')}
                        </option>
                      )}
                      {filteredOptions.length > 0 ? (
                        <>
                          {' '}
                          {filteredOptions.map((option, idx) => {
                            const key = Object.keys(option)[0]; // Get the key of the option object
                            const value = option[key]; // Get the value of the option object

                            return (
                              <option key={idx} value={key}>
                                {value}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <option value="" disabled>
                            Out of order time
                          </option>
                        </>
                      )}
                    </select>
                  </div>
                </div>

                <div className="pickup-information-item row-item">
                  <div className=" flex-row space-between">
                    <div className="label">
                      {t('number_of_people')}
                      <span className="error">*</span>
                    </div>
                    {type === 'cancel' ? (
                      <span>{GuestNumber}</span>
                    ) : (
                      <Quantity startedWith={GuestNumber} size="small" onChange={quantityChanged} />
                    )}
                  </div>
                </div>
                <div className="pickup-information-item row-item">
                  <div className=" flex-row space-between">
                    <div className="label">
                      {t('email_optional')}
                      {reservationInfo.email.length > 0 && !validateEmail(reservationInfo.email) ? (
                        <span className="error"> ({t('enter_valid_email')})</span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  <InputComponent
                    disabled={type === 'cancel'}
                    value={reservationInfo.email}
                    name="email"
                    onChange={(e) => setReservationInfo({ ...reservationInfo, email: e.target.value })}
                    placeholder="customer@example.com"
                  />
                </div>
              </>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
              }}
            >
              <div
                className="btn primary pd-12 flex-row space-between submit-order-pickup"
                onClick={() => onClickSubmitTableReservation()}
                style={{
                  backgroundColor: type === 'cancel' ? '#ed4d4d' : brandColor,
                  boxShadow: `0px 4px 4px ${type === 'cancel' ? '#ed4d4d' : brandColor}40`,
                  borderColor: type === 'cancel' ? '#ed4d4d' : brandColor,
                }}
              >
                <div className="flex-row center ">
                  <span>{!type && t('book_now')}</span>
                  <span>{type === 'edit' && t('update_reservation')}</span>
                  <span>{type === 'cancel' && t('yes')}</span>
                </div>
                <div className="flex-row column-gap-16">
                  {!isLoading && type !== 'cancel' ? (
                    <img src="/assets/icons/next-white.svg" alt="" />
                  ) : (
                    <> {!isLoading && <Icon icon="iconamoon:check-fill" width={24} />}</>
                  )}
                  {isLoading && <LoadingSpinner />}
                </div>
              </div>
              {type === 'cancel' && (
                <div
                  className="btn primary pd-12 flex-row space-between submit-order-pickup"
                  onClick={() => onClosed()}
                  style={{
                    backgroundColor: brandColor,
                    boxShadow: `0px 4px 4px ${brandColor}40`,
                    borderColor: brandColor,
                  }}
                >
                  <div className="flex-row center column-gap-10">
                    <span>{t('no')}</span>
                  </div>
                  <div className="flex-row column-gap-16">
                    <Icon icon="maki:cross" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableReservationInformation;
