import { find } from 'lodash';
import { useLocation } from 'react-router-dom';

import { Item } from '../interfaces/models/carts';
import { CategoryConfiguration } from '../interfaces/models/menu';
import { getSelectorByKey } from './selector';
import { orderHours } from 'interfaces/models/outlets';

export function getEditItemsFromCart(): Item | null {
  const search = useLocation().search;
  const orderItem = new URLSearchParams(search).get('orderItem');
  const { items } = getSelectorByKey('carts');

  if (orderItem && items.length) {
    return items[Number(orderItem)];
  }

  return null;
}

export function isEditingCart(): boolean {
  const search = useLocation().search;
  const orderItem = new URLSearchParams(search).get('orderItem');

  return orderItem ? true : false;
}

export function getEditingIndex(): number {
  const search = useLocation().search;
  const orderItem = new URLSearchParams(search).get('orderItem');

  return Number(orderItem);
}

export function findCategoryConfigByName(name: string, category: CategoryConfiguration[]) {
  const lowerCaseName = name.toLowerCase();
  const config = find(category, (item: CategoryConfiguration) => item.name.toLowerCase() === lowerCaseName);
  return config;
}

export function isDisabledAddToCartButton(orderHoursArray: orderHours[]): boolean {
  let currentDay = new Date().getDay();
  const currentTime = new Date();
  if (currentDay === 0) {
    currentDay = 6;
  }
  if (orderHoursArray !== undefined && orderHoursArray.length > 0) {
    const filterOrderHours = orderHoursArray.filter((i) => i.dayOfWeek === currentDay - 1);
    for (const orderHour of filterOrderHours) {
      const { orderStartTime, orderEndTime, breakStartTime, breakEndTime } = orderHour;
      const startTime = new Date(currentTime);
      startTime.setHours(orderStartTime.hours, orderStartTime.minutes, orderStartTime.seconds);

      const endTime = new Date(currentTime);
      endTime.setHours(orderEndTime.hours, orderEndTime.minutes, orderEndTime.seconds);

      const breakStartTimeObj = new Date(currentTime);
      breakStartTimeObj.setHours(
        Number(breakStartTime.hours),
        Number(breakStartTime.minutes),
        Number(breakStartTime.seconds)
      );

      const breakEndTimeObj = new Date(currentTime);
      breakEndTimeObj.setHours(Number(breakEndTime.hours), Number(breakEndTime.minutes), Number(breakEndTime.seconds));

      const isWithinOrderTime = startTime <= currentTime && currentTime <= endTime;
      const isWithinBreakTime = breakStartTimeObj <= currentTime && currentTime <= breakEndTimeObj;

      if (isWithinOrderTime && !isWithinBreakTime) {
        return true;
      }
    }
  } else {
    return true;
  }
  return false;
}

export const generateUniqueId = (baseString: string) => {
  const stringWithUnderscores = baseString.replace(/\s+/g, '_');

  const timestamp = new Date().getTime();

  const uniqueId = timestamp % 100000;

  return `${stringWithUnderscores}${uniqueId.toString().padStart(5, '0')}`;
};
