import { Icon } from '@iconify/react';
import { hexToRgba } from 'helper/rgba';
import { HeaderImages } from 'interfaces/models/outlets';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

interface ImageSliderProps {
  images: HeaderImages[];
  brandColor?: string;
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, brandColor }) => {
  const rgbaColor = hexToRgba(brandColor, 0.5);

  const onErrorLoadingImage = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = '/assets/icons/no-images.svg';
  };
  const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 40, // Set width and height to the same value for a perfect circle
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: rgbaColor,
    border: 'none',
    borderColor: 'transparent',
    borderRadius: '50%',
  };
  const indicatorStyles: CSSProperties = {
    background: '#fff9',
    width: 10,
    height: 10,
    display: 'inline-block',
    margin: '0 8px',
    borderRadius: '50%',
  };
  return (
    <Carousel
      axis="horizontal"
      autoPlay={true}
      infiniteLoop={true}
      swipeable={true}
      showThumbs={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 10 }}>
            <Icon icon="ph:caret-left" width={60} color="#ffff" />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 10 }}>
            <Icon icon="ph:caret-right" width={60} color="#ffff" />
          </button>
        )
      }
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: brandColor }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
    >
      {images.map((image: any, index: number) => (
        <div className="restaurant-wallpaper">
          <img
            className="wallpaper"
            loading="lazy"
            src={image.image.imageUrl}
            onError={onErrorLoadingImage}
            style={{ transition: 'opacity 1s ease-in-out' }}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default ImageSlider;
