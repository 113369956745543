import { ThreeDots } from 'react-loader-spinner';
type TypProps = {
  brandColor?: string;
};
export function LoadingMagnfyingGlass({ brandColor }: TypProps) {
  return (
    <ThreeDots
      height="50"
      width="50"
      radius="8"
      color={brandColor}
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      visible={true}
    />
  );
}
