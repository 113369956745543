import { MyImages } from 'cores/components';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { generatePathHelpForHomePage } from 'helper/path';
import { POST_TAB_REQUEST } from 'constants/tabs';

const OrderSummaryHeader = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const dispatch = useAppDispatch();
  const { outletId, orgId } = useParams();
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { t } = useTranslation();
  const backtoHome = () => {
    dispatch({ type: POST_TAB_REQUEST, payload: 'order' });

    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };
  return (
    <div className="order-summary-header">
      {/* <div className="flex-row space-between">
        <MyImages src="/assets/icons/back.svg" onClick={() => navigate(-1)} />
        <MyImages src="/assets/icons/menu-hamburger.svg" />
      </div> */}
      <div className="mt-28 flex-row space-between">
        <div className="order-summary-label">{t('order_history')}</div>
        <div className="flex-row column-gap-7 add-order" onClick={() => backtoHome()} style={{ color: brandColor }}>
          {/* <MyImages src="/assets/icons/plus-circle.svg" /> */}
          <Icon icon="el:plus-sign" width={20} />
          {t('add_order')}
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryHeader;
