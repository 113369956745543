import { combineReducers } from '@reduxjs/toolkit';

import carts from './carts';
import category from './category';
import error from './errors';
import favorites from './favorites';
import filter from './filter/';
import menu from './menu';
import orders from './order/';
import organization from './organization';
import outlets from './outlets';
import request from './request';
import spot from './spot';
import promotion from './promotion';
import localization from './localization';
import tabSelected from './tabs';

// Combine Reducer
const rootReducer = combineReducers({
  category,
  menu,
  organization,
  request,
  outlets,
  filter,
  carts,
  spot,
  promotion,
  favorites,
  error,
  orders,
  localization,
  tabSelected,
});

export default rootReducer;
