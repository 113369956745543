import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { t } from 'i18next';

type ImagesType = {
  [key: string]: string;
};

const images: ImagesType = {
  grocery: '/assets/images/grocery.gif',
  stew: '/assets/images/stew.gif',
  paperBag: '/assets/images/paper-bag.gif',
  scooter: '/assets/images/scooter.gif',
  delivered: '/assets/images/delivered.gif',
};

interface CountDownType {
  deliveryTime: string;
}

const DeliveryCountDownImages = (props: CountDownType) => {
  const [range, setRange] = useState('');
  const [initialTime, setInitialTime] = useState(0);
  const orderTime = localStorage.getItem('orderedTime');

  useEffect(() => {
    const givenDeliveryTime = moment(props.deliveryTime, 'h:mm A');
    const interval = setInterval(() => {
      const currentMoment = moment();
      const diff = givenDeliveryTime.diff(currentMoment, 'minutes');
      if (orderTime === null) {
        localStorage.setItem('orderedTime', diff.toString());
      }
      setInitialTime(diff);
      const orderTimeDiff = parseInt(orderTime || '20', 10);
      const percentage = Math.floor(((orderTimeDiff - diff) / orderTimeDiff) * 100);
      if (percentage >= 100) setRange('delivered');
      else if (percentage >= 60) setRange('scooter');
      else if (percentage >= 50 && percentage < 60) setRange('paperBag');
      else if (percentage >= 20 && percentage < 50) setRange('stew');
      else if (percentage < 20) setRange('grocery');
    }, 1000);

    return () => clearInterval(interval);
  }, [props.deliveryTime, orderTime]);

  const imageToShow = images[range as keyof ImagesType];
  let orderStatus = 'Preparing your order...';

  if (range === 'delivered') {
    orderStatus = 'your_order_delivered';
  } else if (range === 'scooter') {
    orderStatus = 'your_order_on_the_way';
  } else if (range === 'paperBag') {
    orderStatus = 'waiting_order_picked_up';
  } else if (range === 'stew') {
    orderStatus = 'preparing_your_order';
  }

  return (
    <>
      {initialTime > 0 && (
        <p
          style={{
            fontSize: '0.9rem',
            color: '#7C7C7C',
            paddingTop: '1vh',
          }}
        >
          {t('estimated_delivery_time')}
        </p>
      )}
      <p
        style={{
          fontSize: '1.5rem',
          fontWeight: '450',
        }}
      >
        {initialTime > 0 && initialTime >= 15 && <>{initialTime - 5} - </>}
        {initialTime > 0 && initialTime + ' mins'}
      </p>
      <img width="100vw" src={imageToShow} />
      <p
        style={{
          fontSize: '0.9rem',
          color: '#7C7C7C',
          paddingTop: '1vh',
          textAlign: 'center',
        }}
      >
        {t(orderStatus)}
      </p>
    </>
  );
};

export default DeliveryCountDownImages;
