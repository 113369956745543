import { GET, POST } from '../generics/base-service';
import {
  CallingWaiterBodyParam,
  CallingWaiterParam,
  CallingWaiterResponse,
  IOutlets,
  OutletFeedBackParam,
} from '../interfaces/models/outlets';

export function fetchOutletByOrganizationId(orgId: string): Promise<IOutlets[]> {
  const url = '/api/v1/Organization/' + orgId + '/outlets';
  return GET<IOutlets[]>(url);
}

export function createCallingWaiter(
  params: CallingWaiterParam,
  body: CallingWaiterBodyParam
): Promise<CallingWaiterResponse> {
  const { outletId, spotId } = params;
  const url = `/api/v1/Outlet/${outletId}/spot/${spotId}/callWaiter`;
  return POST<CallingWaiterResponse, CallingWaiterBodyParam>(url, body);
}

export function outletsFeedBack(params: OutletFeedBackParam): Promise<IOutlets[]> {
  const url = '/api/v1/Feedback/outlet/send';
  return POST<any>(url, params);
}

export function fetchOutletByOutletId(outletId: string): Promise<any> {
  const url = `/api/v1/Outlet/${outletId}`;
  return GET(url);
}

// export async function paymentRequestService(params: RequestPayment): Promise<any> {
//   const url = 'https://corsproxy.io/?' + encodeURIComponent('https://ntn.e-bot.io/request-payment');
//   try {
//     const response = await fetch(url, {
//       method: 'POST',
//       mode: 'cors',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer e8956e472afbbed041923498fdb73064dc375410`,
//       },
//       body: JSON.stringify(params),
//     });

//     if (!response.ok) {
//       toast.error('Error in Payment Request Service');
//       throw new Error('Network response was not ok');
//     }

//     const responseData = await response.text();
//     return responseData;
//   } catch (error) {
//     console.error('Error in paymentRequestService:', error);
//     toast.error('Error in Payment Request Service');
//     throw error;
//   }
// }
