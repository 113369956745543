import { MyImages } from "cores/components";

const NotFoundPage = () => {
  return (
    <div className="not-found-wrapper">
      <div className="center">
        <MyImages className="image-404" src="/assets/images/404.svg" />
      </div>
      <div className="mt-40">
        <div className="somethings-went-wrong-text">Oops! Something went wrong.</div>
        <p className="description mt-12">
          We couldn’t find the page you were looking for. Why not try to scan the QR Code again.
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
