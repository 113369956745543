import { IOrganization } from 'interfaces/models/organization';
import { Action, Dispatch } from 'redux';

import { PayloadAction } from '@reduxjs/toolkit';

import { GET_ORGANIZATION_REQUEST, GET_ORGANIZATION_REQUEST_SUCCESS } from '../../constants/organization';
import { createListAction } from '../../generics/action-creator';
import { fetchOrganizationInformation } from '../../services/organization';
import { RootState } from '../../store/configureStore';
import { getOutlets } from '../outlets/index';

export const getOrganizationInformation =
  (id: string): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const options = {
      serviceMethod: fetchOrganizationInformation,
      dispatch,
      getState,
      requestName: GET_ORGANIZATION_REQUEST,
      successAction: onOrganizationSuccessAction,
    };

    return createListAction<IOrganization>(options, id);
  };

function onOrganizationSuccessAction(
  response: IOrganization,
  dispatch: Dispatch<Action>
): PayloadAction<IOrganization> {
  if (response) {
    dispatch(getOutlets(response.id));
  }

  return { type: GET_ORGANIZATION_REQUEST_SUCCESS, payload: response };
}
