import { Action, AnyAction } from '@reduxjs/toolkit';

import { SET_REQUEST_IN_PROCESS } from '../../constants/request';

const initialState = {};

export default function request(state = initialState, action: Action<string>): any {
  switch (action.type) {
    case SET_REQUEST_IN_PROCESS:
      return setRequestInProcess(state, action);
  }
  return state;
}

function setRequestInProcess(state: any, action: AnyAction): any {
  if (!action) return state;

  const { inProcess, requestType } = action;
  const requestObject: any = {};
  requestObject[requestType] = inProcess;
  return Object.assign({}, state, requestObject);
}
