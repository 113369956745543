import { useAppSelector } from 'hooks/redux';
import { OutletMenuItem } from 'interfaces/models/menu';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import ThumbnailHorizontal from './thumbnail-horizontal';
import { getSpotId } from 'helper/path';

interface GroupedPromotionsProps {
  categoryDisplayed: (categoryName: string) => void;
}
const GroupedPromotions: React.FC<GroupedPromotionsProps> = ({ categoryDisplayed }) => {
  const menuList = useAppSelector((state) => state.menu.list);
  const productList = useAppSelector((state) => state.promotion.list);
  const spotId = getSpotId();
  const { category } = useAppSelector((state) => state.filter);
  const promoRef = useRef<HTMLDivElement>(null);

  const promoType = (type: string, value: number) => {
    if (type === '%') {
      return value + '% OFF - ';
    } else {
      return '$' + value + ' OFF - ';
    }
  };
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      // ref.current.scrollIntoView({ behavior: 'smooth' });
      const element = ref.current;
      const elementRect = element.getBoundingClientRect();
      const absoluteY = elementRect.top + window.scrollY;

      window.scrollTo({
        top: absoluteY - window.innerHeight / 4.2,
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    if (category.length > 0 && category[0] === 'special_deals') {
      scrollToSection(promoRef);
    }
  }, [category]);

  const handleScroll = () => {
    // Get the center of the viewport
    const viewportCenter = window.scrollY + window.innerHeight / 2;

    // Loop through your categories and find the closest category to the viewport center
    const categoryRef = promoRef.current;

    if (categoryRef) {
      const categoryRect = categoryRef.getBoundingClientRect();
      const categoryTop = categoryRect.top + window.scrollY;
      const categoryBottom = categoryRect.bottom + window.scrollY;

      if (categoryTop <= viewportCenter && categoryBottom >= viewportCenter) {
        categoryDisplayed('special_deals');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [promoRef]);
  return (
    <div className="menu-content-promo" ref={promoRef}>
      <div>
        {productList &&
          productList.map((promo, index: number) => {
            const filtermenuByPromo =
              promo.eligibleMenus &&
              promo.eligibleMenus.map((item: any) => {
                const menuListFiltered = menuList.find((menu) => menu.id === item.id);
                if (menuListFiltered) {
                  return menuListFiltered;
                }
                return null;
              });
            const hasMatchingSpotInMenu =
              Array.isArray(filtermenuByPromo) &&
              filtermenuByPromo &&
              filtermenuByPromo.some((item) => {
                if (!item) return false;
                return item.spots && item.spots.length > 0 && item.spots.some((spot: any) => spot.id === spotId);
              });
            if (promo && promo.eligibleMenus && promo.eligibleMenus.length > 0 && hasMatchingSpotInMenu)
              return (
                <div key={index}>
                  <div className="all-time-filter" key={index}>
                    <div className="text-all-time-category">
                      <span style={{ whiteSpace: 'nowrap' }}>{promoType(promo.discountType, promo.value)}</span>
                      {promo.name}
                    </div>
                  </div>
                  <div className="item thumbnail-horizontal-flex">
                    {promo.eligibleMenus.length > 0 &&
                      promo.eligibleMenus.map((item: any, index: number) => {
                        return (
                          <div key={index}>
                            {menuList &&
                              menuList.map((menu) => {
                                const checkSpot = menu.spots && menu.spots.find((item) => item.id === spotId);
                                if (menu.id === item.id && menu.spots && menu.spots.length > 0 && checkSpot) {
                                  return <ThumbnailHorizontal key={index} item={menu} />;
                                }
                              })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
          })}
      </div>
    </div>
  );
};
export default GroupedPromotions;
