import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MyImages } from '../../../cores/components/MyImage/index';
import { generatePathHelpForHomePage, getSpotId } from 'helper/path';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useState } from 'react';
import WifiDetails from 'components/partials/WifiDetails';
import { SPOT_HOTEL_ROOM_SERVICE, SPOT_QRTABLE } from 'constants/spot';
import CallForWaiter from 'components/partials/CallForWaiter';
import { getLatestOrder } from 'helper/order-summary';
import { useAppDispatch } from 'hooks/redux';
import { POST_TAB_REQUEST } from 'constants/tabs';
import { useTranslation } from 'react-i18next';
export interface IProps {
  brandColor: string;
  outlet: any;
  spot: any;
  setWifiDetailsModal?: () => void;
  setCallForWaiter: () => void;
  setOpenBillPayment: () => void;
  setOpenLanguage?: () => void;
  setSelectedTab: (param: string) => void;
  selectedTab: string;
}

const NavigationButtons = (props: IProps) => {
  const {
    brandColor,
    outlet,
    spot,
    setWifiDetailsModal,
    setCallForWaiter,
    setOpenLanguage,
    setSelectedTab,
    selectedTab,
  } = props;
  const { outletId, orgId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const [openWifi, setOpenWifi] = useState(false);
  const [list] = useState(getLatestOrder());
  const filteredList = list && list.filter((item) => item.outletId === outletId && item.outletSpotId === spot.id);
  const { t } = useTranslation();
  const discountedPriceSum =
    filteredList && filteredList.length > 0
      ? filteredList.reduce((total, item) => {
          const price = item.discountedPrice && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
          const isPaid = item.status === 0 ? price : spot.type === Number(SPOT_QRTABLE) ? 0 : price;

          return total + isPaid;
        }, 0)
      : 0;
  const firstRowButtonItems = [
    { name: 'menu_order', icon: '/assets/images/button/order.png', function: goToMenu },
    { name: 'call_service', icon: '/assets/images/button/room-service.png', function: setCallForWaiter },

    {
      name: 'my_order_pay',
      icon: '/assets/images/button/pay.png',
      function: discountedPriceSum > 0 ? goToOrderSummary : undefined,
    },
  ];
  const secondRowButtonItems = [
    ...(outlet && outlet.wiFis !== null && outlet.wiFis && outlet.wiFis.length > 0
      ? [{ name: 'wifi', icon: '/assets/images/button/wifi.png', function: setWifiDetailsModal }]
      : [{ name: 'language', icon: '/assets/images/button/language.png', function: setOpenLanguage }]),
    { name: 'feedback', icon: '/assets/images/button/feedback.png', function: goToRate },
    { name: 'social_media', icon: '/assets/images/button/social-media.png', function: goToContactUs },
  ];

  const thirstRowButtonItems = [
    ...(outlet && outlet.wiFis !== null && outlet.wiFis && outlet.wiFis.length > 0
      ? [{ name: 'language', icon: '/assets/images/button/language.png', function: setOpenLanguage }]
      : []),
  ];
  function goToMenu() {
    dispatch({ type: POST_TAB_REQUEST, payload: 'order' });

    setSelectedTab('order');
  }
  function goToContactUs() {
    setSelectedTab('contact-us');
    dispatch({ type: POST_TAB_REQUEST, payload: 'contact-us' });

    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/contact-us', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  }

  function goToRate() {
    setSelectedTab('rating-outlet');
    dispatch({ type: POST_TAB_REQUEST, payload: 'rating-outlet' });
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/rating-outlet', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  }
  function closedWifi() {
    setOpenWifi(false);
  }

  function goToOrderSummary() {
    setSelectedTab('order-summary');
    dispatch({ type: POST_TAB_REQUEST, payload: 'order-summary' });
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/order-summary', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  }

  function wifiDetails() {
    setWifiDetailsModal;
    dispatch({ type: POST_TAB_REQUEST, payload: null });
  }
  return (
    <>
      <div
        className="navigation-buttons-container"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          zIndex: 200,
        }}
      >
        <div
          className="navigation-buttons-row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
          }}
        >
          {firstRowButtonItems.map((item) => (
            <div
              className="navigation-button"
              style={{
                minWidth: '115px',
                minHeight: '118px',
                backgroundColor: item.name === 'my_order_pay' && discountedPriceSum === 0 ? '#c1c1c1' : brandColor,
                borderRadius: '20%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '5px',
                padding: '10px',
              }}
              onClick={item.function} // Place the onClick handler here
            >
              <MyImages src={item.icon} loading="lazy" width={'70px'} />
              <div
                style={{
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: '500',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {t(item.name)}
              </div>
            </div>
          ))}
        </div>
        <div
          className="navigation-buttons-row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
          }}
        >
          {secondRowButtonItems.map((item) => (
            <div
              className="navigation-button"
              style={{
                minWidth: '115px',
                minHeight: '118px',
                backgroundColor: item.name !== 'wifi' ? brandColor : outlet.wiFis !== null ? brandColor : '#c1c1c1',
                borderRadius: '20%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '5px',
                padding: '10px',
              }}
              onClick={item.function}
            >
              <div>
                <MyImages src={item.icon} loading="lazy" width={'70px'} />
              </div>
              <div
                style={{
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: '500',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {t(item.name)}
              </div>
            </div>
          ))}
        </div>
        <div
          className="navigation-buttons-row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
          }}
        >
          {thirstRowButtonItems.map((item) => (
            <div
              className="navigation-button"
              style={{
                minWidth: '115px',
                minHeight: '118px',
                backgroundColor: brandColor,
                borderRadius: '20%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '5px',
                padding: '10px',
              }}
              onClick={item.function}
            >
              <div>
                <MyImages src={item.icon} loading="lazy" width={'70px'} />
              </div>
              <div
                style={{
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: '500',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {t(item.name)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NavigationButtons;
