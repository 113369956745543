import { useState } from "react";

export interface TypeProps {
  onClick: (rating: number) => void;
}

export const FiveStar = (props: TypeProps) => {
  const [star, setStar] = useState(0);

  const onClickStar = (index: number) => {
    setStar(index + 1);
    const { onClick } = props;
    onClick(index + 1);
  };

  return (
    <>
      <div className="star-list">

        {Array.from(Array(5)).map((_, index: number) => (
          <svg
            key={index}
            onClick={() => onClickStar(index)}
            className="rating-star"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            style={{ fill: `${star >= index + 1 ? '#FABA39' : ''}` }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2697 1.05482C14.3054 1.07601 14.3395 1.10185 14.3711 1.13209L14.4595 1.28302L18.4695 8.12772L18.6817 8.48992L19.0888 8.59213L26.5573 10.467L26.5573 10.4671L26.57 10.4701C26.6527 10.4897 26.7339 10.5328 26.8038 10.6003C26.874 10.668 26.93 10.758 26.9615 10.8634L26.965 10.8752L26.9688 10.8869C27.0022 10.9893 27.0093 11.1007 26.9885 11.2082L27.9705 11.3974L26.9885 11.2082C26.9678 11.3157 26.921 11.4111 26.8574 11.4861L26.8511 11.4936L26.8449 11.5012L21.8659 17.6071L21.6142 17.9158L21.6437 18.3131L22.2379 26.3163L22.2385 26.3237C22.2478 26.4376 22.2286 26.5508 22.1846 26.6515C22.1408 26.7519 22.0751 26.8336 21.9985 26.8919L22.6038 27.6879L21.9984 26.8919C21.9221 26.95 21.8368 26.9835 21.7514 26.9939C21.6661 27.0042 21.5786 26.9917 21.4962 26.9554L21.4911 26.9533L14.3975 23.8807L14 23.7085L13.6025 23.8807L6.50885 26.9533L6.50883 26.9532L6.50078 26.9568C6.41866 26.9932 6.33141 27.0058 6.24638 26.9955L6.12538 27.9881L6.24637 26.9955C6.16123 26.9851 6.07618 26.9513 6.00021 26.893L5.9935 26.8879L5.98669 26.8828C5.91251 26.8278 5.8485 26.7495 5.8059 26.6521C5.76322 26.5546 5.74506 26.4445 5.75524 26.334L5.75606 26.3252L5.75672 26.3163L6.35098 18.3131L6.38048 17.9158L6.12873 17.6071L1.14976 11.5012L1.14282 11.4926L1.13569 11.4843C1.07312 11.4109 1.02709 11.3171 1.00706 11.2112C0.987003 11.1053 0.994669 10.9957 1.02835 10.8954L1.03476 10.8763L1.04039 10.8569C1.07066 10.753 1.12531 10.6644 1.19391 10.5979C1.26223 10.5316 1.34168 10.4896 1.42234 10.4706L1.42986 10.4689L1.43736 10.467L8.90581 8.59213L9.31296 8.48992L9.52516 8.12772L13.5351 1.28302L13.5352 1.28305L13.5411 1.27265C13.5954 1.17729 13.6694 1.10369 13.7517 1.05482L13.241 0.19508L13.7517 1.05482C13.8336 1.00616 13.9227 0.982422 14.0107 0.982422C14.0987 0.982422 14.1878 1.00616 14.2697 1.05482Z"
              stroke="#FABA39"
              strokeWidth="2"
            />
          </svg>
        ))}
      </div >
    </>
  );
};
