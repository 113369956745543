import { useEffect, useState } from 'react';
import Location from 'components/partials/Location';
import { ToastContainer, toast } from 'react-toastify';
import 'styles/components/_location.scss';

import { isDisabledAddToCartButton, isEditingCart } from '../../../helper/cart';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { IOutlets, orderHours } from 'interfaces/models/outlets';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import { SPOT_HOTEL_ROOM_SERVICE, SPOT_QRTABLE } from 'constants/spot';
import { QuickOrderConfirmation } from 'components/partials/QuickOrderConfirmation';
import { AddOn } from 'interfaces/models/menu';

type TypeProps = {
  quantity: number;
  price: number;
  onClick?: () => void;
  messageID: number;
  distance: number;
  menuDetails?: any;
  addOns: AddOn[];
  isValid?: boolean;
  setIsSubmitting: () => void;
  specialRequest?: string;
};

const AddToCart = ({
  quantity = 0,
  price = 0,
  onClick,
  messageID,
  distance,
  menuDetails,
  addOns,
  isValid,
  setIsSubmitting,
  specialRequest,
}: TypeProps) => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const { t } = useTranslation();
  const { list } = useAppSelector((state) => state.outlets);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { outletId } = useParams();
  let findOutletId = list.find((i) => i.id === outletId);
  const [currentOutlet, setCurrentOutlet] = useState<IOutlets>();
  const [isWithinOrderTime, setIsWithinOrderTime] = useState(false);
  const [openQuickOrderConfirmation, setOpenQuickOrderInformation] = useState(false);
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  useEffect(() => {
    const currentOrderHours = currentOutlet?.orderHours || undefined;
    const isWithinHours = currentOrderHours ? isDisabledAddToCartButton(currentOrderHours) : false;
    setIsWithinOrderTime(isWithinHours);
  }, [currentOutlet]);

  useEffect(() => {
    setCurrentOutlet(findOutletId);
  }, [findOutletId]);

  const whenClickAddHappened = () => {
    if (onClick) {
      toast.dismiss();
      isWithinOrderTime ? onClick() : toast.error('Sorry, you cannot order at this time.');
    }
  };
  const onClickClose = () => {
    setOpenQuickOrderInformation(false);
  };
  const submitQuickOrder = () => {
    setIsSubmitting();
    if (isValid) {
      setOpenQuickOrderInformation(true);
    }
  };
  return (
    <>
      {openQuickOrderConfirmation && (
        <QuickOrderConfirmation
          specialRequest={specialRequest ? specialRequest : ''}
          setIsSubmitting={() => submitQuickOrder()}
          brandColor={brandColor}
          price={quantity * price}
          outlet={currentOutlet}
          onClose={() => setOpenQuickOrderInformation(false)}
          menu={menuDetails}
          quantity={quantity}
          addOns={addOns}
          isValid={isValid ? isValid : false}
        />
      )}
      {quantity >= 0 && (
        <div className="footer-wrapper">
          <div>
            {spot && (spot.type == SPOT_QRTABLE || spot.type == SPOT_HOTEL_ROOM_SERVICE) ? (
              <>
                {isWithinOrderTime ? (
                  <div
                    className={`basket-wrapper`}
                    onClick={() => (isWithinOrderTime ? submitQuickOrder() : undefined)}
                    style={{
                      backgroundColor: brandColor,
                      boxShadow: `0px 4px 4px ${brandColor}40`,
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    <Icon icon="icon-park-outline:order" color="#ffff" width={40} />
                    <p>{t('quick_order')}</p>
                  </div>
                ) : (
                  <div
                    className={`basket-wrapper-disabled`}
                    style={{
                      // backgroundColor: brandColor,
                      // boxShadow: `0px 4px 4px ${brandColor}40`,
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    <Icon icon="icon-park-outline:order" color="#ffff" width={40} />
                    <p>{t('quick_order')}</p>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="footer-price">
                  $ {(quantity * price).toFixed(2)}
                  {!currentOutlet?.areChargesIncluded &&
                    (currentOutlet?.vat !== 0 || currentOutlet?.serviceCharge !== 0) &&
                    '++'}
                </div>
                <div className="footer-description">
                  {currentOutlet?.areChargesIncluded || (currentOutlet?.vat === 0 && currentOutlet?.serviceCharge === 0)
                    ? t('no_additional_charges')
                    : currentOutlet?.vat !== undefined || currentOutlet?.serviceCharge !== undefined
                    ? `Subject to ${currentOutlet?.vat && currentOutlet?.vat > 0 && `${currentOutlet?.vat}% VAT`} & ${
                        currentOutlet?.serviceCharge &&
                        currentOutlet?.serviceCharge > 0 &&
                        `${currentOutlet?.serviceCharge}% Service Charge`
                      } `
                    : ''}
                </div>
              </>
            )}
          </div>
          {openPopUp ? (
            <div className="basket-wrapper-block" onClick={isWithinOrderTime ? whenClickAddHappened : undefined}>
              {isEditingCart() && (
                <div>
                  {quantity === 1 ? t('add_to_cart', { count: quantity }) : t('add_to_cart', { count: quantity })}
                </div>
              )}
              {!isEditingCart() && (
                <div>
                  {quantity === 1
                    ? `${t('add_to_cart', { count: quantity })}`
                    : `${t('add_to_cart', { count: quantity })}`}
                </div>
              )}
            </div>
          ) : (
            <>
              {isWithinOrderTime ? (
                <div
                  className={`${isWithinOrderTime ? 'basket-wrapper' : 'basket-wrapper-disabled'}`}
                  onClick={isWithinOrderTime ? whenClickAddHappened : undefined}
                  style={{ backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40` }}
                >
                  {isEditingCart() && (
                    <div>
                      {quantity === 1
                        ? t('update_xxxx_item', { count: quantity })
                        : t('update_xxxx_items', { count: quantity })}
                    </div>
                  )}
                  {!isEditingCart() && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Icon icon="icon-park-outline:shopping-cart" color="#ffff" width={30} />
                      <span>
                        {quantity === 1
                          ? `${t('add_to_cart', { count: quantity })}`
                          : `${t('add_to_cart', { count: quantity })}`}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div className={`basket-wrapper-disabled`} onClick={whenClickAddHappened}>
                  {isEditingCart() && (
                    <div>
                      {quantity === 1
                        ? t('update_xxxx_item', { count: quantity })
                        : t('update_xxxx_items', { count: quantity })}
                    </div>
                  )}
                  {!isEditingCart() && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Icon icon="icon-park-outline:shopping-cart" color="#ffff" width={30} />

                      <span>
                        {' '}
                        {quantity === 1
                          ? `${t('add_to_cart', { count: quantity })}`
                          : `${t('add_to_cart', { count: quantity })}`}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AddToCart;
