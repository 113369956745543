import Confirmation from 'components/partials/Confirmations';
import OrderSummaryHeader from 'components/partials/OrderSummaryHeader';
import SelectPaymentMethod from 'components/partials/SelectPaymentMethod';
import { MyImages } from 'cores/components';
import { AddOn } from 'interfaces/models/menu';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { calculateTotalSumOfOrders, getLatestOrder } from '../../helper/order-summary';
import { Item } from '../../interfaces/models/carts';
import { CreateOrderResponse } from '../../interfaces/models/order';
import { useCalculatChargeDeliveryHistory, useCalculateCharge, useCalculatePickup } from 'hooks/useCalculateCharges';
import ChargesSection from 'components/partials/ChargesSection';
import { generatePathHelpForHomePage, getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import {
  SPOT_CINEMA_COUNTER,
  SPOT_CINEMA_HALL,
  SPOT_CINEMA_SEAT,
  SPOT_DELIVERY,
  SPOT_PICKUP_TYPE,
  SPOT_QRTABLE,
} from 'constants/spot';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { RequestPayment } from 'interfaces/models/requestPayment';
import { paymentRequestService } from 'services/payment';
import { toast } from 'react-toastify';
import { generateUniqueId } from 'helper/cart';
import { OrderAgainConfirmation } from 'components/partials/OrderAgainConfirmation';
import { ORDER_UPDATE_ITEM } from 'constants/order';
import { Icon } from '@iconify/react';
import { POST_TAB_REQUEST } from 'constants/tabs';

const OrderSummary = () => {
  const navigate = useNavigate();
  const { outletId, orgId } = useParams();
  const { search } = useLocation();
  const routeParam = useParams();
  const spotId = getSpotId();
  const dispatch = useAppDispatch();
  const { detail } = useAppSelector((state) => state.organization);
  const spotName = getSpotById(spotId) && getSpotById(spotId).spotName;
  const spotType = getSpotById(spotId) && getSpotById(spotId).type;
  const { selected } = useAppSelector((state) => state.tabSelected);
  const [list] = useState(getLatestOrder());
  const filteredList = list && list.filter((item) => item.outletId === outletId && item.outletSpotId === spotId);
  const { t } = useTranslation();
  const [isOrderAgain, SetOrderAgain] = useState(false);
  const [orderAgainItem, SetOrderAgainItem] = useState({});
  const [orderAgainItemImage, SetOrderAgainItemImage] = useState('');
  const [orderAgainItemPromo, SetOrderAgainPromotion] = useState({});
  const [totalPrice, SetTotalPrice] = useState(0);
  const totalSumOfOrders = calculateTotalSumOfOrders(filteredList);
  const discountedPriceSum =
    filteredList && filteredList.length > 0
      ? filteredList.reduce((total, item) => {
          const price = item.discountedPrice && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
          // const isPaid = item.paymentMethod === null ? price : spotType === Number(SPOT_QRTABLE) ? 0 : price;
          const isPaid = item.status === 0 ? price : spotType === Number(SPOT_QRTABLE) ? 0 : price;

          return total + isPaid;
        }, 0)
      : 0;
  const idList: any = filteredList.filter((item) => item.paymentMethod === null).map((item) => item.id);

  const totalVisaMasterCardFee = filteredList.reduce((total, item) => {
    const price = item.discountedPrice && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
    // const isPaid = item.paymentMethod === null ? price : spotType === Number(SPOT_QRTABLE) ? 0 : price;
    const isPaid = item.status === 0 ? price : spotType === Number(SPOT_QRTABLE) ? 0 : price;
    // const chargePercentage = item.paymentMethod !== null ? item.paymentMethod?.chargePercentage : 0;
    const chargePercentage = item.status !== 0 ? item.paymentMethod?.chargePercentage : 0;
    const totalFee = isPaid * chargePercentage;
    return total + totalFee;
  }, 0);
  const deliveryFee =
    filteredList && filteredList.length > 0
      ? filteredList.reduce((total, item) => {
          const deliveryCharge = item.deliveryCharge !== 0 && item.price <= 30 ? item.deliveryCharge : 0;
          return total + deliveryCharge;
        }, 0)
      : 0;
  const [openSelectPayment, setOpenSelectPayment] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [isCallForBillDisabled, setIsCallForBillDisabled] = useState<boolean>(true);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { serviceChargePercentage, vatPercentage, subTotal, serviceChargeTotal, vatTotal, totalSum } =
    useCalculateCharge(discountedPriceSum);

  const { vatPercentagePickup, subTotalPickup, vatTotalPickup, totalSumPickup } =
    useCalculatePickup(discountedPriceSum);
  const { vatPercentageDelivery, subTotalDelivery, vatTotalDelivery, totalSumDelivery } =
    useCalculatChargeDeliveryHistory(discountedPriceSum);
  const [expandCharges, setExpandCharges] = useState<boolean>(false);
  const handleDisAbleFor3Seconds = () => {
    // setIsCallForBillDisabled(true);
    if (selected === 'order-summary') {
      setIsCallForBillDisabled(false);
    } else {
      setTimeout(() => {
        setIsCallForBillDisabled(false);
      }, 5000);
    }
  };

  useEffect(() => {
    handleDisAbleFor3Seconds();
  }, [openSelectPayment, openConfirmation]);
  const updateOrder = (id: string, updatedProperties: Partial<CreateOrderResponse>) => {
    dispatch({
      type: ORDER_UPDATE_ITEM,
      payload: { id, ...updatedProperties },
    });
  };
  function clickSubmitPayment(paymentType: string, method: string) {
    const chargePercentage = method === 'VISA_MASTER' ? 0.03 : 0;
    if (paymentType !== 'PAYONLINE') {
      filteredList.map((item) => {
        if (item.paymentMethod === null)
          updateOrder(item.id, { paymentMethod: { name: method, chargePercentage: chargePercentage } });
      });
      const { outletId, orgId } = routeParam;
      if (outletId && orgId) {
        const path = generatePathHelpForHomePage('/rating-menu', { outletId, orgId }, search);
        navigate(path.pathname + path.search);
      }
    } else {
      ReactGA.event({
        category: `Requested Cash Payment`,
        action: `Requested Cash Payment`,
        transport: 'xhr', // optional, beacon/xhr/image
      });
      const path = '/rating-menu';
      paymentRequest(generateUniqueId(spotName), path, method, chargePercentage);
    }
  }
  function clickBackConfirmation() {
    setOpenConfirmation(false);
    setOpenSelectPayment(true);
  }
  const paymentRequest = (orderId: string, path: string, method: string, chargePercentage: number) => {
    ReactGA.event({
      category: `Requested Online Payment`,
      action: `Requested Online Payment`,
      transport: 'xhr', // optional, beacon/xhr/image
    });
    localStorage.setItem('KESS_PAYMENT_METHOD', method);
    filteredList.map((item) => {
      if (item.paymentMethod === null)
        updateOrder(item.id, { paymentMethod: { name: method, chargePercentage: chargePercentage } });
    });

    const baseUrl = window.location.protocol + '//' + window.location.host;
    if (totalSum >= 0.01 && outletId && orgId && detail) {
      const redirectURL = generatePathHelpForHomePage(path, { outletId, orgId }, search);
      let totalFee = totalSum * 0.03;

      const paymentParams: RequestPayment = {
        body: detail.name,
        amount: Number(method === 'VISA_MASTER' ? (totalSum + totalFee).toFixed(2) : totalSum.toFixed(2)),
        fee: method === 'VISA_MASTER' ? parseFloat(totalFee.toFixed(2)) : 0,
        order_id: orderId,
        redirect_url: `${baseUrl}${redirectURL.pathname}${redirectURL.search}`,
        tip: 0,
        discount: 0,
        total: Number(method === 'VISA_MASTER' ? (totalSum + totalFee).toFixed(2) : totalSum.toFixed(2)),
        payment_method: method,
      };
      paymentRequestService(paymentParams)
        .then((responseData) => {
          window.location.href = responseData;
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      setOpenSelectPayment(false);
      toast.error('Use Cash Payment Method');
    }
  };
  const PaymentFeeTotal = () => {
    if (spotType === Number(SPOT_DELIVERY)) {
      return totalSumDelivery;
    } else if (spotType === Number(SPOT_PICKUP_TYPE)) {
      return totalSumPickup;
    } else {
      return totalSum;
    }
  };
  return (
    <>
      {isOrderAgain && (
        <OrderAgainConfirmation
          outlet={filteredList}
          orderItems={orderAgainItem}
          brandColor={brandColor}
          onClose={() => SetOrderAgain(false)}
          orderAgainItemImage={orderAgainItemImage}
          orderAgainItemPromo={orderAgainItemPromo}
          totalPrice={totalPrice}
        />
      )}
      {openSelectPayment && (
        <SelectPaymentMethod
          totalSum={PaymentFeeTotal()}
          clickedSubmit={(param: string, method: string) => clickSubmitPayment(param, method)}
          cancelCalled={() => setOpenSelectPayment(!openSelectPayment)}
        />
      )}
      {openConfirmation && <Confirmation onClosed={clickBackConfirmation} />}
      <OrderSummaryHeader />

      {filteredList && filteredList.length === 0 && (
        <div className="order-summary-empty">
          <h2 className="title mb-22">{t('no_order')}</h2>
          <button
            onClick={() => navigate(-1)}
            className="btn primary"
            style={{ backgroundColor: brandColor, borderColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40` }}
          >
            {t('order_now')}
          </button>
        </div>
      )}

      <section className="margin-on-last-child">
        {filteredList.map((item: CreateOrderResponse) => (
          <div className="child" key={item.id}>
            <div className="order-summary-wrapper">
              <div className="order-summary-label">
                {t('order')} #{item.orderTypeSequence} -{' '}
                <span style={{ fontWeight: 'normal' }}>{moment(item.createdAt).calendar()}</span>
              </div>
              {/* <div className="order-received-label pt-10">
                {t('order_received_at')} {moment(item.createdAt).format('hh:mm A')}
              </div> */}
            </div>

            <div className="order-content-wrapper" style={{ paddingTop: '5px' }}>
              <OrderItem
                SetOrderAgainItemImage={(img: string) => SetOrderAgainItemImage(img)}
                detail={detail}
                items={item.items}
                orderItem={item}
                price={item.discountedPrice !== 0 ? item.discountedPrice : item.price}
                orderItemDeliveryCharge={item.deliveryCharge !== 0 ? item.deliveryCharge : 0}
                SetOrderAgain={() => SetOrderAgain(true)}
                SetOrderAgainItem={(orderItem: any) => SetOrderAgainItem(orderItem)}
                SetOrderAgainPromotion={(promo: any) => SetOrderAgainPromotion(promo)}
                SetTotalPrice={(price: number) => SetTotalPrice(price)}
              />
            </div>
          </div>
        ))}
      </section>
      {filteredList && filteredList.length > 0 && (
        <div className="order-summary-bottom">
          {spotType === Number(SPOT_QRTABLE) && (
            <>
              {totalSum > 0 && serviceChargeTotal > 0 && (
                <div
                  onClick={() => setExpandCharges(!expandCharges)}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '-15px',
                  }}
                >
                  <Icon icon={expandCharges ? 'mingcute:up-line' : 'mingcute:down-line'} color="#000" width={30} />
                </div>
              )}
              {expandCharges && (
                <div
                  style={{
                    borderBottom: `${subTotal > 0 ? '1px solid #D3D4D5' : 'none'}`,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {serviceChargePercentage + vatPercentage > 0 && ExtraChargesQRTable('subtotal', subTotal, 0, t)}
                  {serviceChargePercentage > 0 &&
                    ExtraChargesQRTable('service_charges', serviceChargeTotal, serviceChargePercentage, t)}
                  {vatPercentage > 0 && ExtraChargesQRTable('vat', vatTotal, vatPercentage, t)}
                </div>
              )}
              {totalSum > 0 ? (
                <TotalPrice finalPrice={totalSum} />
              ) : (
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <span
                    style={{
                      fontSize: '20px',
                      fontWeight: '700',
                      color: '#343434',
                    }}
                  >
                    All Items are Paid.
                  </span>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <button
                  onClick={() => {
                    if (outletId && orgId) {
                      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
                      navigate(path.pathname + path.search);
                    }
                  }}
                  className="btn primary mt-20"
                  style={{
                    backgroundColor: 'white',
                    borderColor: brandColor,
                    boxShadow: `0px 4px 4px ${brandColor}40`,
                    fontSize: '1rem',
                    color: brandColor,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    padding: '0.6rem',
                  }}
                >
                   {t('place_another_order')}
                </button>
                {totalSum > 0 && (
                  <button
                    onClick={() => {
                      if (!isCallForBillDisabled) setOpenSelectPayment(!openSelectPayment);
                    }}
                    className="btn primary mt-20"
                    style={{
                      fontSize: '1rem',
                      padding: '0.6rem',
                      whiteSpace: 'nowrap',
                      backgroundColor: `${isCallForBillDisabled ? '#e5e5e5' : brandColor}`,
                      borderColor: `${isCallForBillDisabled ? '#e5e5e5' : brandColor}`,
                      boxShadow: `${isCallForBillDisabled ? 'none' : `0px 4px 4px ${brandColor}40`}`,
                      color: `${isCallForBillDisabled ? '#b1b0b1' : 'white'}`,
                      transitionDuration: `0.5s`,
                      transitionDelay: `0.5s`,
                    }}
                  >
                    {t('call_for_bill')}
                  </button>
                )}
              </div>
            </>
          )}
          {spotType === Number(SPOT_PICKUP_TYPE) && (
            <>
              <div style={{ borderBottom: '1px solid #D3D4D5', display: 'flex', flexDirection: 'column' }}>
                {vatPercentagePickup > 0 && ExtraCharges('subtotal', subTotalPickup, 0)}
                {vatPercentagePickup > 0 && ExtraCharges('vat', vatTotalPickup, vatPercentagePickup)}
                {totalVisaMasterCardFee > 0 && ExtraCharges('Visa/Master Card Fee: ', totalVisaMasterCardFee, 0.03)}
              </div>
              <TotalPrice finalPrice={totalSumPickup} />
            </>
          )}
          {spotType === Number(SPOT_DELIVERY) && (
            <>
              <div style={{ borderBottom: '1px solid #D3D4D5', display: 'flex', flexDirection: 'column' }}>
                {vatPercentageDelivery > 0 && ExtraCharges('subtotal', subTotalDelivery, 0)}
                {vatPercentageDelivery > 0 && ExtraCharges('vat', vatTotalDelivery, vatPercentageDelivery)}
                {totalVisaMasterCardFee > 0 && ExtraCharges('Visa/Master Card Fee: ', totalVisaMasterCardFee, 0.03)}

                <div className="sub-summary-label">
                  <span>{t('delivery_fee')}</span>
                  <span>$ {deliveryFee !== 0 ? deliveryFee.toFixed(2) : 'Free'}</span>
                </div>
              </div>
              <TotalPrice finalPrice={totalSumDelivery + deliveryFee} />
            </>
          )}
          {(spotType === Number(SPOT_CINEMA_SEAT) ||
            spotType === Number(SPOT_CINEMA_COUNTER) ||
            spotType === Number(SPOT_CINEMA_HALL)) && (
            <>
              <div style={{ borderBottom: '1px solid #D3D4D5', display: 'flex', flexDirection: 'column' }}>
                {serviceChargePercentage + vatPercentage > 0 && ExtraCharges('subtotal', subTotal, 0)}
                {serviceChargePercentage > 0 &&
                  ExtraCharges('service_charges', serviceChargeTotal, serviceChargePercentage)}
                {vatPercentage > 0 && ExtraCharges('vat', vatTotal, vatPercentage)}
                {totalVisaMasterCardFee > 0 && ExtraCharges('Visa/Master Card Fee: ', totalVisaMasterCardFee, 0.03)}
              </div>
              <TotalPrice finalPrice={totalSum + totalVisaMasterCardFee} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              ></div>
            </>
          )}
        </div>
      )}
    </>
  );
};

type TypeProps = {
  finalPrice: number;
};
const TotalPrice = ({ finalPrice }: TypeProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex-row space-between">
        <div className="total-label mt-20">{t('total')}</div>
        <div className="total-label mt-20">$ {finalPrice.toFixed(2)}</div>
      </div>
    </>
  );
};

const ExtraCharges = (name: string, total: number, charge: number) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="sub-summary-label">
        <span>
          {t(name)}
          {charge > 0 ? ` (${charge}%)` : ''}
        </span>
        <span>$ {total.toFixed(2)}</span>
      </div>
    </>
  );
};
const ExtraChargesQRTable = (name: string, total: number, charge: number, t: any) => {
  // const { t } = useTranslation();
  if (total > 0)
    return (
      <>
        <div className="sub-summary-label">
          <span>
            {t(name)}
            {charge > 0 ? ` (${charge}%)` : ''}
          </span>
          <span>$ {total.toFixed(2)}</span>
        </div>
      </>
    );
};
type OrderItemProps = {
  items: Item[];
  price: number;
  orderItemDeliveryCharge?: number;
  detail?: any;
  SetOrderAgain: () => void;
  SetOrderAgainItem: (item: any) => void;
  SetOrderAgainItemImage: (img: string) => void;
  SetOrderAgainPromotion: (promo: any) => void;
  SetTotalPrice: (price: number) => void;
  orderItem?: CreateOrderResponse;
};
const OrderItem = (props: OrderItemProps) => {
  const {
    items,
    SetOrderAgainPromotion,
    orderItemDeliveryCharge,
    detail,
    SetOrderAgain,
    SetOrderAgainItem,
    SetOrderAgainItemImage,
    SetTotalPrice,
    orderItem,
  } = props;
  const [showChargesSection, setShowChargesSection] = useState(false);
  const { t } = useTranslation();
  const { list } = useAppSelector((state) => state.menu);
  let totalPrice = 0;
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const spotId = getSpotId();
  const spotType = getSpotById(spotId) && getSpotById(spotId).type;

  // Calculate the total price for all items
  totalPrice = items.reduce((total, item) => {
    let basePrice = item.discountedPrice !== 0 ? item.discountedPrice : item.price;
    basePrice = basePrice !== null ? basePrice : 0;

    let itemTotalPrice = basePrice;

    if (item && item.addOns && item.addOns.length > 0) {
      const addOnsTotalPrice = item.addOns.reduce((addOnTotal, addOn) => addOnTotal + addOn.price, 0);
      itemTotalPrice += addOnsTotalPrice;
    }

    return total + itemTotalPrice * (item.quantity || 1);
  }, 0);
  const getMenuImage = (item: Item) => {
    const menu = list.find((menu) => menu.id === item.menuId);
    if (menu?.image.imageUrl) {
      return menu?.image.imageUrl;
    } else {
      return '/assets/icons/no-images.svg';
    }
  };
  const gotoMenuDetail = (item: any) => {
    const menu = list.find((menu) => menu.id === item.menuId);
    if (menu?.image.imageUrl) {
      SetOrderAgainItemImage(menu?.image.imageUrl);
    } else {
      SetOrderAgainItemImage('');
    }
    SetOrderAgain();
    SetOrderAgainItem(item);
    if (menu) {
      SetOrderAgainPromotion(menu.promotion);
    }
    SetTotalPrice(totalPrice);
  };
  const { totalSum, serviceChargeTotal } = useCalculateCharge(totalPrice);
  console.log('🚀 ~ OrderItem ~ serviceChargeTotal:', serviceChargeTotal);

  return (
    <>
      {items.map((item: Item, index: number) => (
        <div
          className="order-summary"
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'stretch',
            // justifyContent: 'space-around',
          }}
        >
          <div
            style={{
              borderRadius: '20%',
              backgroundImage: `url(${getMenuImage(item)})`,
              height: '64px',
              width: '64px',
              backgroundSize: 'cover',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              // maxWidth: '40vw',
              // minWidth: '40vw',
              width: '80%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                alignContent: 'center',
                gap: '8px',
              }}
            >
              <div style={{ fontWeight: '400' }}>
                <span style={{ fontWeight: '700' }}>{item.quantity}x</span> {item.name}
              </div>{' '}
              <div style={{ whiteSpace: 'nowrap' }}>
                <DiscountOrderPrice item={item} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              {item.addOns?.map((addOn: AddOn, index: number) => (
                <div
                  // className="flex-row flex-12 "
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="order-item-option flex-row ">
                    <MyImages width={14} height="14" src="/assets/icons/circle-grey-add.svg" />
                    <span className="ml-6">{addOn.name}</span>
                  </div>
                  <div className="order-item-option" style={{ justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
                    <span className="ml-6">$ {addOn.price.toFixed(2)}</span>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'center',
                gap: '8px',
              }}
            >
              <span className=" order-item-option">{item.specialRequest || 'No Note'}</span>
              {spotType === Number(SPOT_QRTABLE) && (
                <button
                  className="btn primary"
                  onClick={() => gotoMenuDetail(item)}
                  style={{
                    borderRadius: '8px',
                    backgroundColor: brandColor,
                    borderColor: brandColor,
                    boxShadow: `0px 5px 6px ${brandColor}40`,
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    padding: '12px 5px',
                    maxWidth: '90px',
                    height: '30px',
                  }}
                >
                  {t('order_again')}
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
      {serviceChargeTotal && serviceChargeTotal > 0 ? (
        <div
          onClick={() => setShowChargesSection(!showChargesSection)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5px',
          }}
        >
          <Icon icon={showChargesSection ? 'mingcute:down-line' : 'mingcute:up-line'} color="#000" width={30} />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5px',
          }}
        ></div>
      )}

      {showChargesSection ? (
        <ChargesSection
          totalPrice={totalPrice}
          deliveryCharge={orderItemDeliveryCharge}
          paymentMethod={orderItem?.paymentMethod}
        />
      ) : (
        <div className="flex-row space-between mt-10 total-amount-text">
          <span>{t('total')}: </span>
          <span>
            $
            {orderItem?.paymentMethod !== null && orderItem?.paymentMethod.name === 'VISA_MASTER'
              ? (totalPrice + totalPrice * 0.03).toFixed(2)
              : totalSum.toFixed(2)}
          </span>
        </div>
      )}
    </>
  );
};
export default OrderSummary;

type OtherTypeProps = {
  item: Item | null;
};
const DiscountOrderPrice = ({ item }: OtherTypeProps) => {
  // if (item?.promotion?.promotionId) {
  if (item?.discountedPrice && item?.discountedPrice < item?.price) {
    return (
      <>
        <div className="price" style={{ fontSize: '20px', fontWeight: '700' }}>
          $ {(item?.discountedPrice || 0).toFixed(2)}
        </div>
        {/* <div className="price thumbnail-old-pricing">$ {(item?.price || 0).toFixed(2)}</div> */}
      </>
    );
  } else {
    return (
      <div className="price" style={{ fontSize: '20px', fontWeight: '700' }}>
        $ {(item?.price || 0).toFixed(2)}
      </div>
    );
  }
};
