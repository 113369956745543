import { MenuOption, OutletMenuItem } from 'interfaces/models/menu';

import { GET, POST } from '../generics/base-service';
import { FeedBackMenuParam } from '../interfaces/models/feedback';
import { RequestPayment } from 'interfaces/models/requestPayment';

/**
 * @constant API_ENDPOINT - 'data/menu.json'
 */

/**
 * @returns {{Promise<OutletMenuItem[]>}}
 */
export function getMenuByOutletId(outletId: string): Promise<OutletMenuItem[]> {
  const url = `/api/v1/Outlet/${outletId}/menu`;
  return GET<OutletMenuItem[]>(url);
}

type DetailsParam = {
  menuId: string;
  outletId: string;
};

/**
 * @returns {{Promise<OutletMenuItem>}}
 */
export function getMenuDetailById(params: DetailsParam): Promise<OutletMenuItem> {
  const { outletId, menuId } = params;
  const url = `/api/v1/Outlet/${outletId}/menu/${menuId}`;
  return GET<OutletMenuItem>(url);
}
export function getMenuOptionByMenuId(params: DetailsParam): Promise<MenuOption> {
  const { outletId, menuId } = params;
  const url = `/api/v1/Outlet/${outletId}/menu/${menuId}/options`;
  return GET<MenuOption>(url);
}
/**
 * @returns {{Promise<OutletMenuItem>}}
 */
export function feedBackMenu(params: FeedBackMenuParam[]): Promise<any> {
  const url = '/api/v1/Feedback/menu/send';
  return POST<OutletMenuItem>(url, params);
}
