import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import rootReducer from '../reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['request'],
  version: 1,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: {},
  middleware: [logger, thunk] as const,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [],
});

const persistor = persistStore(store);
// persistor.purge();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export { store, persistedReducer, persistor };
