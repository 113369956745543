import { CART_REQUEST_EMPTY } from 'constants/carts';
import { sumBy } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLatestOrder } from '../../helper/order-summary';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { RootState } from '../../store/configureStore';
import { Item } from 'interfaces/models/carts';
import { PaymentMethods, socialMedias } from 'interfaces/models/outlets';
import { MyImages } from 'cores/components';
import ChargesSection from 'components/partials/ChargesSection';
import { FooterLogo } from 'components/partials/FooterLogo';
import DownloadReceipt from 'cores/components/DownloadReceipt';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import DeliveryCountDownImages from 'components/partials/DeliveryCountDownImages';

export const DeliveryOrderSumary = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const latestOrder = getLatestOrder();
  const { detail } = useAppSelector((state: RootState) => state.organization);
  const { list } = useAppSelector((state: RootState) => state.outlets);
  const totalOrderAmount = latestOrder[0].items.reduce((totalAmount, item) => {
    const basePrice = item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
    const itemPrice = (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
    const total = totalAmount + itemPrice;
    return total;
  }, 0);
  const socialMedias = outletDetails()?.socialMedias;
  const paymentMethods = outletDetails()?.paymentMethods;
  const name = outletDetails()?.name;
  const deliveryAddress = latestOrder[0].deliveryContact?.address;
  const dateObj = new Date(latestOrder[0].deliveryDateTime);
  const deliveryTime = dateObj.toLocaleString([], { hour: 'numeric', minute: 'numeric', hour12: true });

  function outletDetails() {
    let details = list.find((i) => i.id === latestOrder[0].outletId);
    return details;
  }

  const showSocials = (name: string, url: string) => {
    switch (name) {
      case 'Facebook':
        return <MyImages width="30px" src="/assets/icons/facebook.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Instagram':
        return <MyImages width="30px" src="/assets/icons/instagram.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Tripadvisor':
        return <MyImages width="30px" src="/assets/icons/tripadvisor.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Google':
        return <MyImages width="30px" src="/assets/icons/google-fill.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Tiktok':
        return <MyImages width="30px" src="/assets/icons/telegram.svg" onClick={() => window.open(url, '_blank')} />;
      case 'Telegram':
        return <MyImages width="30px" src="/assets/icons/tiktok.svg" onClick={() => window.open(url, '_blank')} />;

      default:
        return '';
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname + window.location.search}`,
      title: `${detail?.name}/Delivery Order Summary`,
    });
    return () => {
      dispatch({ type: CART_REQUEST_EMPTY });
    };
  }, []);
  const handleDeliveryStatusWithTime = () => {
    // Create a countdown Timer for 40 minutes
    const deliveryTime = moment(latestOrder[0].deliveryDateTime).add(40, 'minutes');
    const currentTime = moment();
    const duration = moment.duration(deliveryTime.diff(currentTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    if (hours === 0 && minutes === 0 && seconds === 0) {
      return 'Delivered';
    } else {
      return `Delivering in ${hours} hours ${minutes} minutes`;
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-card">
          <div className="restaurant-profile">
            <img loading="lazy" src={detail?.image.imageUrl} alt={detail?.image.imageUrl} />
          </div>
          <section
            className="section"
            style={{
              paddingTop: '48px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <h2 className="title">{t('order_sent')}</h2>
            <div className="date">
              {t('order')} #{latestOrder[0] ? latestOrder[0].orderTypeSequence : 'N/A'}
            </div>
            <div className="date mt-5">{moment().format('Do MMMM YYYY - hh:mm')}</div>

            {/* <img width="100vw" src={'/assets/images/grocery.gif'} /> */}
            <DeliveryCountDownImages deliveryTime={deliveryTime} />
          </section>

          <section className="section">
            <div className="flex-row space-between total-amount-text">
              <div>{t('order_summary')}</div>
            </div>
            <div className="flex-col pt-16">
              <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                <div className="col">{t('order_from')}</div>
                <div>{name}</div>
              </div>
            </div>
            <div className="flex-col pt-16">
              <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                <div className="col">{t('delivery_time')}</div>
                <div>{deliveryTime}</div>
              </div>
            </div>

            <div className="flex-col pt-16">
              <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10, gap: '5px' }}>
                <div className="col" style={{ whiteSpace: 'nowrap' }}>
                  {t('delivery_address')}
                </div>
                <div style={{ textAlign: 'right' }}>{deliveryAddress}</div>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="flex-row center">
              {paymentMethods &&
                paymentMethods.length > 0 &&
                paymentMethods.map((item: PaymentMethods, index: number) => {
                  return (
                    <div key={index} className="mt-10">
                      {item.name === 'KHQR' ? (
                        <>
                          <div className="flex-row center" style={{ marginBottom: '10px' }}>
                            <p className="bold">{'KHQR'}</p>
                          </div>
                          <div className="flex-row center" style={{ marginBottom: '10px' }}>
                            <MyImages width="120px" src={item.icon.imageUrl} />
                          </div>
                          <div className="flex-row center" style={{ marginBottom: '5px' }}>
                            <p className="bold">{item.accountName}</p>
                          </div>
                          <div className="flex-row center" style={{ marginBottom: '5px' }}>
                            <p className="bold">{item.accountNumber}</p>
                          </div>
                          <div className="flex-row center" style={{ marginBottom: '30px' }}>
                            <p className="bold">
                              ${' '}
                              {totalOrderAmount >= 30
                                ? totalOrderAmount.toFixed(2)
                                : (totalOrderAmount + latestOrder[0].deliveryCharge).toFixed(2)}
                            </p>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })}
            </div>
            <div className="flex-row space-between total-amount-text">
              <div>{t('order_details')}</div>
            </div>
            <div className="flex-col pt-23">
              {latestOrder[0].items.map((item: Item, index: number) => {
                const basePrice =
                  item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
                const totalPrice =
                  (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
                return (
                  <div
                    key={index}
                    className="flex-row space-between"
                    style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                  >
                    <div className="col">
                      <div>
                        <span style={{ fontWeight: '600' }}>{item.quantity}X</span> {item.name}
                      </div>
                      {item.addOns?.map((addon, index) => (
                        <div key={index}>- {addon.name}</div>
                      ))}
                    </div>
                    <div style={{ whiteSpace: 'nowrap' }}>$ {totalPrice.toFixed(2)}</div>{' '}
                    {/* Ensure two decimal places */}
                  </div>
                );
              })}
            </div>

            <ChargesSection totalPrice={totalOrderAmount} deliveryCharge={latestOrder[0].deliveryCharge} />
          </section>
          <section className="section">
            <div className="socials mt-20">
              <h3>{name}</h3>
              {socialMedias && socialMedias.length > 0 ? (
                <>
                  <span>Connect with us on:</span>
                  <div className="flex-row column-gap-10 center">
                    {socialMedias.map((item: socialMedias, index: number) => {
                      return (
                        <div key={index} className="mt-10">
                          {showSocials(item.name, item.url)}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </section>
        </div>
        <div className="flex-col pt-23">
          <DownloadReceipt />
        </div>
        <div style={{ marginTop: '20px' }}>
          <FooterLogo />
        </div>
      </div>
    </div>
  );
};
