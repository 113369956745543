import { Action, AnyAction } from '@reduxjs/toolkit';

import { SET_ERROR } from '../../constants/error';

const initialState = {};

export default function error(state = initialState, action: Action<any>): any {
  switch (action.type) {
    case SET_ERROR:
      return setError(state, action);
  }
  return state;
}

function setError(state: any, action: AnyAction): any {
  if (!action) return state;

  const { error, requestType } = action;
  const requestObject: any = {};
  requestObject[requestType] = error;
  return Object.assign({}, state, requestObject);
}
