import { PayloadAction } from "@reduxjs/toolkit";

import { FILTER_CHANGE_ON_CATEGORY, FILTER_SEARCH_BY_NAME_CHANGE } from "../../constants/Filter";

type StateType = {
  category: string[];
  name: string | null;
};

const initState: StateType = {
  category: [],
  name: null,
};

type PayloadType = string | string[];

export default function filter(state: StateType = initState, action: PayloadAction<PayloadType>): StateType {
  switch (action.type) {
    case FILTER_CHANGE_ON_CATEGORY:
      state.category = action.payload as string[];
      break;

    case FILTER_SEARCH_BY_NAME_CHANGE:
      state.name = action.payload as string;
      break;

    default:
      break;
  }
  return state;
}
