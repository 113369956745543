import { getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import { useCalculatChargeDelivery, useCalculateCharge, useCalculatePickup } from 'hooks/useCalculateCharges';
import { useTranslation } from 'react-i18next';

interface TypeProps {
  totalPrice: number;
  deliveryCharge?: number;
  paymentMethod?: any;
}

const ChargesSection = (props: TypeProps) => {
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const { totalPrice, deliveryCharge, paymentMethod } = props;
  const { serviceChargePercentage, vatPercentage, subTotal, serviceChargeTotal, vatTotal, totalSum } =
    useCalculateCharge(totalPrice);
  const { t } = useTranslation();
  const kessPaymentMethod = localStorage.getItem('KESS_PAYMENT_METHOD');
  const {
    vatPercentageDelivery,
    chargesIncludedDelivery,
    subTotalDelivery,
    vatTotalDelivery,
    totalSumDelivery,
    deliveryCharges,
  } = useCalculatChargeDelivery(totalPrice);
  const { subTotalPickup, vatTotalPickup, totalSumPickup } = useCalculatePickup(totalPrice);
  const totalSumWithDelivery = () => {
    if (deliveryCharge) {
      if (totalPrice > 30) {
        return totalPrice;
      }
      return totalPrice + Number(deliveryCharge);
    }
    return Number(totalPrice);
  };

  return (
    <>
      {spot.type === 0 && (
        <>
          <div style={{ width: '100%' }}>
            {serviceChargePercentage !== 0 && vatPercentage !== 0 && (
              <div className="flex-row space-between mt-12">
                <span className="mr-6">{t('subtotal')}: </span>
                <span>${subTotal.toFixed(2)}</span>
              </div>
            )}

            {serviceChargePercentage !== 0 && (
              <div className="flex-row space-between mt-12">
                <span className="mr-6">{`${t('service_charges')} (${serviceChargePercentage}%): `}</span>
                <span>${serviceChargeTotal.toFixed(2)}</span>
              </div>
            )}

            {vatPercentage !== 0 && (
              <div className="flex-row space-between mt-12 mb-15">
                <span>{`${t('vat')} (${vatPercentage}%): `}</span>
                <span>${vatTotal.toFixed(2)}</span>
              </div>
            )}
            {/* <div className="flex-row space-between mt-10 total-amount-text">
              <span>{t('total')}: </span>
              <span>${totalSum.toFixed(2)}</span>
            </div> */}
            {paymentMethod && paymentMethod !== null && paymentMethod.name === 'VISA_MASTER' && (
              <div className="flex-row space-between mt-12 ">
                <span>Visa/Master Card Fee: </span>
                <span>${(totalSum * paymentMethod.chargePercentage).toFixed(2)}</span>
              </div>
            )}
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #E5E5E5',
              }}
              className="mb-15"
            ></div>
            <div className="flex-row space-between mt-10 total-amount-text">
              <span>{t('total')}: </span>
              <span>
                $
                {paymentMethod && paymentMethod !== null && paymentMethod.name == 'VISA_MASTER'
                  ? (totalSum + totalSum * paymentMethod.chargePercentage).toFixed(2)
                  : totalSum.toFixed(2)}
              </span>
            </div>
          </div>
        </>
      )}

      {spot.type === 2 && (
        <>
          <div style={{ width: '100%' }}>
            {vatPercentage !== 0 && (
              <>
                <div className="flex-row space-between mt-12">
                  <span className="mr-6">{t('subtotal')}: </span>
                  <span>${subTotalPickup.toFixed(2)}</span>
                </div>
                <div className="flex-row space-between mt-12 mb-15">
                  <span>{`${t('vat')}  (${vatPercentage}%): `}</span>
                  <span>${vatTotalPickup.toFixed(2)}</span>
                </div>
              </>
            )}
            {/* <div className="flex-row space-between mt-10 total-amount-text">
              <span>{t('total')}: </span>
              <span>${totalSumPickup.toFixed(2)}</span>
            </div> */}
            {paymentMethod && paymentMethod !== null && paymentMethod.name === 'VISA_MASTER' && (
              <div className="flex-row space-between mt-12 mb-15">
                <span>Visa/Master Card Fee: </span>
                <span>${(totalSumDelivery * paymentMethod.chargePercentage).toFixed(2)}</span>
              </div>
            )}
            <div className="flex-row space-between mt-10 total-amount-text">
              <span>{t('total')}: </span>
              <span>
                $
                {paymentMethod && paymentMethod !== null && paymentMethod.name == 'VISA_MASTER'
                  ? (totalSumPickup + totalSumPickup * paymentMethod.chargePercentage).toFixed(2)
                  : totalSumPickup.toFixed(2)}
              </span>
            </div>
          </div>
        </>
      )}
      {spot.type === 3 && (
        <>
          <div style={{ width: '100%' }}>
            <div className="flex-row space-between mt-12">
              <span className="mr-6">{t('subtotal')}: </span>
              <span>${subTotalDelivery.toFixed(2)}</span>
            </div>
            {deliveryCharge && (
              <div className="flex-row space-between mt-12">
                <span className="mr-6">{t('delivery_fee')}: </span>
                <span> {parseFloat(subTotalDelivery.toFixed(2)) > 30 ? 'Free' : '$' + deliveryCharge}</span>
              </div>
            )}

            {vatPercentage !== 0 && (
              <div className="flex-row space-between mt-12 mb-15">
                <span>{`${t('vat')}  (${vatPercentageDelivery}%): `}</span>
                <span>${vatTotalDelivery.toFixed(2)}</span>
              </div>
            )}
            {/* <div className="flex-row space-between mt-10 total-amount-text">
              <span>{t('total')}: </span>
              {deliveryCharge ? (
                <span>${totalSumDelivery.toFixed(2)}</span>
              ) : (
                <span>${totalSumDelivery.toFixed(2)}</span>
              )}
            </div> */}
            {paymentMethod && paymentMethod !== null && paymentMethod.name === 'VISA_MASTER' && (
              <div className="flex-row space-between mt-12 mb-15">
                <span>Visa/Master Card Fee: </span>
                <span>${(totalSumDelivery * paymentMethod.chargePercentage).toFixed(2)}</span>
              </div>
            )}
            <div className="flex-row space-between mt-10 total-amount-text">
              <span>{t('total')}: </span>
              <span>
                $
                {paymentMethod && paymentMethod !== null && paymentMethod.name == 'VISA_MASTER'
                  ? (totalSumDelivery + totalSumDelivery * paymentMethod.chargePercentage).toFixed(2)
                  : totalSumDelivery.toFixed(2)}
              </span>
            </div>
          </div>
        </>
      )}
      {spot.type === 5 && (
        <>
          <div style={{ width: '100%' }}>
            {serviceChargePercentage !== 0 && vatPercentage !== 0 && (
              <div className="flex-row space-between mt-12">
                <span className="mr-6">{t('subtotal')}: </span>
                <span>${subTotal.toFixed(2)}</span>
              </div>
            )}

            {serviceChargePercentage !== 0 && (
              <div className="flex-row space-between mt-12">
                <span className="mr-6">{`${t('service_charges')} (${serviceChargePercentage}%): `}</span>
                <span>${serviceChargeTotal.toFixed(2)}</span>
              </div>
            )}
            {vatPercentage !== 0 && (
              <div className="flex-row space-between mt-12 mb-15">
                <span>{`${t('vat')} (${vatPercentage}%): `}</span>
                <span>${vatTotal.toFixed(2)}</span>
              </div>
            )}
            {paymentMethod && paymentMethod !== null && paymentMethod.name === 'VISA_MASTER' && (
              <div className="flex-row space-between mt-12 mb-15">
                <span>Visa/Master Card Fee: </span>
                <span>${(totalSum * paymentMethod.chargePercentage).toFixed(2)}</span>
              </div>
            )}
            <div className="flex-row space-between mt-10 total-amount-text">
              <span>{t('total')}: </span>
              <span>
                $
                {paymentMethod && paymentMethod !== null && paymentMethod.name == 'VISA_MASTER'
                  ? (totalSum + totalSum * paymentMethod.chargePercentage).toFixed(2)
                  : totalSum.toFixed(2)}
              </span>
            </div>
          </div>
        </>
      )}
      {(spot.type === 8 || spot.type === 9 || spot.type === 10) && (
        <>
          <div style={{ width: '100%' }}>
            {serviceChargePercentage !== 0 && vatPercentage !== 0 && (
              <div className="flex-row space-between mt-12">
                <span className="mr-6">{t('subtotal')}: </span>
                <span>${subTotal.toFixed(2)}</span>
              </div>
            )}

            {serviceChargePercentage !== 0 && (
              <div className="flex-row space-between mt-12">
                <span className="mr-6">{`${t('service_charges')} (${serviceChargePercentage}%): `}</span>
                <span>${serviceChargeTotal.toFixed(2)}</span>
              </div>
            )}
            {vatPercentage !== 0 && (
              <div className="flex-row space-between mt-12 mb-15">
                <span>{`${t('vat')} (${vatPercentage}%): `}</span>
                <span>${vatTotal.toFixed(2)}</span>
              </div>
            )}
            {paymentMethod && paymentMethod !== null && paymentMethod.name === 'VISA_MASTER' && (
              <div className="flex-row space-between mt-12 mb-15">
                <span>Visa/Master Card Fee: </span>
                <span>${(totalSum * paymentMethod.chargePercentage).toFixed(2)}</span>
              </div>
            )}
            <div className="flex-row space-between mt-10 total-amount-text">
              <span>{t('total')}: </span>
              <span>
                $
                {paymentMethod && paymentMethod !== null && paymentMethod.name == 'VISA_MASTER'
                  ? (totalSum + totalSum * paymentMethod.chargePercentage).toFixed(2)
                  : totalSum.toFixed(2)}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChargesSection;
