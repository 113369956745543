import { useAppSelector } from 'hooks/redux';
import { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { IReservation } from 'interfaces/models/reservation';
import { t } from 'i18next';
interface TypeProps {
  onClosed: () => void;
  type?: string;
  existingReservations?: any[];
  setOpenPreOrderMenu?: (param: boolean) => void;
}

const PreOrderInformation = (props: TypeProps) => {
  const { onClosed, type, setOpenPreOrderMenu, existingReservations } = props;
  const refBottomSheet = useRef<HTMLDivElement>(null);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const [selectedReservation, setSelectedReservation] = useState('');
  const [isError, setIsError] = useState(false);
  const handleClickOutside = (e: any) => {
    if (refBottomSheet.current && !refBottomSheet.current.contains(e.target)) {
      onClosed();
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChooseReservation = () => {
    if (selectedReservation) {
      const reservation = existingReservations?.find((res: IReservation) => res.id === selectedReservation);
      localStorage.setItem('SELECTED_RESERVATION', reservation ? JSON.stringify(reservation) : '');
      setOpenPreOrderMenu && setOpenPreOrderMenu(true);
      onClosed();
    } else {
      setIsError(true);
    }
  };
  return (
    <>
      <div className="bottom-sheet-wrapper">
        <div className="bottom-sheet-content padding" ref={refBottomSheet}>
          <div className="navigation-wrapper pickup-information">
            <div className={`title ${type !== 'cancel' && 'mb-25'}`}>{t('pre_order_information')}</div>

            <div
              style={{
                fontSize: '0.8rem',
              }}
            >
              {t('choose_a_reservation_date_time')}
            </div>
            <div
              style={{
                fontSize: '0.8rem',
                color: '#ed4d4d',
                marginTop: '1vh',
              }}
            >
              {isError && <> {t('please_choose_a_reservation_to_pre_order')}</>}
            </div>
            <div className="reservation-container" style={{ padding: '10px' }}>
              {existingReservations &&
                existingReservations.length > 0 &&
                existingReservations.map((reservation: IReservation, idx: number) => {
                  return (
                    <>
                      <div
                        key={idx}
                        className="reservation-box-container"
                        style={{
                          border: '1px solid #e0e0e0',
                        }}
                        onClick={() => {
                          setSelectedReservation(reservation.id), setIsError(false);
                        }}
                      >
                        <div className="reservation-box">
                          <div className="reservation-box-dates-left-container">
                            <span className="reservation-box-dates-left-top">
                              {moment(`${reservation.ReserveDate}`).format('ddd')}
                            </span>
                            <span
                              className="reservation-box-dates-left-middle"
                              style={{
                                color: brandColor,
                              }}
                            >
                              {moment(`${reservation.ReserveDate}`).format('D')}
                            </span>
                            <span className="reservation-box-dates-left-bottom">
                              {moment(`${reservation.ReserveDate}`).format('MMM')}
                            </span>
                          </div>
                          <div className="reservation-box-details-right-container">
                            <div className="reservation-box-details-right-container-row">
                              <p
                                className="reservation-box-details-right-container-row-name"
                                style={{
                                  color: brandColor,
                                }}
                              >
                                {reservation.CustomerName}
                              </p>
                              <span className="reservation-box-details-right-container-row-edit">
                                {selectedReservation === reservation.id ? (
                                  <Icon icon="ic:sharp-radio-button-checked" color={brandColor} width={20} />
                                ) : (
                                  <Icon icon="ic:sharp-radio-button-unchecked" width={20} />
                                )}
                              </span>
                            </div>

                            <span className="reservation-box-details-right-container-row-guest-number">
                              {/* Booked for {reservation.GuestNumber} Guest{reservation.GuestNumber > 1 && 's'} */}
                              {t('booked_for_number_guests', { counter: reservation.GuestNumber })}
                            </span>
                            <div className="reservation-box-details-right-container-row-date">
                              <div
                                className="reservation-box-details-right-container-row-date-icon"
                                style={{
                                  color: brandColor,
                                }}
                              >
                                <span>
                                  <Icon icon="majesticons:clock-line" width={16} />
                                </span>
                                <p
                                  style={{
                                    fontSize: '15px',
                                  }}
                                >
                                  {moment(`${reservation.ReserveTime}`, 'HH:mm').format('h:mm A')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <button
              className="btn primary pd-12 flex-row space-between submit-order-pickup"
              onClick={() => handleChooseReservation()}
              style={{
                backgroundColor: type === 'cancel' ? '#ed4d4d' : brandColor,
                boxShadow: `0px 4px 4px ${type === 'cancel' ? '#ed4d4d' : brandColor}40`,
                borderColor: type === 'cancel' ? '#ed4d4d' : brandColor,
                marginTop: '3vh',
              }}
            >
              <div className="flex-row center ">
                <span>{t('save')}</span>
              </div>
              <div className="flex-row column-gap-16">
                <img src="/assets/icons/next-white.svg" alt="" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreOrderInformation;
