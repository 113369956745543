import { AnyAction } from "redux";

import { SET_REQUEST_IN_PROCESS } from "../../constants/request";

export function setRequestInProcess(inProcess: boolean, requestType: string): AnyAction {
  return {
    type: SET_REQUEST_IN_PROCESS,
    requestType,
    inProcess,
  };
}
