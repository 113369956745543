import { HTMLInputTypeAttribute } from 'react';

interface Option {
  type?: HTMLInputTypeAttribute;
  value?: any;
  placeholder?: string;
  onChange?: (event: any) => void;
  disabled?: boolean;
  name?: string;
}

export const InputComponent: React.FC<Option> = (option: Option) => {
  // if (option.type) {
  //   option.type = 'text';
  // }
  const textOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (option.onChange) {
      option.onChange(event.target.value);
    }
  };

  return option.type == 'number' ? (
    <input
      name={option.name}
      onChange={textOnChange}
      className="input"
      value={option.value}
      type={option.type}
      placeholder={option.placeholder}
      {...option}
      pattern="\d*"
    />
  ) : (
    <input
      onChange={textOnChange}
      className="input"
      value={option.value}
      type={option.type}
      placeholder={option.placeholder}
      {...option}
      name={option.name}
    />
  );
};
