import {
  GET_MENU_DETAILS_REQUEST_SUCCESS,
  GET_MENU_LIST_REQUEST_SUCCESS,
  GET_MENU_OPTIONS_REQUEST_SUCCESS,
} from 'constants/menu';

import { PayloadAction } from '@reduxjs/toolkit';

import { CREATE_MENU_FEED_BACK_SUCCESS, GROUP_MENU_LIST_REQUEST_SUCCESS } from '../../constants/menu';
import { FeedBackMenuParam } from '../../interfaces/models/feedback';
import { IGroupMenu, MenuOption, OutletMenuItem } from '../../interfaces/models/menu';

const initState = {
  grouped: [],
  list: [],
  details: null,
  rating: [],
  options: [],
};

type TypeState = {
  grouped: IGroupMenu[];
  list: OutletMenuItem[];
  rating: FeedBackMenuParam[];
  details: null | OutletMenuItem;
  options: null | MenuOption[];
};

export default function Menu(
  state: TypeState = initState,
  action: PayloadAction<OutletMenuItem | OutletMenuItem[] | IGroupMenu[] | FeedBackMenuParam[] | MenuOption[]>
) {
  switch (action.type) {
    case GET_MENU_LIST_REQUEST_SUCCESS:
      state.list = action.payload as OutletMenuItem[];
      return { ...state };

    case GROUP_MENU_LIST_REQUEST_SUCCESS:
      state.grouped = action.payload as IGroupMenu[];
      return { ...state };

    case GET_MENU_DETAILS_REQUEST_SUCCESS:
      state.details = action.payload as OutletMenuItem;
      return { ...state };

    case GET_MENU_OPTIONS_REQUEST_SUCCESS:
      state.options = action.payload as MenuOption[];
      return { ...state };

    case CREATE_MENU_FEED_BACK_SUCCESS:
      const newFeedback = action.payload as FeedBackMenuParam[];

      // Check if state.rating is an array, if not, initialize it as an empty array
      const updatedRating = Array.isArray(state.rating) ? state.rating.concat(newFeedback) : newFeedback;

      return {
        ...state,
        rating: updatedRating,
      };

    default:
      return state;
  }
}
