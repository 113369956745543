import { Icon } from '@iconify/react';
import PreOrderInformation from 'components/partials/PreOrderInformation';
import TableReservationInformation from 'components/partials/TableReservationInformation';
import { getConvertedDate } from 'helper/date';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IReservation } from 'interfaces/models/reservation';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ListingSections from './ListingSections';
import { getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import { SPOT_RESERVATION_PREORDER } from 'constants/spot';
import { CART_REQUEST_EMPTY } from 'constants/carts';
import PreviouslyOrdered from './PreviouslyOrder';
import GroupedPromotions from './GroupedPromotion';
interface TableReservationProps {
  openPreOrderMenu: boolean;
  setOpenPreOrderMenu: (value: boolean) => void;
  categoryDisplayed: (categoryName: string) => void;
}
const TableReservation = (props: TableReservationProps) => {
  const { openPreOrderMenu, setOpenPreOrderMenu, categoryDisplayed } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { detail } = useAppSelector((state) => state.organization);
  const { outletId } = useParams();

  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';

  const existingReservationsString = localStorage.getItem('RESERVATION_INFO_LOCAL_GROUPED');
  const existingReservations = existingReservationsString ? JSON.parse(existingReservationsString) : [];

  const isFromConfirmed = localStorage.getItem('isFromConfirmedPage');
  const isFromConfirmedLocalObject = isFromConfirmed ? JSON.parse(isFromConfirmed) : null;

  const selectedPreOrderReservation = localStorage.getItem('SELECTED_RESERVATION');
  const selectedPreOrderReservationLocalObject = selectedPreOrderReservation
    ? JSON.parse(selectedPreOrderReservation)
    : null;

  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const [isPreOrderInfoOpen, setIsPreOrderInfoOpen] = useState(false);
  const [reservationType, setReservationType] = useState('');
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [reservationIndex, setReservationIndex] = useState<number | null>(null);

  const handleReservationType = (type: string, index?: number) => {
    setReservationType(type);
    if (index) {
      setReservationIndex(index);
    }
    setOpenBottomSheet(true);
  };

  useEffect(() => {
    if (isFromConfirmed) {
      setOpenBottomSheet(true);
      setReservationIndex(isFromConfirmedLocalObject.type ? isFromConfirmedLocalObject.index : null);
      setReservationType(isFromConfirmedLocalObject.type);
    }
    if (selectedPreOrderReservationLocalObject) {
      setOpenPreOrderMenu(true);
    }
  }, [reservationType]);

  return (
    <>
      {!openPreOrderMenu ? (
        <div className="reservation-container">
          {existingReservations.length !== 0 && existingReservations[0].outletId === outletId ? (
            <>
              {spot && spot.type == SPOT_RESERVATION_PREORDER && (
                <button
                  onClick={() => setIsPreOrderInfoOpen(true)}
                  className="btn primary"
                  style={{
                    backgroundColor: '#f4f4f4',
                    borderColor: brandColor,
                    color: brandColor,
                    boxShadow: `0px 4px 4px ${brandColor}40`,
                  }}
                >
                  {t('pre_order_food')}
                </button>
              )}
            </>
          ) : (
            <h2 className="reservation-h2">{t('you_dont_have_reservations')}</h2>
          )}
          <button
            onClick={() => handleReservationType('', 0)}
            className="btn primary"
            style={{
              backgroundColor: brandColor,
              borderColor: brandColor,
              boxShadow: `0px 4px 4px ${brandColor}40`,
            }}
          >
            {existingReservations.length > 0 ? t('book_new_table') : t('book_now')}
          </button>
          {existingReservations.length > 0 &&
            existingReservations.map((reservation: IReservation, idx: number) => {
              if (reservation.outletId === outletId)
                return (
                  <>
                    <div key={idx} className="reservation-box-container">
                      <div className="reservation-box">
                        <div className="reservation-box-dates-left-container">
                          <span className="reservation-box-dates-left-top">
                            {moment(`${reservation.ReserveDate}`).format('ddd')}
                          </span>
                          <span
                            className="reservation-box-dates-left-middle"
                            style={{
                              color: brandColor,
                            }}
                          >
                            {moment(`${reservation.ReserveDate}`).format('D')}
                          </span>
                          <span className="reservation-box-dates-left-bottom">
                            {moment(`${reservation.ReserveDate}`).format('MMM')}
                          </span>
                        </div>
                        <div className="reservation-box-details-right-container">
                          <div className="reservation-box-details-right-container-row">
                            <p
                              className="reservation-box-details-right-container-row-name"
                              style={{
                                color: brandColor,
                              }}
                            >
                              {reservation.CustomerName}
                            </p>
                            <span
                              onClick={() => handleReservationType('edit', idx + 1)}
                              className="reservation-box-details-right-container-row-edit"
                            >
                              <Icon icon="uil:edit" width={20} />
                            </span>
                          </div>

                          <span className="reservation-box-details-right-container-row-guest-number">
                            {/* Booked for {reservation.GuestNumber} Guest{reservation.GuestNumber > 1 && 's'} */}
                            {t('booked_for_number_guests', { counter: reservation.GuestNumber })}
                          </span>
                          <div className="reservation-box-details-right-container-row-date">
                            <div
                              className="reservation-box-details-right-container-row-date-icon"
                              style={{
                                color: brandColor,
                              }}
                            >
                              <span>
                                <Icon icon="majesticons:clock-line" width={16} />
                              </span>
                              <p
                                style={{
                                  fontSize: '15px',
                                }}
                              >
                                {moment(`${reservation.ReserveTime}`, 'HH:mm').format('h:mm A')}
                              </p>
                            </div>
                            <div
                              onClick={() => handleReservationType('cancel', idx + 1)}
                              style={{
                                paddingRight: '0.5rem',
                                color: 'red',
                              }}
                            >
                              {t('cancel')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
            })}
        </div>
      ) : (
        <>
          <div className="reservation-container" style={{ paddingBottom: '1vh' }}>
            <button
              onClick={() => {
                setOpenPreOrderMenu(false);
                dispatch({ type: CART_REQUEST_EMPTY });

                localStorage.removeItem('SELECTED_RESERVATION');
              }}
              className="btn primary"
              style={{
                backgroundColor: brandColor,
                borderColor: brandColor,
                boxShadow: `0px 4px 4px ${brandColor}40`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
            >
              <Icon icon="uil:arrow-left" width={30} />
              {t('back_to_reservations')}
            </button>
          </div>
          <PreviouslyOrdered categoryDisplayed={categoryDisplayed} />
          <GroupedPromotions categoryDisplayed={categoryDisplayed} />
          <ListingSections categoryDisplayed={categoryDisplayed} />
        </>
      )}

      {openBottomSheet && (
        <TableReservationInformation
          onClosed={() => setOpenBottomSheet(false)}
          type={reservationType}
          index={reservationIndex}
          isOnConfirmedPage={false}
        />
      )}
      {isPreOrderInfoOpen && (
        <PreOrderInformation
          existingReservations={existingReservations}
          onClosed={() => setIsPreOrderInfoOpen(false)}
          type={reservationType}
          setOpenPreOrderMenu={(param: boolean) => setOpenPreOrderMenu(param)}
        />
      )}
    </>
  );
};
export default TableReservation;
