import _, { capitalize, isEmpty, xor } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { FILTER_CHANGE_ON_CATEGORY } from '../../../constants/Filter';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux/index';
import { RootState } from '../../../store/configureStore';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { SPOT_QRTABLE } from 'constants/spot';

type Props = {
  setCategory: (param: string) => void;
  // setSelectedTab: (param: string) => void;
  selectedCategory: string;
  selectedTab: string;
  spot: any;
};
const FilterCategory: React.FunctionComponent<Props> = ({ setCategory, selectedCategory, selectedTab, spot }) => {
  const promotionGroup = useAppSelector((state) => state.menu.list);
  const groupMenus = _.groupBy(promotionGroup, 'promotion.name');
  const grouped = useAppSelector((state: RootState) => state.menu.grouped);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const categoryListRef = useRef<HTMLDivElement | null>(null); // Ref for the category list container

  useEffect(() => {
    if (categoryListRef.current) {
      const selectedCategoryElement = categoryListRef.current.querySelector(`[data-category="${selectedCategory}"]`);
      if (selectedCategoryElement instanceof HTMLElement) {
        const scrollLeft =
          selectedCategoryElement.offsetLeft -
          (categoryListRef.current.clientWidth - selectedCategoryElement.clientWidth) / 2;
        categoryListRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedCategory]);

  return (
    <>
      {spot && spot.type == SPOT_QRTABLE ? (
        <>
          {selectedTab === 'order' && (
            <div className="filter-list" ref={categoryListRef}>
              {groupMenus && groupMenus[0] && (
                <p
                  onClick={() => setCategory('special_deals')}
                  style={{
                    color: selectedCategory === 'special_deals' ? brandColor : '#a9a9a9',
                  }}
                  data-category="special_deals"
                >
                  Special deals
                </p>
              )}

              {grouped.map((item, index) => (
                <>
                  {item.category !== 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន' && (
                    <>
                      <div
                        style={{
                          padding: '10px',
                          borderBottom: selectedCategory === item.category ? '5px solid ' + brandColor : 'none',
                        }}
                      >
                        <p
                          key={index}
                          onClick={() => setCategory(item.category)}
                          style={{
                            color: selectedCategory === item.category ? brandColor : '#a9a9a9',
                          }}
                          data-category={item.category}
                        >
                          {item.category}
                        </p>
                      </div>
                    </>
                  )}
                </>
              ))}
            </div>
          )}
        </>
      ) : (
        <div className="filter-list" ref={categoryListRef}>
          {groupMenus && groupMenus[0] && (
            <p
              onClick={() => setCategory('special_deals')}
              style={{
                color: selectedCategory === 'special_deals' ? brandColor : '#a9a9a9',
              }}
              data-category="special_deals"
            >
              Special deals
            </p>
          )}

          {grouped.map((item, index) => (
            <>
              {item.category !== 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន' && (
                <>
                  <div
                    style={{
                      padding: '10px',
                      borderBottom: selectedCategory === item.category ? '5px solid ' + brandColor : 'none',
                    }}
                  >
                    <p
                      key={index}
                      onClick={() => setCategory(item.category)}
                      style={{
                        color: selectedCategory === item.category ? brandColor : '#a9a9a9',
                      }}
                      data-category={item.category}
                    >
                      {item.category}
                    </p>
                  </div>
                </>
              )}
            </>
          ))}
        </div>
      )}
    </>
  );
};

export default FilterCategory;
