import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { RootState } from '../../store/configureStore';
import { socialMedias } from 'interfaces/models/outlets';
import { MyImages } from 'cores/components';
import { Icon } from '@iconify/react';
import { generatePathHelpForHomePage, getSpotId } from 'helper/path';
import { useEffect } from 'react';
import { getOrganizationInformation } from 'actions/organization';
import { POST_TAB_REQUEST } from 'constants/tabs';
import { t } from 'i18next';
export const ContactUs = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { outletId, orgId } = useParams();
  const spotId = getSpotId();
  const { search } = useLocation();
  const { list } = useAppSelector((state: RootState) => state.outlets);
  const { detail } = useAppSelector((state) => state.organization);
  let details = list.find((i) => i.id === outletId);
  const socialMedias = details?.socialMedias;
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  useEffect(() => {
    if (orgId && outletId) {
      dispatch(getOrganizationInformation(orgId));
      dispatch({ type: POST_TAB_REQUEST, payload: null });
    }
  }, [orgId, outletId]);
  const showSocials = (name: string, url: string) => {
    switch (name) {
      case 'Facebook':
        return (
          <MyImages width="80px" src="/assets/icons/socials/facebook.png" onClick={() => window.open(url, '_blank')} />
        );
      case 'Instagram':
        return (
          <MyImages width="80px" src="/assets/icons/socials/instagram.png" onClick={() => window.open(url, '_blank')} />
        );
      case 'Tiktok':
        return (
          <MyImages width="80px" src="/assets/icons/socials/tik-tok.png" onClick={() => window.open(url, '_blank')} />
        );
      case 'Tripadvisor':
        return (
          <MyImages
            width="80px"
            src="/assets/icons/socials/tripAdvisor.png"
            onClick={() => window.open(url, '_blank')}
          />
        );
      case 'Google':
        return (
          <MyImages width="80px" src="/assets/icons/socials/google.png" onClick={() => window.open(url, '_blank')} />
        );
      case 'Telegram':
        return (
          <MyImages width="80px" src="/assets/icons/socials/telegram.png" onClick={() => window.open(url, '_blank')} />
        );

      default:
        return '';
    }
  };
  const backToHome = () => {
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };
  return (
    <div>
      <div className="contact-us-wrapper">
        <div className="contact-us-header">
          {spotId && (
            <span onClick={() => backToHome()}>
              <Icon icon="icon-park-outline:left" width={40} />
            </span>
          )}
          <p className="contact-us-header-title">{t('connect_with_us')}</p>
        </div>
        <div className="contact-us-content-wrapper">
          {socialMedias && socialMedias.length > 0 ? (
            <>
              {socialMedias.map((item: socialMedias, index: number) => {
                return (
                  <div key={index} className="contact-us-cards ">
                    {showSocials(item.name, item.url)}
                    <div
                      className="contact-us-cards-content"
                      style={{
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div className="contact-us-follow-us-on">
                        <p style={{ maxWidth: '37vw' }}>
                          {['Facebook', 'Telegram', 'Tiktok', 'Instagram'].includes(item.name)
                            ? t('follow_us')
                            : t('leave_a_review')}{' '}
                          {item.name}
                        </p>
                      </div>
                      <div className="contact-us-follow-wrapper">
                        <div
                          onClick={() => window.open(item.url, '_blank')}
                          style={{
                            backgroundColor: brandColor,
                            boxShadow: `0px 4px 4px ${brandColor}40`,
                          }}
                          className="contact-us-follow-button"
                        >
                          {['Facebook', 'Telegram', 'Tiktok', 'Instagram'].includes(item.name)
                            ? t('follow')
                            : t('review')}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
