import { CART_REQUEST_EMPTY } from 'constants/carts';
import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getLatestOrder } from '../../helper/order-summary';
import { generatePathHelpForHomePage, getSpotId } from '../../helper/path';
import { useAppDispatch, useAppSelector } from '../../hooks/redux/index';
import { RootState } from '../../store/configureStore';
import { PaymentMethods, socialMedias } from 'interfaces/models/outlets';
import { MyImages } from 'cores/components';
import ChargesSection from 'components/partials/ChargesSection';
import { FooterLogo } from 'components/partials/FooterLogo';
import DownloadReceipt from 'cores/components/DownloadReceipt';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { SPOT_DINE_IN, SPOT_QRTABLE } from 'constants/spot';
import { getSpotById } from 'helper/spot';
import { PaymentType } from 'enums/payment-type';
import { POST_TAB_REQUEST } from 'constants/tabs';

export const ThankYourForOrdering = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const route = useParams();
  const { outletId, orgId } = route;
  const list = getLatestOrder();
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const filteredList = list.filter((item) => item.outletId === outletId && item.outletSpotId === spotId);
  const { t } = useTranslation();
  // Calculate the total of all items
  const discountedPriceSum =
    filteredList.length > 0
      ? filteredList.reduce((total, item) => {
          const price = item.discountedPrice && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
          return total + price;
        }, 0)
      : 0;
  const { detail } = useAppSelector((state: RootState) => state.organization);
  const { socialMedias } = useAppSelector((state: RootState) => state.outlets.list[0]);
  const { paymentMethods } = useAppSelector((state: RootState) => state.outlets.list[0]);
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const paymentMethod: string = localStorage.getItem('paymentMethod') ?? 'KHQR';
  const clickMakeOtherOrder = () => {
    dispatch({ type: CART_REQUEST_EMPTY });
    dispatch({ type: POST_TAB_REQUEST, payload: 'order' });
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };

  const showSocials = (name: string, url: string) => {
    switch (name) {
      case 'Facebook':
        return <MyImages width="30px" src="/assets/icons/facebook.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Instagram':
        return <MyImages width="30px" src="/assets/icons/instagram.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Tripadvisor':
        return <MyImages width="30px" src="/assets/icons/tripadvisor.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Google':
        return <MyImages width="30px" src="/assets/icons/google-fill.svg" onClick={() => window.open(url, '_blank')} />;
        break;
      case 'Tiktok':
        return <MyImages width="30px" src="/assets/icons/telegram.svg" onClick={() => window.open(url, '_blank')} />;

      case 'Telegram':
        return <MyImages width="30px" src="/assets/icons/tiktok.svg" onClick={() => window.open(url, '_blank')} />;

      default:
        return '';
        break;
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname + window.location.search}`,
      title: ` ${detail?.name} - QR Table Order Sumary`,
    });
    return () => {
      dispatch({ type: CART_REQUEST_EMPTY });
    };
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-card">
          <div className="restaurant-profile">
            <img loading="lazy" src={detail?.image.imageUrl} alt={detail?.image.imageUrl} />
          </div>
          <section className="section" style={{ paddingTop: '48px' }}>
            <h2 className="title">
              {spot && spot.type != SPOT_QRTABLE ? t('thank_you_for_staying') : t('thank_you_for_dining')}
            </h2>
            <div className="date mt-16">{moment().format('Do MMMM YYYY - hh:mm')}</div>

            <div className="socials mt-20">
              {socialMedias && socialMedias.length > 0 ? (
                <>
                  <span>{t('connect_with_us')}:</span>
                  <div className="flex-row column-gap-10 center">
                    {socialMedias.map((item: socialMedias, index: number) => {
                      return (
                        <div key={index} className="mt-10">
                          {showSocials(item.name, item.url)}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </section>
          <section className="section">
            {spot && spot.type === 0 && (
              <div className="flex-row center">
                {paymentMethods &&
                  paymentMethods.length > 0 &&
                  paymentMethods.map((item: PaymentMethods, index: number) => {
                    return (
                      // <div key={index} className="mt-10">
                      //   {item.name === 'KHQR' ? <MyImages width="120px" src={item.icon.imageUrl} /> : ''}
                      // </div>
                      <div key={index} className="mt-10">
                        {item.name === 'KHQR' ? (
                          <>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <p className="bold">{'KHQR'}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '10px' }}>
                              <MyImages width="120px" src={item.icon.imageUrl} />
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountName}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '5px' }}>
                              <p className="bold">{item.accountNumber}</p>
                            </div>
                            <div className="flex-row center" style={{ marginBottom: '30px' }}>
                              <p className="bold">$ {discountedPriceSum.toFixed(2)}</p>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
            <div className="flex-row space-between total-amount-text">
              <div>{t('order_details')}</div>
            </div>
            <div className="flex-col pt-23">
              {filteredList.map((order, orderIndex) => (
                <div key={orderIndex} className="order-container">
                  {order.items.map((item, itemIndex) => {
                    const basePrice =
                      item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;

                    // Calculate the total price by considering quantity and addons
                    const totalPrice =
                      (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
                    return (
                      <div
                        key={itemIndex}
                        className="flex-row space-between"
                        style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                      >
                        <div className="col">
                          <div>
                            {item.quantity}x {item.name}
                          </div>
                          {item.addOns?.map((a, index) => (
                            <div key={index}>- {a.name}</div>
                          ))}
                        </div>
                        <div style={{ whiteSpace: 'nowrap' }}>$ {totalPrice.toFixed(2)}</div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <ChargesSection totalPrice={Number(discountedPriceSum.toFixed(2))} />
            {spot && spot.type === 0 && (
              <div className="flex-row space-between pt-23 price-text">
                <div>{t('payment_method')}</div>
                {/* <div>{t('payment_method')}</div> */}

                <div>{PaymentType[paymentMethod as keyof typeof PaymentType]}</div>
              </div>
            )}

            <div className="mt-33">
              {/* <img width={'100%'} src="/assets/images/ad.svg" alt="" /> Temporarily Removed */}
              <div
                onClick={() => clickMakeOtherOrder()}
                className="btn primary mt-35"
                style={{
                  backgroundColor: brandColor,
                  borderColor: brandColor,
                  boxShadow: `0px 4px 4px ${brandColor}40`,
                }}
              >
                {t('place_another_order')}
              </div>
              <div className="flex-col pt-23">
                <DownloadReceipt />
              </div>
            </div>
          </section>
        </div>
        <div style={{ marginTop: '20px', paddingBottom: '20px' }}>
          <FooterLogo />
        </div>
      </div>
    </div>
  );
};
