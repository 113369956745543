export enum PaymentType {
  CASH = 'Cash',
  CC = 'Credit Card',
  QR = 'KHQR',
  ABA = 'ABA Payway',
  BILL = 'Bill to room',
  CASHWITHNOTE = 'Cash with Note',
  PAYONLINE = 'Pay Online',
  VISA_MASTER = 'Visa/Master Card',
  ABAAKHPP = 'ABA Pay',
  ACLBKHPP = 'Acleda Bank',
  SBPLKHPP = 'Sathapana Bank',
}

export enum PaymentName {
  CASH = 'Cash',
  CC = 'Credit Card',
  QR = 'KHQR',
  ABA = 'ABA Payway',
  BILL = 'Bill to room',
  CASHWITHNOTE = 'Cash with Note',
  PAYONLINE = 'Pay Online',
  VISA_MASTER = 'Visa/Master Card',
  ABAAKHPP = 'ABA Pay',
  ACLBKHPP = 'Acleda Bank',
  SBPLKHPP = 'Sathapana iBanking',
}
