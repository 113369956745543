import { IOutlets } from "interfaces/models/outlets";

import { PayloadAction } from "@reduxjs/toolkit";

import { GET_OUTLET_REQUEST_SUCCESS } from "../../constants/outlets";

type StateType = {
  list: IOutlets[];
};

const initialState = {
  list: [],
};

export default function outlets(state: StateType = initialState, action: PayloadAction<IOutlets[]>): StateType {
  switch (action.type) {
    case GET_OUTLET_REQUEST_SUCCESS:
      return {
        ...state,
        list: action.payload ?? [],
      };
    default:
      return state;
  }
}
