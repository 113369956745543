export const CREATE_ORDER_REQUEST = '@@order/CREATE_ORDER_REQUEST';
export const CREATE_ORDER_REQUEST_SUCCESS = '@@order/CREATE_ORDER_REQUEST_SUCCESS';
export const ORDER_UPDATE_ITEM = '@@order/ORDER_UPDATE_ITEM';
export const ORDER_TYPES = {
  DINE_IN: 0,
  PICKUP: 1,
  DELIVERY: 2,
  RESERVATION: 3,
  PREORDER: 4,
  MENU_ONLY: 5,
  HOTEL_ROOM_SERVICE: 6,
  CINEMA_SEAT: 7,
  CINEMA_COUNTER: 8,
  CINEMA_HALL: 9,
};
