import { useAppSelector } from 'hooks/redux';
import { IGroupMenu, OutletMenuItem } from 'interfaces/models/menu';
import { RootState } from 'store/configureStore';
import ThumbnailLandscape from './thumbnail-landscape';
import ThumbnailHorizontal from './thumbnail-horizontal';
import { t } from 'i18next';

interface PreviouslyOrderedProps {
  categoryDisplayed: (categoryName: string) => void;
}
const PreviouslyOrdered: React.FC<PreviouslyOrderedProps> = ({ categoryDisplayed }) => {
  const groupMenu = useAppSelector((state: RootState) => state.menu.grouped);
  const handleItemClick = (item: IGroupMenu) => {
    localStorage.setItem('menuListingScrollPosition', window.scrollY.toString());
  };
  const sortedGroupMenu =
    groupMenu &&
    groupMenu.length > 0 &&
    useAppSelector((state: RootState) => state.menu.grouped).sort((a, b) => {
      if (a.category !== 'Others' && a.category !== 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន') {
        if (a.menu.length > 0 && b.menu.length > 0 && a.menu[0].itemCategory && b.menu[0].itemCategory)
          return a.menu[0].itemCategory.sequence - b.menu[0].itemCategory.sequence;
      }
      return 0;
    });

  return (
    <div className="menu-content">
      <div className="all-time-favourite-wrapper">
        {sortedGroupMenu &&
          sortedGroupMenu.map((item: IGroupMenu, index: number) => (
            <>
              {
                // category.length === 0 &&
                item.category && item.category == 'Previously Ordered - ធ្លាប់បញ្ជាទិញពីមុន' && (
                  <div key={index} onClick={() => handleItemClick(item)}>
                    <div className="all-time-filter">
                      <div className="text-all-time-category">{t("previously_ordered")}</div>
                    </div>

                    {item.menu.length > 0 &&
                    item.menu.every((menu) => menu.itemCategory && menu.itemCategory.type === 'Landscape') ? (
                      <>
                        <div className="thumbnail-landscape-flex">
                          {item.menu.map((menu: OutletMenuItem, index: number) => (
                            <div key={index}>
                              <ThumbnailLandscape key={index} item={menu} index={index} />
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="thumbnail-horizontal-flex">
                          {item.menu.map((menu: OutletMenuItem, index: number) => (
                            <div key={index}>
                              {!menu?.isOutOfStock && <ThumbnailHorizontal key={index} item={menu} />}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )
              }
            </>
          ))}
      </div>
    </div>
  );
};
export default PreviouslyOrdered;
