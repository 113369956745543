import { PayloadAction } from '@reduxjs/toolkit';
import { SET_LANGUAGE } from 'constants/localization';

type StateType = {
  lang: string;
};

const initialState = {
  lang: 'en',
};

export default function setLanguage(state: StateType = initialState, action: PayloadAction<string>): StateType {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        lang: action.payload ?? 'en',
      };
    default:
      return state;
  }
}
