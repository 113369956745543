import { Oval } from 'react-loader-spinner';
type TypProps = {
  brandColor?: string;
};
export function LoadingSpinner({ brandColor }: TypProps) {
  return (
    <Oval
      height={20}
      width={20}
      color="#FFFF"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={brandColor ? brandColor : '#FFB26B'}
      strokeWidth={5}
      strokeWidthSecondary={5}
    />
  );
}
