import { PayloadAction } from '@reduxjs/toolkit';

import { GET_ORGANIZATION_REQUEST_SUCCESS } from '../../constants/organization';
import { IOrganization } from '../../interfaces/models/organization';

type StateType = {
  detail?: IOrganization | null;
};

const initialState = {
  detail: null,
};

export default function organization(state: StateType = initialState, action: PayloadAction<IOrganization>): StateType {
  switch (action.type) {
    case GET_ORGANIZATION_REQUEST_SUCCESS:
      return {
        ...state,
        detail: action.payload,
      };
    default:
      return state;
  }
}
