import * as _ from 'lodash';
import moment from 'moment';

import { Item } from '../interfaces/models/carts';
import { CreateOrderResponse } from '../interfaces/models/order';
import { IOutlets } from '../interfaces/models/outlets';
import { getSelectorByKey } from './selector';

export function getLatestOrder(): CreateOrderResponse[] {
  const { list } = getSelectorByKey('orders');
  return _.chain(list).filter(filterOrder).orderBy(['createdAt'], 'desc').value();
}

const filterOrder = (item: CreateOrderResponse) => {
  const { list } = getSelectorByKey('outlets');
  const outletIds = list.map((item: IOutlets) => item.id);
  return moment(item.createdAt).isSame(new Date(), 'day') && outletIds.includes(item.outletId);
};

export function getLatestMenuForReviews(): Item[] {
  const orders = getLatestOrder();
  const { list, rating } = getSelectorByKey('menu');
  if (!orders.length) {
    return [];
  }

  const menus: Item[] = [];
  const allOrderMenu: Item[] = [];

  _.map(orders, (element: CreateOrderResponse) => allOrderMenu.push(...element.items));

  const groupMenus = _.groupBy(allOrderMenu, 'menuId');
  _.keys(groupMenus).forEach((key: string) => {
    const menu = _.get(groupMenus, [key, 0], null);
    const hasRating = _.findIndex(rating, { menuId: menu?.menuId });

    if (menu && hasRating === -1) {
      const imageUrl = _.find(list, { id: menu.menuId })?.image.imageUrl;
      const disabledRating = _.find(list, { id: menu.menuId })?.disableRating ?? false;
      menu['rating'] = _.find(list, { id: menu.menuId })?.rating.value ?? 0;
      menu['imageUrl'] = imageUrl ?? '';
      if (disabledRating) return;
      menus.push(menu);
    }
  });
  const orderMenus = _.orderBy(menus, 'rating', 'asc').splice(0, 2);
  return orderMenus;
}

export function storePreviousOrder(items: Item[]) {
  // Retrieve previously stored items from local storage
  const storedItems: string | null = localStorage.getItem('PREVIOUS_ORDERED_ITEMS');
  const parsedItems: Item[] = storedItems ? JSON.parse(storedItems) : [];

  const newItems: Item[] = [...parsedItems];

  // Add new items to the array if they don't already exist
  items.forEach((item) => {
    const existingIndex = newItems.findIndex((existingItem) => existingItem.menuId === item.menuId);
    if (existingIndex === -1) {
      if (newItems.length >= 10) {
        // Replace the oldest item with the new one if the count exceeds 10
        newItems.shift();
      }
      newItems.push(item);
    }
  });

  // Save the new items to local storage
  localStorage.setItem('PREVIOUS_ORDERED_ITEMS', JSON.stringify(newItems));
}

export const calculateOrderTotalPrice = (order: CreateOrderResponse) => {
  let orderTotalPrice = 0;

  order.items.forEach((item) => {
    let basePrice = item.discountedPrice !== 0 ? item.discountedPrice : item.price;
    basePrice = basePrice !== null ? basePrice : 0;

    let itemTotalPrice = basePrice;

    if (item.addOns && item.addOns.length > 0) {
      const addOnsTotalPrice = item.addOns.reduce((addOnTotal, addOn) => addOnTotal + addOn.price, 0);
      itemTotalPrice += addOnsTotalPrice * (item.quantity || 1);
    }

    orderTotalPrice += itemTotalPrice;
  });

  return orderTotalPrice;
};

export const calculateTotalSumOfOrders = (orders: CreateOrderResponse[]) => {
  let totalSum = 0;

  orders.forEach((order) => {
    const orderTotalPrice = calculateOrderTotalPrice(order);
    totalSum += orderTotalPrice;
  });

  return totalSum;
};
