import { useAppDispatch } from 'hooks/redux';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { getLatestOrder } from '../../../helper/order-summary';
import { generatePathHelpForHomePage, getSpotId } from '../../../helper/path';
import AskForBilledPayment from '../AskForBillPayment';
import CallForWaiter from '../CallForWaiter';

import { SPOT_CINEMA_COUNTER, SPOT_CINEMA_SEAT, SPOT_MENU_ONLY, SPOT_PICKUP_TYPE, SPOT_QRTABLE } from 'constants/spot';
import { getSpotById } from 'helper/spot';
import { FooterLogo } from '../FooterLogo';
import LanguageSetting from '../LanguageSetting';
import { useTranslation } from 'react-i18next';
interface TypeProps {
  onClosed: () => void;
}

const Navigation = (props: TypeProps) => {
  const { onClosed } = props;
  const { search: queryParam } = useLocation();
  const { search } = useLocation();
  const { outletId, orgId } = useParams();
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const refBottomSheet = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [openBillPayment, setOpenBillPayment] = useState(false);
  const [callForWaiter, setCallForWaiter] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const order = getLatestOrder();
  const { t } = useTranslation();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (refBottomSheet.current && !refBottomSheet.current.contains(e.target)) {
      onClosed();
    }
  };

  function callingWaiterClicked() {
    setCallForWaiter(true);
    setOpenBillPayment(false);
    // if (spotId && outletId) {
    //   // dispatch(callingWaiter(spotId, outletId, { type: 'Question', message: '', paymentType: '' }));
    // }
  }
  function languageClicked() {
    setOpenLanguage(true);
    // setOpenBillPayment(false);
    // setCallForWaiter(false);
    // onClosed();
  }
  function goToContactUs() {
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/contact-us', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  }
  function askForBillPayment() {
    if (order.length) {
      setOpenBillPayment(!openBillPayment);
      setCallForWaiter(false);
    }
  }
  const outletFeedback: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    localStorage.setItem('OUTLET_FEEDBACK', '1');
  };
  useEffect(() => {
    localStorage.setItem('OUTLET_FEEDBACK', '0');
  }, []);
  return (
    <>
      {callForWaiter && <CallForWaiter onClosed={onClosed} outletId={outletId ?? ''} spotId={spotId ?? ''} />}
      {openBillPayment && <AskForBilledPayment onClose={() => setOpenBillPayment(false)} />}
      {openLanguage && <LanguageSetting onClose={() => setOpenLanguage(false)} />}
      {!openBillPayment && !callForWaiter && !openLanguage && (
        <div className="bottom-sheet-wrapper">
          <div className="bottom-sheet-content padding" ref={refBottomSheet}>
            <div className="navigation-wrapper">
              {spotId && (
                <>
                  {spot.type != SPOT_MENU_ONLY && (
                    <div className="navigation-item navigation-title">
                      <Link to={'order-summary' + queryParam}>{t('order_history')}</Link>
                    </div>
                  )}
                  <div className="navigation-item navigation-title">
                    <Link to={'rating-menu' + queryParam} onClick={outletFeedback}>
                      {t('submit_feedback')}
                    </Link>
                    {/* <p onClick={() => outletFeedback()}>Submit Feedback</p> */}
                  </div>
                </>
              )}
              {spot.type == SPOT_QRTABLE ? (
                <>
                  {' '}
                  <div className="navigation-item navigation-title" onClick={callingWaiterClicked}>
                    {t('call_for_service')}
                  </div>
                  {order === null || order.length == 0 ? null : (
                    <div className="navigation-item navigation-title" onClick={askForBillPayment}>
                      {t('call_for_bill')}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {spot.type == SPOT_CINEMA_SEAT && (
                    <div className="navigation-item navigation-title" onClick={callingWaiterClicked}>
                      {t('call_for_service')}
                    </div>
                  )}
                </>
              )}
              <div className="navigation-item navigation-title" onClick={languageClicked}>
                {t('language')}
              </div>
              <div className="navigation-item navigation-title" onClick={goToContactUs}>
                {t('social_media')}
              </div>
              <div className="navigation-item navigation-title">
                <FooterLogo />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
