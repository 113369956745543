import { ISpot } from "interfaces/models/spot";

import { PayloadAction } from "@reduxjs/toolkit";

import { GET_SPOT_REQUEST_SUCCESS } from "../../constants/spot";

type StateType = {
  list: ISpot[];
};

const initialState = {
  list: [],
};

export default function spot(state: StateType = initialState, action: PayloadAction<ISpot[]>): StateType {
  switch (action.type) {
    case GET_SPOT_REQUEST_SUCCESS:
      return {
        ...state,
        list: action.payload ?? [],
      };
    default:
      return state;
  }
}
