export const SET_CATEGORY_LIST = '@@category/SET_CATEGORY_LIST';
export const SET_OUTLET_CATEGORY_LIST = '@@category/SET_OUTLET_CATEGORY_LIST';
export const CATEGORY_LIST = [
  {
    id: 1,
    name: "All Time's Favorites",
  },
  {
    id: 2,
    name: 'Entree',
  },
  {
    id: 3,
    name: 'Steak And Chop',
  },
  {
    id: 4,
    name: 'Soup and Salads',
  },
  {
    id: 5,
    name: 'Sides Dishes',
  },
  {
    id: 6,
    name: 'Drink',
  },
  {
    id: 7,
    name: 'Desserts',
  },
];
