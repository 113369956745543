import _, { sumBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getLatestOrder } from '../../helper/order-summary';
import { generatePathHelpForHomePage, getSpotId } from '../../helper/path';
import { useAppSelector } from '../../hooks/redux/index';
import { RootState } from '../../store/configureStore';
import { FooterLogo } from 'components/partials/FooterLogo';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import TableReservationInformation from 'components/partials/TableReservationInformation';
import ReactGA from 'react-ga4';
import ChargesSection from 'components/partials/ChargesSection';
import { Item } from 'interfaces/models/carts';

export const PreOrderSumary = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const route = useParams();
  const { outletId, orgId } = route;
  const { t } = useTranslation();
  const latestOrder = getLatestOrder();
  const { detail } = useAppSelector((state: RootState) => state.organization);
  const { list } = useAppSelector((state: RootState) => state.outlets);
  const spotId = getSpotId();
  const filteredList = latestOrder.filter((item) => item.outletId === outletId && item.outletSpotId === spotId);
  const tableReservationInfoLocal = localStorage.getItem('RESERVATION_INFO_LOCAL');
  const tableReservationInfoLocalObject = tableReservationInfoLocal ? JSON.parse(tableReservationInfoLocal) : null;
  const existingReservationsString = localStorage.getItem('RESERVATION_INFO_LOCAL_GROUPED');
  const existingReservations = existingReservationsString ? JSON.parse(existingReservationsString) : [];
  const existingPreOrder = localStorage.getItem('PREORDER_GROUPED');
  const existingPreOrderLocalObject = existingPreOrder ? JSON.parse(existingPreOrder) : [];
  const brandColor =
    detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [reservationType, setReservationType] = useState('');
  const [reservationIndex, setReservationIndex] = useState<number | null>(null);
  const name = outletDetails()?.name;
  const location = outletDetails()?.location;
  const totalOrderAmount = latestOrder[0].items.reduce((totalAmount, item) => {
    const basePrice = item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
    const itemPrice = (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
    const total = totalAmount + itemPrice;
    return total;
  }, 0);
  function outletDetails() {
    let details = list.find((i) => i.id === filteredList[0].outletId);
    return details;
  }
  const handleReservation = (type: string) => {
    localStorage.removeItem('SELECTED_RESERVATION');
    if (type !== 'back') {
      localStorage.setItem(
        'isFromConfirmedPage',
        JSON.stringify({
          isConfirmed: true,
          index: existingReservations.length,
          type: type,
        })
      );
      localStorage.setItem('isBack', 'true');
    }
    if (outletId && orgId) {
      const path = generatePathHelpForHomePage('/', { outletId, orgId }, search);
      navigate(path.pathname + path.search);
    }
  };
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname + window.location.search}`,
      title: `${detail?.name} -  ${name}/Tabel Reservation Confirmation`,
    });
  }, []);

  return (
    <>
      <div>
        <div className="content-wrapper">
          <div className="content-card">
            <div className="restaurant-profile">
              <img loading="lazy" src={detail?.image.imageUrl} alt={detail?.image.imageUrl} />
            </div>
            <section className="section" style={{ paddingTop: '48px' }}>
              <h2 className="title">{t('pre_order_confirmed')}</h2>
              {tableReservationInfoLocal && (
                <div
                  className="date"
                  style={{
                    paddingTop: '20px',
                  }}
                >
                  {t('your_pre_order_has_been_added')}{' '}
                  <span style={{ fontWeight: '450' }}>
                    {moment(
                      `${tableReservationInfoLocalObject.ReserveDate} ${tableReservationInfoLocalObject.ReserveTime}`
                    ).format('MMMM Do YYYY, h:mm:ss A')}
                  </span>{' '}
                  {t('and_a_table_for')}{' '}
                  <span style={{ fontWeight: '450' }}> {tableReservationInfoLocalObject.GuestNumber}</span>.
                </div>
              )}
            </section>
            <section className="section">
              <div className="flex-row space-between total-amount-text">
                <div>{t('reservation_details')}</div>
                {tableReservationInfoLocalObject.type === '' && (
                  <span
                    onClick={() => handleReservation('edit')}
                    style={{
                      paddingRight: '0.5rem',
                      color: '#a9a9a9',
                    }}
                  >
                    <Icon icon="uil:edit" width={20} />
                  </span>
                )}
              </div>
              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('reservation_place')}: </div>
                  <div style={{ fontWeight: '500' }}>{name}</div>
                </div>
              </div>
              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('reservation_address')}: </div>
                  <div style={{ fontWeight: '500' }}>
                    {location?.address}, {location?.city}
                  </div>
                </div>
              </div>
              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('dining_time')}: </div>
                  <div style={{ fontWeight: '400', fontSize: '14px' }}>
                    {moment(
                      `${tableReservationInfoLocalObject.ReserveDate} ${tableReservationInfoLocalObject.ReserveTime}`
                    ).format('lll')}
                  </div>
                </div>
              </div>
              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('customer_name')}: </div>
                  <div style={{ fontWeight: '400', fontSize: '15px' }}>
                    {tableReservationInfoLocalObject.CustomerName}
                  </div>
                </div>
              </div>
              <div className="flex-col pt-16">
                <div className="flex-row space-between" style={{ paddingBottom: 10, paddingTop: 10 }}>
                  <div className="col">{t('number_of_guest')}: </div>
                  <div style={{ fontWeight: '400', fontSize: '15px' }}>
                    {tableReservationInfoLocalObject.GuestNumber}
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="flex-row space-between total-amount-text">
                <div>{t('pre_order_details')}</div>
              </div>
              {/* {existingPreOrderLocalObject.map((item: any, index: number) => {
                if (item.id === tableReservationInfoLocalObject.id)
                  return (
                    <div className="flex-col pt-16" key={index}>
                      <div className="flex-col pt-23">
                        <div key={index} className="order-container">
                          {item.orderedItem.map((item: any, itemIndex: number) => {
                            const basePrice =
                              item.discountedPrice !== null && item.discountedPrice !== 0
                                ? item.discountedPrice
                                : item.price;
                            const totalPrice =
                              (basePrice + (item.addOns?.reduce((acc: any, addon: any) => acc + addon.price, 0) || 0)) *
                              item.quantity;
                            return (
                              <div
                                key={itemIndex}
                                className="flex-row space-between"
                                style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                              >
                                <div className="col">
                                  <div>
                                    {item.quantity}x {item.name}
                                  </div>
                                  {item.addOns?.map((a: any, index: number) => (
                                    <div key={index}>- {a.name}</div>
                                  ))}
                                </div>
                                <div style={{ whiteSpace: 'nowrap' }}>$ {totalPrice.toFixed(2)}</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
              })} */}
              <div className="flex-col pt-23">
                {latestOrder[0].items.map((item: Item, index: number) => {
                  const basePrice =
                    item.discountedPrice !== null && item.discountedPrice !== 0 ? item.discountedPrice : item.price;
                  const totalPrice =
                    (basePrice + (item.addOns?.reduce((acc, addon) => acc + addon.price, 0) || 0)) * item.quantity;
                  return (
                    <div
                      key={index}
                      className="flex-row space-between"
                      style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                    >
                      <div className="col">
                        <div>
                          <span style={{ fontWeight: '600' }}>{item.quantity}X</span> {item.name}
                        </div>
                        {item.addOns?.map((addon, index) => (
                          <div key={index}>- {addon.name}</div>
                        ))}
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }}>$ {totalPrice.toFixed(2)}</div>
                    </div>
                  );
                })}
              </div>
              <ChargesSection totalPrice={totalOrderAmount} />
            </section>
            <section className="section">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <h4
                  style={{
                    fontWeight: '400',
                    fontSize: '18px',
                    color: '#909296',
                    alignItems: 'center',
                    display: 'flex',
                    gap: '5px',
                  }}
                >
                  <Icon icon="system-uicons:cross-circle" width={25} />
                  <span>{t('cancel_policy')}</span>
                </h4>

                <div
                  className="date"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {t('if_you_cant_make_it_to')}
                </div>
                <div
                  onClick={() => handleReservation('back')}
                  style={{
                    fontWeight: '400',
                    fontSize: '14px',
                    color: '#909296',
                    alignItems: 'center',
                    display: 'flex',
                    marginTop: '15px',
                    gap: '5px',
                  }}
                >
                  <Icon icon="icon-park-solid:back" />
                  <span>{t('return_to_home')}</span>
                </div>
              </div>
            </section>
          </div>
          <div style={{ marginTop: '20px' }}>
            <FooterLogo />
          </div>
        </div>
      </div>
      {openBottomSheet && (
        <TableReservationInformation
          onClosed={() => setOpenBottomSheet(false)}
          type={reservationType}
          index={reservationIndex}
          isOnConfirmedPage={true}
        />
      )}
    </>
  );
};
