import { GET_SPOT_REQUEST } from "constants/spot";
import { createListAction } from "generics/action-creator";
import { Action, Dispatch } from "redux";
import { fetchingAllSpotByOutletId } from "services/spot";

import { ISpot } from "../../interfaces/models/spot";
import { RootState } from "../../store/configureStore";

export const getAllSpot =
  (outletId: string): any =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const options = {
      serviceMethod: fetchingAllSpotByOutletId,
      dispatch,
      getState,
      requestName: GET_SPOT_REQUEST,
    };

    return createListAction<ISpot[]>(options, outletId);
  };
