import { useAppSelector } from 'hooks/redux';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { InputComponent } from 'cores/components';
import { IPickUpInfo } from 'interfaces/models/pickupInfo';
import { Oval } from 'react-loader-spinner';
import { IOutlets } from 'interfaces/models/outlets';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../LoadingSpinner';
import { getSpotId } from 'helper/path';
import { getSpotById } from 'helper/spot';
import { SPOT_CINEMA_HALL } from 'constants/spot';
import { t } from 'i18next';
type CinemaInfoProps = {
  onClosed: () => void;
  brandColor: string;
  cinemaInfo: any;
  setCinemaInfo: (cinemaInfo: any) => void;
  onSubmitCinemaInfo: () => void;
};
const CinemaCounterInformation = ({
  onClosed,
  brandColor,
  cinemaInfo,
  setCinemaInfo,
  onSubmitCinemaInfo,
}: CinemaInfoProps) => {
  const refBottomSheet = useRef<HTMLDivElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const spotId = getSpotId();
  const spot = getSpotById(spotId);
  const spotName = spot ? spot.spotName : '';
  console.log('🚀 ~ spot:', spot);
  useEffect(() => {
    setCinemaInfo({ ...cinemaInfo, hallNumber: spotName });

    document.body.style.overflow = 'hidden';
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleClickOutside = (e: any) => {
    if (refBottomSheet.current && !refBottomSheet.current.contains(e.target)) {
      onClosed();
    }
  };

  return (
    <>
      <div className="bottom-sheet-wrapper">
        <div className="bottom-sheet-content padding" ref={refBottomSheet}>
          <div className="navigation-wrapper pickup-information">
            <div className="title mb-25">{t('seat_info')}</div>
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="label">
                  {t('hall_number')} <span className="error">*</span>
                </div>
              </div>
              {spot.type == SPOT_CINEMA_HALL ? (
                <>
                  <InputComponent value={cinemaInfo.hallNumber} name="seat_number" disabled={true} />
                </>
              ) : (
                <>
                  <select
                    className="select"
                    onChange={(e) => setCinemaInfo({ ...cinemaInfo, hallNumber: e.target.value })}
                  >
                    <option value="" disabled selected>
                      {t('please_choose_hall')}
                    </option>
                    <option value="Hall 1">{t('hall')} 1</option>
                    <option value="Hall 2">{t('hall')} 2</option>
                    <option value="Hall 3">{t('hall')} 3</option>
                    <option value="Hall 4">{t('hall')} 4</option>
                    <option value="Hall 5">{t('hall')} 5</option>
                    <option value="Hall 6">{t('hall')} 6</option>
                    <option value="Hall 7">{t('hall')} 7</option>
                    <option value="Hall 8 (VIP)">{t('hall')} 8 (VIP)</option>
                  </select>
                  {submitted && cinemaInfo.hallNumber === '' ? (
                    <span className="error"> {t('please_choose_hall_number')}</span>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
            <div className="pickup-information-item row-item">
              <div className=" flex-row space-between">
                <div className="label">
                  {t('seat_number')} <span className="error">*</span>
                </div>
              </div>

              <InputComponent
                value={cinemaInfo.seatNumber}
                name="seat_number"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCinemaInfo({ ...cinemaInfo, seatNumber: e.target.value })
                }
                placeholder="Example: Seat A11"
              />
              {submitted && cinemaInfo.seatNumber === '' ? (
                <span className="error"> {t('please_input_seat_number')}</span>
              ) : (
                ''
              )}
            </div>
            <div className="pickup-information-item row-item">
              <div className="flex-row space-between">
                <div className="label">
                  {t('mobile_number')}{' '}
                  <span
                    style={{
                      fontSize: '13px',
                      color: '#7b7b7b',
                    }}
                  >
                    {t('optional')}
                  </span>
                  {cinemaInfo.phoneNumber.length != 0 && cinemaInfo.phoneNumber.length < 8 ? (
                    <span className="error">{t('enter_valid_phone_number')}</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="flex-row space-between">
                <div className="phone-country-code">
                  <InputComponent
                    value=""
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setCinemaInfo({ ...cinemaInfo, phoneNumber: e.target.value })
                    }
                    placeholder="(855)"
                    disabled={true}
                  />
                </div>

                <InputComponent
                  name="phonenumber"
                  value={cinemaInfo.phoneNumber}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    e.target.value.length <= 9 ? setCinemaInfo({ ...cinemaInfo, phoneNumber: e.target.value }) : ''
                  }
                  placeholder="12 123 123"
                  type="number"
                />
              </div>
            </div>
            <div
              className="btn primary pd-12 flex-row space-between submit-order-pickup"
              onClick={() => {
                setSubmitted(true);

                if (cinemaInfo.hallNumber && cinemaInfo.seatNumber) {
                  onSubmitCinemaInfo();
                  setLoading(true);
                }
              }}
              style={{ backgroundColor: brandColor, boxShadow: `0px 4px 4px ${brandColor}40`, borderColor: brandColor }}
            >
              <div className="flex-row center column-gap-10">
                <span>{t('proceed_to_payment')}</span>
              </div>
              <div className="flex-row column-gap-16">
                {!isLoading && <img src="/assets/icons/next-white.svg" alt="" />}
                {isLoading && <LoadingSpinner />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CinemaCounterInformation;
