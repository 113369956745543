import { PayloadAction } from '@reduxjs/toolkit';

import { CREATE_ORDER_REQUEST_SUCCESS, ORDER_UPDATE_ITEM } from '../../constants/order';
import { CreateOrderResponse } from '../../interfaces/models/order';

type StateType = {
  list?: CreateOrderResponse[];
};

const initialState = {
  list: [],
};

export default function orders(state: StateType = initialState, action: PayloadAction<CreateOrderResponse>): StateType {
  switch (action.type) {
    case CREATE_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        list: state.list?.concat([action.payload]),
      };
    case ORDER_UPDATE_ITEM:
      const updatedList = state.list?.map(order =>
        order.id === action.payload.id ? { ...order, ...action.payload } : order
      );

      return {
        ...state,
        list: updatedList,
      };
    default:
      return state;
  }
}

