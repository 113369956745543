// Function to get the current date in the format yyyy-mm-dd
export function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Function to get the date 30 days from today in the format yyyy-mm-dd
export function getMaxFutureDate() {
  const today = new Date();
  today.setDate(today.getDate() + 30); // Add 30 days to today's date
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getConvertedDate(date: string) {
  const inputDateString = date;
  const parts = inputDateString.split(/[- :]/); // Split the string by '-', ' ', and ':'
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript (0 = January, 1 = February, etc.)
  const day = parseInt(parts[2], 10);
  const hours = parseInt(parts[3], 10);
  const minutes = parseInt(parts[4], 10);

  const dateObject = new Date(year, month, day, hours, minutes);

  const formattedDateString =
    dateObject.toDateString() + ' ' + dateObject.toLocaleTimeString() + ' GMT+0700 (Cambodia Standard Time)';

  return formattedDateString;
}

export function formatCustomDate(inputDateStr: string) {
  const inputDate = new Date(inputDateStr);

  // Define month names
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Extract date components
  const month = monthNames[inputDate.getMonth()];
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();

  // Determine AM/PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Format minutes with leading zero
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Create the formatted date string
  const formattedDateString = `${month} ${day} ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedDateString;
}
