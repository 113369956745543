import { Icon } from '@iconify/react';
import { useAppSelector } from 'hooks/redux';
import { useEffect, useState } from 'react';

interface Option {
  startedWith?: number;
  max?: number;
  size?: 'small' | 'large';
  onChange: (amount: number) => void;
}

export const QuantitySmall = ({ startedWith = 1, size, max, onChange }: Option) => {
  const [amount, setAmount] = useState<number>(1);
  const { detail } = useAppSelector((state) => state.organization);
  const brandColor = detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#22262d';
  const decrement = (e: Event) => {
    e.preventDefault();
    // Case Quantity cannot goes below 0
    if (amount <= 0) return;

    const decreaseAmount = amount - 1;
    onChange(decreaseAmount);
    setAmount(decreaseAmount);
  };

  const increment = (e: Event) => {
    e.preventDefault();
    // Case Quantity cannot goes above max
    if (max && amount >= max) return;

    const incrAmount = amount + 1;
    onChange(incrAmount);
    setAmount(incrAmount);
  };

  useEffect(() => {
    if (startedWith !== 0) {
      setAmount(startedWith);
    }
  }, [amount, startedWith]);

  return (
    <div className={`quantity-wrapper small-quantity`}>
      {amount === 1 ? (
        <Icon icon="ph:trash-bold" color="#64676c" width={23} onClick={(e: any) => decrement(e)} />
      ) : (
        <div style={{ backgroundColor: brandColor }} className="item-box" onClick={(e: any) => decrement(e)}>
          -
        </div>
      )}

      <div id="quantity" className="">
        {amount}
      </div>
      <div style={{ backgroundColor: brandColor }} className="item-box" onClick={(e: any) => increment(e)}>
        +
      </div>
    </div>
  );
};
