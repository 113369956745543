import { GET } from 'generics/base-service';
import { IPromotion } from 'interfaces/models/promotion';
import { ISpot } from 'interfaces/models/spot';

export function fetchingAllPromotionByOutletId(outletId: string): Promise<IPromotion> {
  // const url = '/product/promotion-list/' + outletId;
  const url = 'api/v1/Promotion/outlet/' + outletId;

  return GET<IPromotion>(url);
}
