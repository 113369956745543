import React from 'react';
import html2canvas from 'html2canvas';
import { useAppSelector } from 'hooks/redux';
import { generateRandomCode } from 'helper/randomString';
import { useTranslation } from 'react-i18next';

const DownloadReceipt: React.FC = () => {
  const { detail } = useAppSelector((state) => state.organization);
  const [isDownload, setIsDownload] = React.useState(false);
  const brandColor = detail?.primaryColor && detail?.primaryColor !== null ? detail?.primaryColor.replace(/'/g, '') : '#DD5F36';
  const { t } = useTranslation();
  const handleDownloadPage = async () => {
    setIsDownload(true);
    // Get the entire HTML element (the root element of your page)
    const element = document.documentElement;

    // Wait for all images to load before capturing the page
    await loadAllImages();

    // Use html2canvas to capture the page as an image
    html2canvas(element).then((canvas) => {
      // Convert the canvas to a data URL
      const dataURL = canvas.toDataURL('image/png');

      // Create an anchor element with the data URL as the href
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = `receipt${generateRandomCode()}.png`; // Set the filename for the downloaded file

      // Programmatically click the anchor to initiate the download
      if (document.createEvent) {
        const event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        link.dispatchEvent(event);
      } else {
        link.click();
      }
      setIsDownload(false);
    });
  };
  const loadAllImages = () => {
    const images = document.getElementsByTagName('img');
    const promises = [];

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (!image.complete) {
        // If the image is not yet loaded, create a promise to wait for it to load
        const promise = new Promise((resolve) => {
          image.onload = resolve;
          image.onerror = resolve; // Resolve even if the image fails to load
        });
        promises.push(promise);
      }
    }

    return Promise.all(promises);
  };

  return (
    <>
      {!isDownload && (
        <>
          <div
            id="download-button"
            className="btn primary pd-12 flex-row contact-info"
            onClick={handleDownloadPage}
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              borderColor: brandColor,
              boxShadow: `0px 4px 4px ${brandColor}00`,
            }}
          >
            <div>
              <span style={{ color: brandColor, userSelect: 'none' }}>{t('download_receipt')}</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DownloadReceipt;
