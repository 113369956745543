import React from 'react';

import Confirmation from '../Confirmations/index';
import SelectPaymentMethod from '../SelectPaymentMethod';

type PropType = {
  onClose: () => void;
};

const AskForBilledPayment = (props: PropType) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  return (
    <>
      {!showConfirmation && (
        <SelectPaymentMethod cancelCalled={props.onClose} clickedSubmit={() => setShowConfirmation(true)} />
      )}
      {showConfirmation && <Confirmation onClosed={props.onClose} />}
    </>
  );
};

export default AskForBilledPayment;
