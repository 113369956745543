export const GET_SPOT_REQUEST = '@@organization/GET_SPOT_REQUEST';
export const GET_SPOT_REQUEST_SUCCESS = '@@organization/GET_SPOT_REQUEST_SUCCESS';

export const SPOT_QRTABLE = '0';
export const SPOT_DINE_IN = '1';
export const SPOT_PICKUP_TYPE = '2';
export const SPOT_DELIVERY = '3';
export const SPOT_TABLE_RESERVATION = '4';
export const SPOT_RESERVATION_PREORDER = '5';
export const SPOT_MENU_ONLY = '6';
export const SPOT_HOTEL_ROOM_SERVICE = '7';
export const SPOT_CINEMA_SEAT = '8';
export const SPOT_CINEMA_COUNTER = '9';
export const SPOT_CINEMA_HALL = '10';
